.root {
  display: inline;
  line-height: 30px;
  background-color: #ed3b3b;

  bottom: 14px;
  margin-top: 5px;
  position: absolute;
  right: 5px;

  border: 1px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding-left: 13px;
  padding-right: 13px;
}
