@import './variables.scss';

.tabMenuContainer {
  width: $containerWidth;
  background: $colorDark;
  color: $colorLight;
  .tabWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    margin-left: 20px;
    .tabItem {
      margin-right: 5px;
      padding: 15px;
      color: $colorLight;
      font-weight: bold;
      &:hover {
        text-decoration: none;
        color: $colorPrimary;
      }
    }
    .activeTab {
      color: $colorPrimary;
      border-bottom: 4px solid $colorPrimary;
    }
  }
}