@import './variables.scss';

.root {
  position: fixed;
  background: linear-gradient(98deg, $grape -18%, $plum 36%, $ruby 98%);
  color: #eee;
  font-weight: 200;
  z-index: 8;
  width: 100%;
  min-width: 320px;
  margin: auto;
  padding: 0;
}

.wrapper{
  width: 1240px;
  margin: 0 auto;
}

.logo {
  width: 172px;
  height: 70px;
  display: block;
}

.logo img {
  height: 70px;
}

.mediaNav {
  position: relative;
  font-size: 18px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  margin-right: 25px;
}

.active {
  border-bottom: 3px solid white;
}

.listItem a {
  color: $light;
  float: none;
  font-size: 18px;
}

.listItem a:hover {
  color: $light;
  cursor: pointer;
  text-decoration: none;
}

.listItemNav {
  font-size: 18px;
  font-weight: 500;
  display: block;
  padding: 13px 0;
}

.listItemNav:hover {
  cursor: pointer;
  text-decoration: none;
  display: block;
}

.dropdownItem {
  float: none;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  font-size: 14px;
  vertical-align: middle;
}

.dropdownItem a {
  color: $light;
}

.dropdownItem a:hover {
  color: $light;
  text-decoration: none;
}

.dropdown {
  color: $light;
  display: block;
  position: absolute;
  top: -17px;
  left: -31px;
  white-space: nowrap;
  list-style-type: none;
  z-index: 1000;
}

.dropdown > ul {
  background-color: rgba(0, 0, 0, .9);
  border: 1px solid rgba(81, 90, 105, .95);
  list-style: none;
  margin: 0;
  padding: 24px 32px 16px;
  max-height: calc(100vh - 69px);
  width: auto;
  clear: both;
  content: '';
  vertical-align: top;
}
