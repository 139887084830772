@import "@blueprintjs/core/lib/scss/variables";
@import "./_variables";
@import "./mixins";

.root {
  background-color: $lightGray6;
  padding: 20px 10px;
}

.gridRow {
  display: grid;
  grid-template-columns: repeat(3, $gridWidth);
  grid-gap: $gridGap;
  margin-bottom: 20px;
}

.leastMostGridRow {
  display: grid;
  grid-template-columns: repeat(2, $leastMostGridWidth);
  grid-gap: $gridGap;
  margin-bottom: 20px;
}

.leftContainer {
  flex: 1 1 auto;
  min-width: 600px;
}

.cardHeading {
  height: 30px;
}

.cardSubHeading {
  float: left;
  display: block;
  color: $gray4;
}

.rightContainer {
  margin-left: 20px;
  flex: 0 0 0;
  width: 250px;
  min-width: 250px;
}

.pageHeaderContainer {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}

.pageHeaderContainer .pageHeader {
  display: inline;
}

.pageHeader {
  margin-top: 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid $light_gray3;
  padding-bottom: 15px;
  font-size: 22px;
  font-weight: 600;
}

.card {
  padding: 15px;
  height: 100%;
  border: none !important;
  box-shadow: none !important;
}

.gridItem {
  min-height: fit-content;
  .scoreStatContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 85%;
    .detailWrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      .percentageWrapper {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        .changeAmount {
          @include changeAmountStyle($gray4)
        }
        .changeAmountUp {
          @include changeAmountStyle($green4)
        }
        .changeAmountDown {
          @include changeAmountStyle($red4)
        }
        .optimizationScore {
          font-size: 36px;
        }
      }
    }
    .chartWrapper{
      width: 80%;
      height: 100%;
      margin: auto;
      a {
        display: none !important;
      }
    }
  }
  .scanStatHeader {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    text-align: left;
    .headerBorder {
      border: 1px solid $light_gray3;
      padding: 10px;
      h4 {
        margin-top: 10px;
      }
    }
  }
  .statBottom {
    margin-top: 5px;
    padding: 3px 0;
    text-align: left;
    max-height: $gridHeight;
    overflow-y: auto;
    .platformWrapper {
      border-bottom: 1px solid $light_gray3;
      .platformLogo img{
        width: 50px;
      }
      .territories {
        margin: 0;
        padding: 0;
      }
      
      .territoriesItem {
        display: inline-block;
        height: 32px;
        width: 32px;
        margin-right: 5px;
      }
      
      .territoriesItem:not(:first-child) {
        margin-left: 4px;
      }
      
      .territoriesItemImage {
        width: 100%;
        height: 20px;
      }
    }
  }
  .actionStateWrapper{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 10vh);
    .actionStateItem {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $light_gray3;
      .title{
        @include actionItemLabelStyle($gray4, 14px)
      }
      .number {
        @include actionItemLabelStyle($dark-gray1, 18px);
        margin-right: 10px;
      }
      .difference {
         @include actionItemLabelStyle($gray4, 14px)
      }
      .differenceUp {
         @include actionItemLabelStyle($green4, 14px)
      }
      .differenceDown {
         @include actionItemLabelStyle($red4, 14px)
      }
    }
  }

}

.progressBar {
  margin-bottom: 10px;
}

.header {
  color: $dark-gray1;
  padding-bottom: 15px;
  border-bottom: 1px solid $light_gray3;
  font-size: 22px;
  margin: 0;
  font-weight: 600;
}

.seperator {
  height: 1px;
  box-shadow: 0 1px 0 rgba(16,22,26,.15);
  margin-left: 10px;
  margin-right: 10px;
}

.clear {
  clear: both;
}

.brandSection {
  display: flex;
  flex-direction: column;
}

.platforms {
  margin: 10px 0px 0px 0px;
  padding: 0;
}

.platformsItem {
  display: inline-block;
  height: 60px;
  width: 60px;
}

.platformsItem span {
  vertical-align: middle !important;
}

.platformsItemImage {
  padding: 8px;
  width: 100%;
}

.flag {
  width: 25px;
  height: 47px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  display: inline;
  margin: -5px 0 0 10px;
  opacity: 0.8;
}

.scanTitle {
  margin-top: 70px;
  font-size: 22px;
  font-weight: 600;
}

.tooltip {
  position: relative;
  top: -3px;
  left: 10px;
}

.tooltipPopover {
  width: 400px;
}