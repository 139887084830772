@import './variables.scss';

.container {
  max-width: $containerWidth;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

.item div {
  width: $containerWidth;
  height: $carouselHeight;
  border-radius: 16px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 45%;
  border: solid white;
  border-width: 0 4px 4px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  opacity: 0;
}

.container:hover .rightArrow,
.container:hover .leftArrow {
  opacity: 1;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -25px;
}

.disabledArrow {
  display: none;
}

.slickActive {
  opacity: 0.9;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 13px !important;
  height: 13px !important;
  margin: 0 !important;
  margin-right: 7px !important;
}

.slickNormal {
  opacity: 0.3;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 13px !important;
  height: 13px !important;
  margin: 0 !important;
  margin-right: 7px !important;
}

@media (max-width: 1700px) {

  .container {
    max-width: 100vw;
  }

  .item div {
    width: 100vw;
    height: 45vh;
  }
}