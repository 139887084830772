@import "./variables.scss";

.item {
  float: left;
  width: $brickHeroWidth;
  height: $brickHeroHeight;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  border-radius: 5px;

  margin-right: $brickHeroMargin;
  margin-bottom: 21px;
  /* opacity: 0.25; */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.highlight.highlight {
  filter: brightness(150%);
  opacity: 1;

}

.container {
  max-width: $bodyWidth;
}

.scrollWrapper {
  width: 100%;
  overflow: scroll;
  white-space: nowrap;
}

.header {
  color: white;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  margin-bottom: 16px;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
