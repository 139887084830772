.root {
  position: absolute;
  height: 374px;
  width: 100%;
}

.imageContainer {
  height: 374px;
  width: 497.42px;
}

.imageContainerInner {
  display: inline-block;
  height: 100%;
}

.backgroundImage {
  height: 100%;
}

.squareCover {
  height: 289px;
}

.squareCover .imageContainer{
  height: 289px;
}
