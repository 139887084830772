@import './variables.scss';

.item, .chartItem {
  position: relative;
  display: block;
  border-radius: 16px;
  background-size: cover;
  margin-bottom: 8px;
  &.chartItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &:hover{
    text-decoration: none;
  }
  .spotBackground, .spotBackgroundVideoContainer {
    background: $backgroundColor center center no-repeat;
    background-size: cover;
    margin-bottom: 8px;
    &.spotBackgroundVideoContainer {
      background-color: $videoContainerBckColor;
    }
  }
  .releaesAdv {
    position: absolute;
    top: 10px;
    background: rgb(191, 0, 0);
    color: #fff;
    font-weight: bold;
    font-size: 0.7rem;
    text-transform: uppercase;
    right: 15px;
    padding: 1%;
  }
  .chartContainerCounter {
    color: #fff;
    font-size: 5rem;
    align-self: flex-end;
    font-weight: bold;
  }
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.seeMoreItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f171e;
  color: white;
  text-shadow: 1px -1px 0 rgba(0, 0, 0, 0.35);
  font-size: 17px;
}

.details{
  font-size: 14px;
  margin-bottom: 20px;
  .currentRating {
    color: $darkFontColor;
    margin: 0 10px 0 10px;
    background: $saleColor;
    padding: 2px 3px;
  }
  .subtitle, .categoryName {
    color: $fontColor;
  }
  .categoryName{
    margin-left: 3.5vw;
  }
}
