@import "./variables.scss";

.root {
  position: fixed;
  font-weight: 200;
  z-index: 2;
  width: $pageWidth;
  height: fit-content;
  line-height: 67px;
  display: flex;
  flex-direction: row;
  color: $colorLight;
  background: $colorDark;
  .logo {
    margin: auto 20px 0 100px;
    img {
      width: 145px;
      vertical-align: middle;
    }
  }
  .menuLinks {
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .listItem {
      text-decoration: none;
      margin: 0;
      font-size: 1rem;
      font-weight: 700;
      color: $colorLight;
      font-family: $fontFamily;
      span {
        padding: 3px 20px;
      }
      &:hover{
        text-decoration: none;
        color: $colorLightLight;
        border-bottom: 3px solid $colorPrimary;
      }
    }
  }
}
