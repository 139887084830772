@mixin spotStyling($height) {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  float: left;
  margin: 0 5px 5px 0;
  div {
    width: 100%;
    height: $height;
  }
}

@mixin iconStyle($color, $background) {
  position: absolute;
    right: 10px;
    top: 10px;
    padding: 3px 5px;
    border-radius: 5px;
    color: $color;
    background: $background;
}

@mixin arrowRotation($angle) {
  transform: rotate($angle);
  -webkit-transform: rotate($angle);
}

@mixin widthHeight($width, $height) {
  div {
    width: $width;
    height: $height;
  }
}

@mixin fontStyle($color, $fontSize) {
  color: $color;
  font-size: $fontSize;
  font-weight: bold;
  margin-bottom: 25px;
  font-style: italic;
}

@mixin linkExternalSize($width, $height) {
  width: $width;
  height: $height;
}

@mixin headerBackgroundStyling($width, $height) {
  .backgroundImage{
    width: $width;
    height: $height;
    background: center center no-repeat;
    background-size: cover;
  }
}