@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: $containerWidth;
  position: relative;
  margin: 130px auto 0;
  padding-bottom: 24px;
  @include alignSliderSpots();
}

.container + .container {
  margin: 60px auto 0;
}

.heading {
  padding-bottom: 3px;
}

.heading h1 {
  font-size: $sectionHeadingFontSize;
  font-weight: 700;
  color: $yellowColor;
  font-family: Amazon Ember,Amazon Arabic Ember,Arial,sans-serif;
}

.heading h1 a {
  color: $yellowColor;
  transition: color .2s ease-in-out;
  font-size: $sectionHeadingFontSize;
  margin-left: 25px;
  font-weight: 500;
  &:hover {
    text-decoration: none;
  }
}

.heading h3 {
  font-size: 13px!important;
  color: $yellowColor;
  font-weight: 500;
}

.item {
  padding-right: $containerItemPadding;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: $containerItemWidth;
  height: $containerItemHeight;
  border-radius: 16px;
}

.item div:hover {
  transform: scale(1.10);
  transition-duration: .8s;
  z-index: 100;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 45%;
  border: solid $lightColor;
  border-width: 0 4px 4px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  opacity: 0;
}

.rightArrow::before,
.leftArrow::before, {
  content: '' !important;
}

.container:hover .rightArrow,
.container:hover .leftArrow {
  opacity: 1;
}

.disabledArrow {
  display: none;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -25px;
}