@import './variables.scss';

.container {
  width: 100%;
  margin-top: 80px;
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
}

.spotWrapper{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.heading {
  padding-bottom: 3px;
  margin-left: 20px;
}

.heading h2 {
  font-size: 24px;
  font-weight: 700;
  color: white;
}

.item {
  margin-bottom: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  opacity: 1;
}

.item div {
  width: $gridItemWidth;
  height: $gridItemHeight;
  margin: 0 3px;
}
