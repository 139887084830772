.item {
  padding: 10px;
  position: relative;
  display: block;
  text-decoration: none;
}

.item:hover {
  text-decoration: none;
  color: #ffffff;
}

.titleContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABgCAYAAADcvRh2AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABV0RVh0Q3JlYXRpb24gVGltZQAzLzEyLzEzP/y5FwAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAABHSURBVBjTpZDRCgAgCANvC/r/T+5NSDSKXmTouOEApgEZsIERysXu1teOd0sKH/uBUBdkFwAdgxJUz5+nDO9kffSi7hlFLyx6jQGLlHpJqgAAAABJRU5ErkJggg==') left bottom repeat-x;
  background-size: 100% 96px;
}

.title {
  padding: 0 10px 10px 10px;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 2;
  width: 100%;
  color: white;
  text-shadow: 1px -1px 0 rgba(0,0,0,.35);
  line-height: 1.2;
}

.seeMoreItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f171e;
  color: #f1c400;
  border: 1px solid;
  border-color: #f1c400;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 1px -1px 0 rgba(0,0,0,.35);
}

.seeMoreItem:hover {
  background-color: #f1c400;
  color: #0f171e;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.cineplexDetails {
  width: 30% !important;
  min-width: 30% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 5%;
  float: right;
}

.cineplexDetails .itemImage {
  max-width: 400px;
}

.cineplexDetails .detailHeader, .cineplexDetails .description {
  font-weight: 600;
  color: #fff;
}

.cineplexDetails .detailHeader{
  font-size: 4em;
}

.cineplexDetails .description {
  font-size: 1.5em;
  font-weight: 600;
  color: #fff;
}

.cineplexDetails button {
  width: 220px;
  height: 55px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #ddb403;
  background: transparent;
  color: #ddb403;
}