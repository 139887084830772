@import "./variables.scss";

.menu {
  z-index: 8;
  background: transparent;
  height: 83px;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 1), transparent);
}

.navbarButton {
  height: 83px;
  width: 68px;
  padding: 27px 23px;
  cursor: pointer;
}

.dropdownMenu {
  display: flex;
  min-width: 200px;
  max-height: 85vh;
  overflow-y: auto;
  flex-direction: column;
  padding: 0 !important;
  background: $rowEightColor;
}

.dropdownMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdownMenu ul li {
  font-size: 16px;
  height: 50px;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.dropdownMenu ul .dropdownLogo {
  text-align: center;
  padding: 20px;
  height: auto;
  line-height: 0;
  border-bottom: 1px solid #424242;
}

.dropdownMenu ul .dropdownLogo img {
  width: 195px;
  height: 65px;
}

.dropdownMenu ul li:hover {
  cursor: pointer;
  background: #1b2c41;
}

.dropdownMenu ul li:hover > a {
  text-decoration: none;
  color: #ffffff;
}

.dropdownMenu ul li a {
  font-weight: 300;
  color: $navbarButtonTextColor;
  font-size: 17px;
  text-transform: uppercase;
}

.dropdownMenu ul .dropdownItem {
  padding-left: 40px;
}

.dropdownMenu ul .dropdownItem a{
  font-size: 16px;
  text-transform: capitalize;
}

.menuItemIcon {
  margin-right: 10px;
}

.rowEightLogo {
  width: 150px;
  height: 83px;
  background-image: url(https://s3.row8.com/assets/web_logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain
}