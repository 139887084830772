@import './variables.scss';

.item {
  position: relative;
  display: block;
  border-radius: 16px;
  background-size: cover;
  margin-bottom: 8px;
  &:hover{
    text-decoration: none;
  }
  .spotBackground {
    background: $colorDark center center no-repeat;
    background-size: cover;
    margin-bottom: 8px;
    .spotSubtitile {
      color: $colorLight;
      background: $colorShadow;
      position: absolute;
      bottom: 0;
      right: 27px;
      padding: 5px;
      border-radius: 5px;
    }
    .spotChart {
      font-size: 3.5rem;
      color: $colorLight;
      position: absolute;
      bottom: 0;
      font-weight: bold;
    }
    .heroCarouselDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 2%;
      width: 30vw;
      color: $colorLight;
      font-weight: 700;
      line-height: 1.2rem;
      img {
        max-width: 25vw;
        margin-top: 45%;
      }
      .buttonWrapper {
        display: block;
        button {
          background-color: $colorPrimary;
          border-radius: 50px;
          margin: 30px 20px 0 0;
          padding: 10px 20px;
          border: none;
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.seeMoreItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f171e;
  color: white;
  text-shadow: 1px -1px 0 rgba(0, 0, 0, 0.35);
  font-size: 17px;
}