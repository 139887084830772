@import "@blueprintjs/core/lib/scss/variables";

.posterImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 62px;
  height: 84px;
  border-radius: 5px;
  margin: 3px;
}

.selected .imageContainer {
  border: 3px solid $pt-intent-danger;
}

.imageContainer {
  transition: border-color 0.4s ease;
  border-radius: 8px;
  border: 3px solid transparent;

  margin: 6px;
}
