@import './variables.scss';

.container {
  width: $pageWidth;
  position: relative;
  padding-bottom: 24px;
  border-radius: 10px;
  top: 7px;

  .heroCarouselWrapper {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: $pageWidth;
    height: $heroCarouselHeight;

    .carouselDetails {
      width: $heroCarouselDetailsWidth;
      height: fit-content;
      position: absolute;
      bottom: 50px;
      padding: 1%;
      display: flex;
      flex-direction: column;
      h2 {
        color: $colorLight;
        margin-bottom: 25px;
        font-weight: bold;
      }
      .subtitle {
        margin-bottom: 20px;
        color: $colorGray;
      }
      .button{
        background-color: $colorPrimary;
        padding: 1%;
        border-radius: 5px;
        width: fit-content;
        a{
          color: $colorLight;
          font-weight: bold;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
