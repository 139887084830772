.menu {
  max-width: 1300px;
  margin: auto;
  margin-bottom: 40px;
  top: 0;
  padding-top: 15px;
  background: #fff;
  height: 48px;
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
}

.list {
  list-style: none;
}

.dropdown {
  background-color: white;
}

.button {
  padding: 0 14px;
  line-height: 48px;
  cursor: pointer;
  border-radius: 9999px;
}

.dropdownIcon {
  background-position-y: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 15px;
  margin: 17px 3px 1px 3px;
  vertical-align: top;
  width: 15px;
}
