.dialog.dialog {
  width: 800px !important;
}

.popover {
  display: flex;
  padding: 15px;
  overflow: hidden;
}

.header {
  padding: 20px 20px 10px 20px;
  box-shadow: 0 1px 0 rgba(16,22,26,0.15);
  /* background-color: white; */
}

.artworkContainer {
  width: 120px;
  height: 1px;
}

.body {
  min-height: 100px;
}

.primaryActions {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
}

.actionButton {
  margin-right: 10px;
}

.secondaryActions {
  flex-grow: 1;
}

.footer {
  display: flex;
}

.tabs {
  padding: 0 20px 0 20px;
  box-shadow: 0 1px 0 rgba(16,22,26,0.15);
}

.tabsContainer {
  padding: 10px;
  width: 100%;
}

.clipboardIcon {
  margin-left: 5px;
  cursor: pointer;
}