@import './variables.scss';

.item {
  position: relative;
  display: block;
  border-radius: 16px;
  background-size: cover;
  &:hover{
    text-decoration: none;
  }
  .spotBackground {
    background: $colorDark center center no-repeat;
    background-size: cover;
    .containerDetails {
      color: $colorLight;
      position: absolute;
      bottom: 0;
      left: 5px;
      padding: 5px;
      display: flex;
      flex-direction: row;
      font-size: 12px;
      img {
        width: 30px;
      }
      span {
        margin: 0 5px;
      }
    }
    .heroCarouselDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      height: 70%;
      padding: 2%;
      width: 30vw;
      color: $colorLight;
      font-weight: 700;
      line-height: 1.2rem;
      h1{
        color: $colorLight;
        font-size: 2rem;
      }
      .buttonWrapper {
        display: block;
        button {
          background-color: $colorPrimary;
          border-radius: 50px;
          margin: 30px 20px 0 0;
          padding: 10px 20px;
          border: none;
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.portritDetails, .portraitGridDetails {
  background-color: $colorDark;
  width: $portraitContainerItemWidth;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: $itemBorderRadius;
  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    margin-left: 5%;
  }
  .description {
    margin-left: 5%;
    h5{ 
      color: $colorLight;
    }
    .imdbDetails {
      display: flex;
      flex-direction: row;
      img {
        width: 30px;
      }
      span {
        margin: 0 5px;
      }
    }
  }
}

.portraitGridDetails {
  display: flex;
  width: $portraitItemWidth;
  justify-content: space-between;
  padding: 20px;
  .count {
    font-size: 2rem;
    margin-left: 3%;
  }
  .description {
    display: flex;
    flex-direction: column;
    gap: 4px;
    h5{ 
      font-size: 0.8rem;
      font-weight: normal;
    }
    .imdbDetails {
      flex-wrap: wrap;
    }
  }
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.seeMoreItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $colorDark;
  color: $colorLight;
  text-shadow: 1px -1px 0 rgba(0, 0, 0, 0.35);
  font-size: 17px;
}

.spotInfo {
  display: flex;
  gap: 4px;
  align-items: center;

  .imdbDetails {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 50px;
      margin-right: 5px;
    }
  }
}

.priceBadge, .availability {
  align-self: center;
  border-radius: 4px;
  display: flex;
  font-size: .625rem;
  font-weight: 700;
  letter-spacing: .05rem;
  line-height: 1.125rem;
  padding: 1px 8px;
  pointer-events: none;
  position: absolute;
  text-align: left;
  text-transform: uppercase;
  white-space: normal;
  z-index: 1;
  background: $colorDark;
  color: $colorLight;
  width: fit-content;
  top: 10px;
  left: 10px;
  &.availability {
    background: $colorPrimary;
  }
}