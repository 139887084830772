.root {
  max-width: 1850px;
  padding-left: 48px;
  margin: 0 auto;
}

.header {
  min-height: 37px;
  padding: 5px;
  position: relative;
}

.title {
  font-family: "Roboto",UILanguageFont,Arial,sans-serif;
  font-weight: 100;
  font-size: 28px;
  line-height: 1.4;
  margin: 0;
}

.subtitle {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  padding-bottom: 4px;
}

.cardList {
  height: 340px;
  overflow: hidden;
}

.tallCover .cardList {
  height: 340px;
}

.squareCover .cardList {
  height: 255px;
}

.halfCover .cardList {
  height: 170px;
}

.inner {
  margin: 5px 0 15px 0;
}

.hasBackground {
  margin: 17px 15px 21px 0;
}

.spacer {
  float: left;
  margin: 5px;
  height: 670px;
  width: 330px;
}

.showAll {
  height: auto !important;
  max-height: none;
}
