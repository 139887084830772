@import "@blueprintjs/core/lib/scss/variables";

.newCandidateBadge {
  position: absolute;
  right: 5px;
  bottom: 30px;
  border-radius: 2px;
  padding: 4px;
  font-weight: bold;
  font-size: 10px;
  background: $cerulean5;
  color: $white;
  text-transform: uppercase;
}

.newCandidateBadge.badgeOnModal {
  right: 10px;
  bottom: 40px;
}
