@import './variables.scss';
@import '../merchandizing-mixins.scss';
@import './minxins.scss';

.container {
  width: $innerContainer;
  position: relative;
  margin: 80px auto 0;
  @include alignSliderSpots();
  &.first {
    margin-top: 210px;
  }
  .heading {
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    margin-bottom: 20px;
    .link {
      font-size: $containerHeaderFontSize;
      font-weight: 700;
      color: $colorDark;
      &:hover {
        text-decoration: none;
        color: $colorRed;
      }
    }
  }
  .containerItem {
    @include itemStyling;
    @include itemSize($containerItemWidth, $containerItemHeight);
  }
  .collectionItem {
    @include itemStyling;
    @include itemSize($collectionItemWidth, $collectionItemHeight);
    margin: 0 auto 45px;
  }

  .largeLandscapeItem {
    @include itemStyling;
    @include itemSize($largeLandscapeItemWidth, $largeLandscapeItemHeight);
  }

  .portraitItem {
    @include itemStyling;
    @include itemSize($portraitItemWidth, $portraitItemHeight);
  }

  .landscapeItem {
    @include itemStyling;
    @include itemSize($landscapeContainerItemWidth, $landscapeContainerItemHeight);
  }

  @media (max-width: 2000px) {
    width: $smContainerWidth;
    .containerItem {
      @include itemSize($smContainerItemWidth, $smContainerItemHeight);
    }
    .collectionItem {
      @include itemSize($smCollectionItemWidth, $smCollectionItemHeight)
    }
    .largeLandscapeItem {
      @include itemSize($smLargeLandscapeItemWidth, $smLargeLandscapeItemHeight);
    }
    .portraitItem {
      @include itemSize($smPortraitItemWidth, $smPortraitItemHeight);
    }
  }
}

.collectionStyling {
  background-color: $colorGray;
  width: $smContainerWidth;
  padding: 0 5vw;
  margin: 0 auto 25px;
  @media (max-width: 2000px) {
    width: $xlContainerWidth;
    padding: 0 9vw;
  }
}
