@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.header {
  width: 100%;
  height: 75px !important;
  background-color: rgb(103, 0, 103) !important;
  position: fixed !important;
  top: 75px;
  .customNavbarGroup {
    height: 75px;
  }
  .toolbarGroup {
    height: 75px !important;
  }
  .divider {
    margin: 0 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    height: 45px;
  }
}

.btnDefault {
  box-shadow: inset 0 0 0 1px rgba(223, 238, 241, 0.2), inset 0 1px 2px rgba(223, 238, 241, 0.2) !important;
  background-color: $white !important;
  border: 1px solid $lightBlue !important;
  &.active, &:hover {
    background-color: $lightBlue !important;
  }
  .labelColor {
    color: $black !important;
  }
  .label{
    margin-left: 10px;
  }
}

@media (max-width: 1500px) {
  .header {
    height: 150px !important;
  }

  .toolbarGroup {
    flex-wrap: wrap;
  }
}

.navBar {
  position: relative !important;
}

.searchBar {
  display: flex !important;
  background-color: white;
  height: 30px;
  width: 350px;
  border-radius: 25px;
}

.search {
  order: 1;
  border-right: 1px solid #D0D0D0 ;
}

.span {
  background-color: #dfeef1 !important;
  color: black !important;
  flex-shrink: 0;
  flex-direction: row;
  min-width: 60px !important;
}

.tag {
  display: flex;
  order: 2;
  max-width: 70% !important;
  overflow-x: scroll !important;
  height: fit-content;
}

.input {
  box-shadow: none !important;
  border: none !important;
  order: 3;
  width: unset !important;
  min-width: 20% !important;
  max-width: 100% !important;
  padding-left: 0 !important;
}

.tagFilter {
  margin-left: 15px;
}
