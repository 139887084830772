$rowHeaderWidth: 350px;
$titleInfoWidth: 300px;
$gridCellWidth: 150px;
$gridCellHeight: $gridCellWidth * 0.8;
$gridCellTotalWidth: $gridCellWidth;

:export {
  rowHeaderWidth: $rowHeaderWidth;
  titleInfoWidth: $titleInfoWidth;
  gridCellWidth: $gridCellWidth;
  gridCellHeight: $gridCellHeight;
  gridCellTotalWidth: $gridCellTotalWidth;
}