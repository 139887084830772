@import './variables.scss';

.container {
  max-width: $containerWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 40px auto 0;
  &.first {
    margin: 220px auto 0;
  }
  .sectionHeader {
    max-width: $containerWidth;
    position: relative;
    margin: 100px auto 0;
    h3 {
      color: $fontColor !important;
      margin: 0 0 25px 15px;
    }
    .spotWrapper{ 
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
    }
  }
  .gridItem, .landScapeGridItem {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    font-size: 21px;
    float: left;
    margin: 0 5px 25px 0;
  }
  
  .gridItem div {
    width: $containerItemWidth;
    height: $containerItemHeight;
    border-radius: 16px;
  }
  
  .landScapeGridItem div {
    width: $landscapeContainerItemWidth;
    height: $landscapeContainerItemHeight;
    border-radius: 16px;
    margin-right: 5px;
  }
}
