.root {
  position: fixed;
  top: 150px;
  background: linear-gradient(90deg,#007bc4,#041c2c 100%);
  color: #f2f3f3;
  -webkit-transition: padding .5s ease;
  -moz-transition: padding .5s ease;
  -o-transition: padding .5s ease;
  transition: padding .5s ease;
  z-index: 5;
  width: 100%;
  left: 0;
  height: 60px;
  line-height: 60px;
  padding: 0;
}

.root.newRoot {
  background: #171c20;
  border-bottom: 1px solid #252d34;

  .dropdown {
    background-color: #171c20;
  }
}

.logo {
  margin: 0;
  margin-left: 75px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  float: left;
}

.logo h3 {
  margin: 0;
  font-weight: 900;
  color: #f2f3f3;
  font-size: 28px;
}

.mediaNav {
  position: relative;
  text-transform: uppercase;
  font-size: 18px;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  float: left;
  height: 60px;
  margin: 0 0 0 15px;
  -webkit-transition: margin ease .5s;
  -moz-transition: margin ease .5s;
  -o-transition: margin ease .5s;
  transition: margin ease .5s;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.dropdownItem {
  float: none;
  height: 39px;
  line-height: 39px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.listItem {
  color: #f2f3f3;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  transition: color .25s ease;
}

.listItem a:hover {
  color: #3478c1;
  cursor: pointer;
  text-decoration: none;
}

.dropdown {
  background-color: #043551;
  color: #f2f3f3;
  display: block;
  padding: 10px 15px;
  min-width: 800px;
  position: absolute;
  top: -5px;
  left: -50px;
  white-space: nowrap;
  list-style-type: none;
  z-index: 1000;
}

.dropdown > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 50px;
  width: auto;
  clear: both;
  content: '';
  vertical-align: top;
}

.dropdown > ul:first-child {
  border: 0;
  padding-left: 0;
}

.mainLink {
  width: 100%;
  height: 50px;
  margin: 15px 0 15px 0;
}

.mainLink li a {
  color: #0D5336;
  font-size: 18px;
}

.textLinks {
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
}

.textLinks li {
  float: left;
}

.textLinks li a {
  color: #f2f3f3;
  font-size: 13px;
  font-weight: 200;
}

.imgLinks {
  width: 100%;
  min-height: 300px;
  padding: 10px;
  padding-bottom: 30px;
}

.imgLinks .listItem {
  height: 145px;
  margin-bottom: 20px;
}

.imgLinks li {
  height: 125px;
  float: left;
}

.imgLinks h4 {
  margin: 0;
  margin-top: -15px;
  background: black;
  color: white;
  padding: 10px 0 10px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
