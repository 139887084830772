@import '../merchandizing-mixins.scss';

.container {
  min-width: 768px;
  height: 80vh;
  margin: 0 auto;
  position: relative;
  padding-top: 55px;
  @include alignSliderSpots();
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  min-width: 1290px;
  height: 80vh;
}

.item div{
  min-width: 1290px;
  height: 100vh;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  cursor: pointer;
  width: 60px;
  height: 60px;
  opacity: 0.8;
  z-index: 5;
}

.rightArrow:hover,
.leftArrow:hover {
  border: #f1c400 solid;
  border-left: 0px;
  border-top: 0px;
  opacity: 1;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 25px;
}

.disabledArrow {
  display: none;
}

.slickActive {
  opacity: 0.9;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 13px !important;
  height: 13px !important;
  margin: 0 !important;
  margin-right: 7px !important;
}

.slickNormal {
  opacity: 0.3;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 13px !important;
  height: 13px !important;
  margin: 0 !important;
  margin-right: 7px !important;
}
