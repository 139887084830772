.root {
  max-width: 1364px;
  min-width: 768px;
  height: 205px;
  margin: 0 auto;
}

.root ul {
  list-style-type: none;
  padding-left: 0;
  height: 100%;
}

.root ul li {
  float: right;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}