@import "@blueprintjs/core/lib/scss/variables";

.table {
  width: 100%;
  padding: 1%;
  &.customStripedTable {
    tbody{
      tr:nth-child(odd) {
        background-color: #dfeef1;
      }
    }
  }
}

.dialogContainer {
  width: 70vw !important;

  .complete {
    background-color: $pt-intent-success;
  }
  .in_progress, .initialized {
    background-color: $blue2;
  }
  .canceled {
    background-color: $gray1;
  }
  .error {
    background-color: $red4;
  }

  .statusIcon {
    &:hover {
      cursor: pointer;
    }
  }
}
