.item {
  position: relative;
  display: block;
  margin-bottom: 35px;
  border-radius: 5px;
}

.container {
  margin: 20px;
  display: inline-flex;
}

.position {
  color: #5f6368;
  width: 40px!important;
  padding-right: 5px;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .00625em;
  line-height: 1.5rem;
  flex: 0 0 32px;
  margin-top: 40px;
}

.image {
  width: 90px!important;
}

.titleName {
  padding-left: 20px;
  left: 0;
  z-index: 2;
  color: #333;
  line-height: 1.2;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 16px;
  width: 200px!important;
}

.detail {
  display: flex;
}

.newPrice {
  width: 40px;
  line-height: 0.8;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 14px!important;
  color: #5f6368;
  font: inherit;
  stop-color: #5f6368;
  margin-right: 5px;;
}
