.root {
  width: 100%;
  height: 300px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.root ul {
  padding: 0;
  list-style-type: none;
}

.root > ul > li {
  height: 300px;
  width: 25%;
  padding: 10px;
  line-height: 30px;
  float: left;
}

.shopNowMenu ul {
  background: #0067b8;
  padding: 50px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  height: 100%;
}

.shopNowMenu li {
  text-align: center;
}

.shopNowMenu h3 {
  color: #f2f2f2;
}

.shopNowMenu a {
  color: #f2f2f2;
  font-size: 16px;
}

.shopNowMenu a:hover {
  color: #f2f2f2;
  text-decoration: underline;
  text-decoration-color: #f2f2f2;
}