@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: $containerWidth;
  position: relative;
  margin: 60px auto 0;
  @include alignSliderSpots();
  &.first {
    margin: 210px auto 0;
  }
  .heading {
    padding-bottom: 3px;
    h1 {
      font-size: $containerHeaderFontSize;
      font-weight: 700;
      color: $fontColor;
      a {
        font-size: 14px;
        float: right;
        &:hover {
          text-decoration: none;
        }
      }
    }
    h3 {
      font-size: 14px!important;
      font-weight: 400;
      color: $fontColor;
    }
  }
  .containerItem, .categoryItem, .landscapeContainer {
    padding-right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 14px;
  }
  
  .containerItem, .landscapeContainer {
    display: block;
  }
  
  .containerItem {
    div{
      width: $containerItemWidth;
      height: $containerItemHeight;
      border-radius: 10px;
    }
  }
  
  .landscapeContainer {
    div{ 
      width: $landscapeContainerItemWidth;
      height: $landscapeContainerItemHeight;
      border-radius: 10px;
      border-top: 5px solid $shadowFontColor;
    }
  }
  
  .categoryItem {
    display: flex !important;
    justify-content: center;
    div {
      width: $categoryItemWidth;
      height: $categoryItemHeight;
      border-radius: 50%;
    }
  }
  
  .fade {
    opacity: 0.25;
  }
  
  .highlight {
    opacity: 1;
  }
  
  .disabledArrow{
    display: none !important;
  }
  
  .arrowWrapperRight, .arrowWrapperLeft {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 1;
    cursor: pointer;
    background-color: $backgroundColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    box-shadow: 0 4px 4px rgba(0,0,0,.3), 0 0 4px rgba(0,0,0,.2);
  }
  
  .arrowWrapperRight:hover, .arrowWrapperLeft:hover {
    background-color: #dbd9d9;
  }
  
  .arrowWrapperLeft{
    left: -15px;
  }
  
  .arrowWrapperRight{
    right: -10px;
  }
  
  .rightArrow,
  .leftArrow {
    border: solid $fontColor;
    border-width: 0 1px 1px 0;
    display: block;
    cursor: pointer;
    width: 10px;
    height: 10px;
  }
  
  .rightArrow {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .leftArrow {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}
