@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.row {
  clear: both;
  height: $gridCellHeight;

  border-top-style: solid;
  border-width: 1px;
  border-color: $gray4;
}

.header {
  padding: 10px;
  margin: 0px;
  height: $gridCellHeight;
  float: left;
  width: $titleInfoWidth;
  position: relative;

}

.header:hover {
  text-decoration: none;
}

.header h4 {
  line-height: 120%;
  overflow: hidden;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-right: 30px;
  color: $dark-gray2;
}

.titleType {
  color: $gray1;
}

.link {
  display: block;
  margin-right: 30px;
}

.link:hover {
  text-decoration: none;
}

.tvRow {
  height: fit-content;
}

.movieRow {
  height: fit-content;
}

.tvHeading {
  height: fit-content;
}

.movieHeading {
  height: fit-content;
}

.cog {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.menuItem {
  display: flex !important;
  gap: 4px;
}
