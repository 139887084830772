@import './variables.scss';
@import './mixins';

.grayIcon {
  @include iconStyle($colorLight, (rgba(0, 0, 0, .4)));
}
.primaryIcon {
  @include iconStyle($colorLight, $colorPrimary);
}

.logoLabel {
  position: relative;
  left: 10px;
  bottom: 10px;
  width: 50px;
  height: fit-content;
  align-self: flex-end;
}
