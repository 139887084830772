.container {
  max-width: 1364px;
  min-width: 768px;
  height: 450px;
  margin: 0 auto;
  position: relative;

  &.vuduCarousel {
    margin: 60px auto;;
  }
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  max-width: 1364px;
  min-width: 1290px;
  height: 450px;
}

.item div{
  max-width: 1364px;
  min-width: 1290px;
  height: 450px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  cursor: pointer;
  width: 17px;
  height: 17px;
  opacity: 0.8;
  z-index: 5;
}

.rightArrow:hover,
.leftArrow:hover {
  opacity: 1;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 25px;
}

.disabledArrow {
  display: none;
}

.slickActive {
  opacity: 0.9;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 13px !important;
  height: 13px !important;
  margin: 0 !important;
  margin-right: 7px !important;
}

.slickNormal {
  opacity: 0.3;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 13px !important;
  height: 13px !important;
  margin: 0 !important;
  margin-right: 7px !important;
}

.tubiCarousel.container {
  max-width: 100%;
  margin-top: 62px;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px;
}

.tubiCarouselItem.item {
  max-width: 100%;
  padding-right: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 21px;
}

.tubiCarouselItem.item div {
  max-width: 100%;
  display: block;
  height: 650px;
}
