@import './variables.scss';

.container {
  width: $containerWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 50px auto 0;
  &.first {
    margin-top: 120px;
  }
  .header {
    color: $colorLight;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .spotWrapper{ 
    display: flex;
    justify-content: start;
    flex-wrap: wrap;

    &.mosaic {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-gap: 24px;

      &:before {
        content: '';
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        padding-bottom: 100%;
      }

      .mosaicItem {
        width: unset;
        height: unset;


        &:first-child {
          grid-column: 1 / span 3;
          grid-row: 1 / span 3;
          height: 100%;
        }
        &:nth-child(2) {
          grid-column: 4 / span 2;
          grid-row: 1 / span 2;
          height: 100%;
        }

        div, a {
          width: 100%;
          height: 100%;
        }
      }
    }
    .gridItem, .collectionItem, .portraitGridItem {
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      float: left;
      margin: 0 20px 25px 0;
      div {
        width: $gridItemWidth;
        height: $containerItemHeight;
        border-radius: $itemBorderRadius;
      }
  
      &.collectionItem {
        div {
          width: $landscapeContainerItemWidth;
          height: $landscapeContainerItemHeight;
          border-radius: $itemBorderRadius;
        }
      } 
  
      &.portraitGridItem {
        div {
          width: $portraitItemWidth;
          height: $portraitItemHeight;
        }
      }
    }
  }
}

