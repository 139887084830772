@import "./variables.scss";

.dropdownWrapper {
  font-family: $fontFamily;
  .dropdownItem{
    color: $colorLight;
    padding: 0 20px;
    font-size: 0.9rem;
    font-weight: 700;
  }
  &:hover{
    .dropdownItem {
      text-decoration: none;
      color: $colorPrimary;
    }
  }
}
.dropdown {
  width: fit-content;
  height: fit-content;
  background: $colorLight;
  position: relative;
  margin-top: -4px;
  display: flex;
  flex-direction: column;
  .dropdownList {
    margin: 0;
    padding: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    .listItem {
      height: 40px;
      padding: 15px;
      a {
        color: $colorDark;
        font-weight: 700;
        font-size: .8rem;
        &:hover{
          text-decoration: none;
          color: $colorPrimary;
        }
      }
      .activeLink {
        color: $colorPrimary;
      }
    }
  }
}

