@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.root {
  padding: 10px;
  background-color: $lightBlue;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.actionCount {
  background-color: $red1;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 10px;
}

.actionCount.submittedCount {
  background-color: $gold5;
}

.exportHeader {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 5px;
}

.exportHeader p {
  font-weight: bold;
}

.legend {
  margin-top: auto;

  .legendSection {
    display: flex;
    flex-direction: column;
  
  }

  .legendRow {
    display: flex;
    gap: 12px;
    padding: 6px 0;
    border-bottom: 1px solid #CED6DD;

    &.naLegendRow {
      gap: 8px;
    }
  }

  .circle {
    min-width: 15px;
    height: 15px;
    border-radius: 50%;
    justify-content: center;
    margin-top: 4px;

    &.priceAction {
      background-color: #D44;
    }
    &.appliedException {
      background-color: $pt-intent-success;
    }
    &.notAppliedException {
      background-color: $notAppliedExceptionColor;
    }
    &.supress {
      background-color: $violet1;
    }
  }

  .naLegend {
    font-size: 11px;
    font-weight: bold;
    color: #D44;
  }
}
