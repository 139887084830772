@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: $containerWidth;
  position: relative;
  margin: 130px auto 0;
  padding-bottom: 24px;
  @include alignSliderSpots();
}

.container + .container {
  margin: 60px auto 0;
}

.heading {
  padding-bottom: 3px;
}

.heading h1 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.heading h1 a {
  color: #106ba3;
  transition: color .2s ease-in-out;
  font-size: 16px;
  float: right;
  font-weight: 600;
}

.item, .channelRoundItem {
  margin-right: $brick2ItemMargin;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: $brick2ItemWidth !important;
  height: $brick2ItemHeight !important;
  border-radius: 16px;
}

.channelRoundItem div {
  width: 140px !important;
  height: 140px !important;
  border-radius: 50%;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  opacity: 0;
}

.rightArrow::before,
.leftArrow::before, {
  content: '' !important;
}

.container:hover .rightArrow,
.container:hover .leftArrow {
  opacity: 1;
}

.disabledArrow {
  display: none;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -25px;
}

@media (max-width: 2000px) {

  .container {
    width: 100%;
  }

  .item div {
    width: 270px !important;
  }
}