@import "@blueprintjs/core/lib/scss/variables";

.number {
  color: $dark-gray1;
  font-size: 22px;
  font-weight: bold;
}

.figure {
  color: $dark-gray1;
  margin-bottom: 5px;
}

.units {
  padding-left: 2px;
  font-size: 15px;
  font-weight: bold;
}

.center {
  text-align: left;
}

.right {
  text-align: right;
}
