$pageWidth: 1485px;
$containerWidth: 1450px;
$backgroundColor: #fff;
$fontColor: #0F0F0F;
$fontColorLight: #FFF;
$fontColorGreen: #107516;
$shadowFontColor: #606060;
$fontFamily: "Roboto","Arial",sans-serif;
$containerItemPadding: 15px;
$dividerColor: #e5e5e5;
$heroCarouselHeight: 650px;
$containerHeaderFontSize: 20px;
$containerItemWidth: 225px;
$containerItemHeight: 315px;
$categoryItemWidth: 130px;
$categoryItemHeight: 130px;
$landscapeContainerItemWidth: 230px;
$landscapeContainerItemHeight: 128px;
