.spotTable {
  height: 100%;
  position: relative;
  font-family: Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.loadingSpinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root {
  width: 100%;
  background-color: #ecf1f5;
}

.pageSpinner {
  position: absolute;
  background: white;
  bottom: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadBtn {
  margin-top: 3px;
  font-size: 13px !important;
  min-height: 27px !important;
  line-height: 27px !important;
}
