.item {
  padding: 10px;
  position: relative;
  display: block;
  width: 200px;
  height: 300px;
  text-decoration: none;
}

.titleContainer {
  position: absolute;
  bottom: 0;
}

.title {
  padding: 0 10px 10px 10px;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 2;
  width: 100%;
  color: white;
  text-shadow: 1px -1px 0 rgba(0,0,0,.35);
  line-height: 1.2;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
