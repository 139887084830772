@import './variables.scss';

.item {
  position: relative;
  display: block;
  cursor: pointer;
  animation: fadeIn 2s;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {

  0% {
    opacity: 0; }

  100% {
    opacity: 1; }
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
