@import './variables.scss';

.container {
  position: relative;
  margin: 230px auto 0;
  padding-bottom: 24px;
  width: 100%;
  border-radius: 10px;
  height: $heroCarouselHeight;
}

.shadowBox{
  width: 100%;
  margin: 0 auto;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: linear-gradient(to right, #000000 30%, transparent 50%);
  cursor: pointer;
}

.heading {
  width: 550px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2% 0 0 2%;
}

.heading h1 {
  font-size: 2.5em;
  font-weight: 600;
  color: $fontColorLight;
  margin-bottom: 30px;
}

.heading h3 {
  margin-bottom: 20px;
  color: $fontColorLight;
  font-size: 16px;
  line-height: 20px;
}

.heading .details {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.heading .details .icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.heading .details .collectionName, .subtitle, .label {
  margin-right: 20px;
  font-size: 12px;
  color: $fontColorLight;
}

.subtitle{ 
  color: $fontColorGreen;
}

.heading button {
  width: fit-content;
  height: 35px;
  padding: 0 10px;
  border-radius: 50px;
  border: none;
  outline: none;
  font-weight: 700;
}

.sliderContainer{
  width: 550px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

.item div {
  width: 107px;
  height: 80px;
  border-radius: 5px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}
