@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  max-width: 1600px;
  margin-top: 15px;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px;
  @include alignSliderSpots();
}

.heading {
  color: #fff;
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  padding-bottom: 20px;
}

.heading h1 {
  color: #fff;
  font-size: 1.375rem;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-weight: 400;
}

.heading h1 a {
  color: #106ba3;
  transition: color .2s ease-in-out;
  font-size: 16px;
  float: right;
  font-weight: 600;
}

.item {
  padding-top: 25px;
  padding-right: 20px;
  display: block;
  font-size: 21px;

}

.item div {
  width: 520px;
  height: 85px;
}

@media (max-width: 2000px) {

  .container {
    width: 100%;
  }

  .item div {
    width: 450px;
  }
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid #5f6368;;
  border-width: 0 2px 2px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  opacity: 0.6;
}

.rightArrow::before,
.leftArrow::before, {
  content: '' !important;
}

.rightArrow:hover,
.leftArrow:hover {
  opacity: 1;
}

.disabledArrow {
  display: none;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -25px;
}
