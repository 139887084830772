@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.badge {
  border-radius: 2px;
  background: #F1F1F1;
  padding: 4px;
  font-weight: bold;
  font-size: 10px;
  color: black;
  text-transform: uppercase;
}

.approved {
  background: $pt-intent-success;
  color: $white;
}

.error, .artwork_refresh_error {
  background: $pt-intent-warning;
}

.unavailable {
  background: $lightRed ;
}

.badge.unavailable_forever {
  background: $vermilion1;
  color: $white;
  right: 3px;
}

.locating {
  background: $pt-intent-primary;
  color: $white;
}

.located {
  background: $cerulean5;
  color: $white;
}

.virtual {
  background: $indigo1;
  color: $white;
}
