.root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.fluidTop {
    flex: 1;
    overflow: hidden;
}
