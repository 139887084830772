@import './variables.scss';

.mosaicContainer {
  width: $containerWidth;
  margin: 50px auto;
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 26vh 26vh 26vh;
  gap: 1rem;
  
  .mosaicSpot {
    div{
      width: 100%;
      height: 100%;
    }
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    display: flex;

    &:nth-child(1) {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
      background-color: $colorDarkLight;
    }
    &:nth-child(2) {
      grid-row: 3;
      grid-column: 1 / 2;
    }

    &:nth-child(3) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
    &:nth-child(4) {
      grid-row: 2 / 4;
      grid-column: 2 / 3;
    }
    &:nth-child(5) {
      grid-row: 1 / 3;
      grid-column: 3 / 4;
    }
    &:nth-child(6) {
      grid-row: 3;
      grid-column: 3 / 4;
    }
    &:nth-child(7) {
      grid-row: 1 / 2;
      grid-column: 4 / 5;
    }
    &:nth-child(8) {
      grid-row: 2 / 4;
      grid-column: 4 / 5;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      &:hover {
        text-decoration: none;
      }
    }
  }
}