$pageWidth: 100vw;
$smContainerWidth: 70vw;
$xlContainerWidth: 90vw;
$innerContainer: 60vw;
$containerItemPadding: 15px;
$containerHeaderFontSize: 24px;
$fontFamily: sans-serif;
$colorLight: #ffffff;
$colorLightLight: #f2f2f2;
$colorDark: #333333;
$colorDarkLight: #7d7878;
$colorDarkSolid: #2b2b2b;
$colorBlack: #000;
$colorGray: #dedede;
$colorPrimary: #FF6600;
$colorRed: #fc363b;
$colorBlue: #00b2b2;
$heroCarouselHeight: 50vh;
$containerItemWidth: 9.3vw;
$containerItemHeight: 26vh;
$smContainerItemWidth: 10.3vw;
$smContainerItemHeight: 27vh;
$collectionItemWidth: 11.3vw;
$collectionItemHeight: 10vh;
$smCollectionItemWidth: 13.3vw;
$smCollectionItemHeight: 11vh;
$largeLandscapeItemWidth: 14.3vw;
$largeLandscapeItemHeight: 19vh;
$smLargeLandscapeItemWidth: 16.3vw;
$smLargeLandscapeItemHeight: 20vh;
$portraitItemWidth: 11.3vw;
$portraitItemHeight: 32vh;
$smPortraitItemWidth: 13.3vw;
$smPortraitItemHeight: 35vh;
$landscapeContainerItemWidth: 14.3vw;
$landscapeContainerItemHeight: 16vh;
$gridItemWidth: 9.5vw;
$gridItemHeight: 26vh;
$pageContainerWidth: 48vw;
$pageContainerHeight: 43vh;
$smPageContainerWidth: 61vw;
$smPageContainerHeight: 50vh;
