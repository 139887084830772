@import "./variables.scss";

.container {
  position: absolute;
  right: 0;
  white-space: nowrap;
  top: $carouselItemHeight + 75px;
  width: $sidePanelWidth;
  height: $linkSectionHeight;
  overflow: hidden;
  background-color: $itunesBackgroundColor;
}

@media(min-width: $screen-xl) {

  .container {
    width: $sidePanelWidth-xl;
    right: 40px;
  }
}
