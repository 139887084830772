.link {
  color: black;
  display: block;
  padding: 6px 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
}

.link:hover {
  text-decoration: none;
  color: black;
}

.itmem {
  width: 200px;
}

.item:hover {
  background-color: #f5f5f5;
}

.item:active {
  background-color: #e5e5e5;
}
