$appleTvBackgroundColor: #1F2122;
$appleTvGreyColor: #ffffffa3;
$containerWidth: 1600px;
$spotlightItemWidth: 100%;
$spotlightItemHeight: 69vh;

$brick2ItemWidth: 300px;
$brick2ItemHeight: 170px;
$brick2ItemMargin: 20px;

$swooshItemWidth: 300px;
$swooshItemMargin: 20px;
$swooshItemHeight: 170px;