@import './variables';

.sectionHeader {
  color: $lightColor;
  margin: 80px 0 -50px 5vw;
}
.container {
  position: relative;
  margin: 75px auto 0;
  padding-bottom: 24px;
  width: 100%;
  border-radius: 10px;
  height: $chartBackgroundHeight;
  .shadowBox {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: linear-gradient(to right, #000000 30%, transparent 50%);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .details {
      width: 40%;
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2% 0 0 2%;
      .logo {
        display: block;
        position: relative;
        top: 0%;
        max-width: 60%;
        max-height: 200px;
        margin-bottom: 15px;
      }
      .title {
        font-weight: 600;
        color: $lightColor;
        font-size: 4em;
        margin-bottom: 15px;
      }
      .ratingWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        .ratingStars {
          display: flex;
          flex-direction: row;
          margin-right: 20px;
        }
        .genre {
          color: $lightColor;
          font-size: 1.5em;
        }
      }
      .season {
        color: $lightColor;
        font-size: 1.5em;
        font-weight: bold;
      }
      .ratingIcon {
        margin-right: 15px;
      }
      .currentRating {
        background-color: #33373d;
        color: #fff;
        border-radius: 3px;
        padding: 3px 7px;
        font-weight: 700;
        font-size: 16px;
        margin-right: 15px;
        height: fit-content;
        width: fit-content;
      }
      .detailsBody {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        z-index: 1;
        margin-top: 50px;
        .shopIcon {
          margin-right: 5px;
        }
        .description {
          font-size: 1.5em;
          font-weight: 600;
          color: $lightColor;
        }
        .offerIcon {
          background-color: #33373d;
          color: #fff;
          border-radius: 15px;
          padding: 3px 10px;
          font-weight: 700;
          font-size: 16px;
          margin-right: 5px;
          height: fit-content;
        }
      }

      .subtitle {
        width: fit-content;
        border: none;
        border-radius: 10px;
        font-size: 1.5rem;
        font-weight: bold;
        color: $lightColor;
        background-color: #33373d;
        margin-top: 50px;
        padding: 15px 20px;
      }
    }

    .sliderContainer {
      width: 100vw;
      .item {
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
      }
      .item div {
        width: $containerItemWidth;
        height: $containerItemHeight;
        border-radius: 16px;
      }
    }
  }
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}
