$rowHeaderWidth: 250px;
$titleInfoWidth: 250px;
$gridCellWidth: 141px;
$gridCellHeight: 130px;
$gridCellTotalWidth: $gridCellWidth;

$moviePosterWidth: 130px;
$moviePosterHeight: 175px;

$tvPosterWidth: 130px;
$tvPosterHeight: 130px;

$headerHeight: 75px;
$lightBlue: #dfeef1;

$lightRed: #fdedee;

:export {
  rowHeaderWidth: $rowHeaderWidth;
  titleInfoWidth: $titleInfoWidth;
  gridCellWidth: $gridCellWidth;
  gridCellHeight: $gridCellHeight;
  gridCellTotalWidth: $gridCellTotalWidth;
  moviePosterWidth: $moviePosterWidth;
  moviePosterHeight: $moviePosterHeight;
  tvPosterWidth: $tvPosterWidth;
  tvPosterHeight: $tvPosterHeight;
  gridCellHeight: $gridCellHeight;
  headerHeight: $headerHeight;
  lightBlue: $lightBlue;
  lightRed: $lightRed;
}