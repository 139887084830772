@import "./variables.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 12vh 0 0 2vw;
  .firstItem {
    padding: 10px 20px;
    border: 1px solid $colorLight;
    width: 10.5vw;
    a{
      color: $colorLight;
      text-decoration: none;
    }
  }
}