@import "@blueprintjs/core/lib/scss/variables";
@import "../ScanPage/variables.scss";

.tagBtn {
  background: #670067;
  color: white;
  border-radius: 2px;
  border: none;
}

.tagBtn:hover {
  cursor: pointer;
}

.tagInput {
  max-width: 160px;
  min-width: 40px;
}

.tagInput:focus {
  border: 2px solid #670067;
}

.tagSpan {
  background-color: #dfeef1 !important;
  line-height: unset !important;
  color: black !important;
  height: 21px;
  min-width: 60px !important;
}

.tagsContainer{
  margin-top: 2px;
  max-height: 27px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}

.tagName{
  position: absolute;
  bottom: 11px;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.checkBox:hover{
  cursor: pointer;
}

.checkBox:checked{
  accent-color: #670067!important;
}

.tagsRow {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: space-between;
}

.promoted {
  border-radius: 2px;
  background: $pt-intent-success;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 21px;
  color: white;
}

.preorder {
  background-color: #458DFC !important;
  color: #FFFF !important;
}

.dropdown {
  margin: 0;
  padding: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  max-width: 182px;
}

.confirmOptions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #ddd;

  .option {
    padding-left: 25px;
    margin: 0;
  }
}

.confirmButton {
  margin: 10px;
}

.regionModal {
  width: fit-content !important;
  background-color: $white !important;

  h4 {
    font-size: 12px !important;
  }

  .platformsWrapper {
    padding-left: 15px;
    .selectorsWrapper {
      margin-left: 3px;
      .inputWrapper{
        margin-top: 20px;
        label{
          display: flex;
          flex-direction: row;
          div{
            margin-left: 5px;
          }
        }
      }
    }
  }
  .footerWrapper {
    margin: 10px;
  }
}