.item {
  line-height: 35px;
  cursor: pointer;
  color: #5f6368;
  fill: #5f6368;
  background-color: #fff;
  border: 1px #dadce0 solid;
  border-radius: 8px;
}

.item a {
  display: block;
  width: 100%;
  height: 100%;
  color: #5f6368;
  padding: 0 20px;
}

.item a:hover {
  cursor: pointer;
  color: #5f6368;
  text-decoration: none;
  background-color: rgba(0,0,0,0.05);
}
