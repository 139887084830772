@import './variables.scss';
@import '../merchandizing-mixins.scss';

@mixin itemStyle($width, $height) {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;

  div {
    width: $width;
    height: $height;
  }
}

.container {
  width: $containerWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 20px auto;
  @include alignSliderSpots();

  &.first {
    margin-top: 135px;
  }

  .headingLight, .headingDark {
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    margin-bottom: 15px;

    .headerText {
      font-size: $containerHeaderFontSize;
      font-weight: 700;
      margin-right: 12px;
      font-size: 1.6rem;
      color: $colorDark;
      z-index: 10;
      &:hover {
        color: $colorGray;
        text-decoration: none;
      }
      .highlighted {
        border-bottom: 4px solid $colorPrimary;
      }
    }
  
    .viewAll {
      font-size: 1rem;
      font-weight: 700;
      color: $colorDark;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      margin-left: auto;
      z-index: 10;
      &:hover {
        text-decoration: underline;
      }

      .rightArrow {
        margin-left: 10px;
        border: solid $colorPrimary;
        border-width: 0 3px 3px 0;
        display: block;
        cursor: pointer;
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-right: 5px;
      }
    }
    &.headingDark {
      .headerText {
        color: $colorLight;
        &:hover {
          color: $colorLight;
          text-decoration: none;
        }
      }
      .viewAll {
        color: $colorLight;
      }
    }
  }

  .heroCarouselItem {
    @include itemStyle($heroCarouselItemWidth, $heroCarouselItemHeight);
  }

  .landscapeItem {
    @include itemStyle($landscapeContainerItemWidth, $landscapeContainerItemHeight);
  }
  
  .containerItem {
    @include itemStyle($containerItemWidth, $containerItemHeight);
  }

  .largeContainerItem {
    @include itemStyle($largeContainerItemWidth, $largeContainerItemHeight);
  }
  
  .chartContainerItem {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
  }
  
  .fade.fade {
    opacity: 0.25;
  }
  
  .highlight.highlight {
    opacity: 1;
  }
  
  .sectionBackgroundImage {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    background: center center no-repeat;
    background-size: cover;
    margin: 0;
  }
  
  .sliderScrollbar {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: $colorLight;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: $heroCarouselItemWidth;
      height: 8px;
      background: gray;
      cursor: pointer;
    }
  
    &::-moz-range-thumb {
      width: $heroCarouselItemWidth;
      height: 8px;
      background: gray;
      cursor: pointer;
    }
  }
}
