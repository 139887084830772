@import './variables.scss';

.item {
  position: relative;
  display: block;
  border-radius: 16px;
  background-size: cover;
  margin-bottom: 8px;
  &:hover{
    text-decoration: none;
  }
  .spotBackground {
    background: $colorDark center center no-repeat;
    background-size: cover;
    margin-bottom: 8px;
    .heroCarouselWrapper {
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
      .heroCarouselDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 3%;
        width: 40rem;
        color: $colorLight;
        font-weight: 700;
        line-height: 2rem;
        .subtitle {
          font-size: 1.18rem;
        }
        .header {
          color: $colorLight;
          margin: 8px 0 15px 0;
        }
        .description {
          font-size: 1.25rem;
        }
        button {
          background-color: $colorPrimary;
          border-radius: 50px;
          margin-top: 30px;
          padding: 10px 20px;
          border: none;
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
    }
    .landscapeDetails {
      width: 50%;
      padding: 5% 3%;
      color: $colorLight;
      h2 {
        color: $colorLight;
        margin-bottom: 15px;
      }
    }
    .collectionDetails {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 0 0 5% 3%;
      p {
        font-size: 1.2rem;
        color: $colorLight;
      }
      h1{
        color: $colorLight;
      }
      button {
        background-color: $colorPrimary;
        border-radius: 50px;
        margin-top: 30px;
        padding: 10px 20px;
        border: none;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }
  .spotName {
    color: $colorLightDark;
    text-align: center;
    max-width: 8.7vw;
    font-size: 0.9rem;
  }
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.seeMoreItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f171e;
  color: white;
  text-shadow: 1px -1px 0 rgba(0, 0, 0, 0.35);
  font-size: 17px;
}