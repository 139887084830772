.item {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.item img {
  width: 100%;
  height: 100%;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
