
.container {
  max-width: 1129px;
  margin: 50px auto 0;
  position: relative;
  background: #000;
  border-radius: 20px;
  padding: 2% 1%;
}

.heading h2{
  color: #fff;
  margin-bottom: 40px;
}

.heading p {
  margin-top: 20px;
  color: #fbcd2d;
  font-size: 18px;
}

.heading p .label{
  color: #000;
  background: #fbcd2d;
  border-radius: 50%;
  padding: 10px 17px;
  margin-right: 15px;
}