.root {
  width: 100%;
  height: 200px;
  margin-top: 30px;
}

.root h3 {
  margin-bottom: 25px;
}

.root ul {
  columns: 5;
  padding: 0;
  list-style-type: none;
  font-size: 15px;
}

.root ul li {
  height: 30px;
  line-height: 30px;
}

.root ul li a {
  position: relative;
  border: 2px solid transparent;
  outline: 1px solid transparent;
  outline-offset: -3px;
  font-weight: 600;
  color: #0067b8;
}