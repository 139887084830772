$pageWidth: 100vw;
$containerWidth: 97vw;
$backgroundColor: #101010;
$fontColor: #fff;
$darkFontColor: #101010;
$shadowColor: #f0f0f04d;
$fontFamily: "Roboto","Arial",sans-serif;
$containerItemWidth: 10.6vw;
$containerItemHeight: 29vh;
$containerItemPadding: 15px;
$heroCarouselHeight: 53vh;
$containerHeaderFontSize: 20px;
$categoryItemWidth: 9vw;
$categoryItemHeight: 16vh;
$landscapeContainerItemWidth: 16vw;
$landscapeContainerItemHeight: 23vh;
$videoContainerItemWidth: 13.7vw;
$videoContainerItemHeight: 18vh;
$videoContainerBckColor: #333333;
$chartContainerItemWidth: 10vw;
$chartContainerItemHeight: 28vh;
$saleColor: #f9cb28;
$dropdownWidth: 634px;
$dropdownFontColor: #848585;
$releaseColor: rgb(191, 0, 0);
