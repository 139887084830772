@import "./variables.scss";

.dropdownWrapper {
  flex: 8;
  font-family: $fontFamily;
  .dropdownItem{
    color: $colorLight;
    padding: 0 20px;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .arrowDown {
      border: solid $colorLight;
      border-width: 0;
      display: block;
      cursor: pointer;
      width: 15px;
      height: 15px;
      box-shadow: 2px 2px $colorLight;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin: 0 15px 14px 0;
    }
  }
  &:hover{
    .dropdownItem {
      text-decoration: none;
      color: $colorLight;
    }
  }
}
.dropdown {
  min-width: $dropdownWidth;
  height: fit-content;
  background: $colorDark;
  position: relative;
  margin-top: -4px;
  .dropdownList {
    margin: 3%;
    padding: 3%;
    list-style: none;
    display: flex;
    flex-direction: column;
    .listItem {
      width: 100%;
      height: 40px;
      margin: 5px;
      a {
        color: $colorLight;
        font-weight: 700;
        font-size: 1rem;
        &:hover{
          text-decoration: none;
          color: $colorLightDark;
          border-bottom: 3px solid $colorPrimary;
        }
      }
      .activeLink {
        border-bottom: 3px solid $colorPrimary;
      }
    }
  }
}

