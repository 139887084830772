@import './variables.scss';

.container {
  max-width: 1220px;
  margin: 0 auto;
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
}

.spotWrapper {
  display: flex;
  justify-content: flex-start;
}

.spotWrapper img {
  margin-top: 16px;
  margin-right: 18px;
}

.priceImage img {
  width: 158px;
  height: 90%;
}

.heading {
  margin-bottom: 40px;
}

.heading h3 {
  font-weight: 700;
  color: $light;
}

.heading h3 a {
  float: right;
  color: $light;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
}

.heading h3 a:hover {
  text-decoration: none;
  color: $light !important;
}

.viewAllArrow {
  border: solid $light;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin: 0 10px 0 0;
  cursor: pointer;
  width: 12px;
  height: 12px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.quarterItem, .halfItem, .fullItem {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  opacity: 1;
}

.quarterItem div {
  width: $quarterDealaItemWidth;
  height: $quarterDealItemHeight;
  margin: 15px 13px;
}

.halfItem div {
  width: $halfDealaItemWidth;
  height: $halfDealItemHeight;
  margin: 15px 13px;
}

.fullItem div {
  width: $fullDealaItemWidth;
  height: $fullDealItemHeight;
  margin: 15px 13px;
}

.headerImage {
  margin: 122px auto 0;
  background-position: center;
  background-size: contain;
  width: 1220px;
  height: 300px;
}