.root {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
}

.fluidLeft {
    flex: 1;
    overflow: auto;
}
