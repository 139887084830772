@import './variables.scss';

.disabledArrow{
  display: none !important;
}

.arrowWrapperRight, .arrowWrapperLeft {
  position: absolute;
  top: 0;
  width: 50px;
  height: 99%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(255,255,255,0.1), rgba(0,0,0,0.5));
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  &.arrowWrapperLeft {
    left: 0px;
  }
  &.arrowWrapperRight {
    right: 15px;
  }
  .rightArrow, .leftArrow {
    border: solid $colorLight;
    border-width: 0 3px 3px 0;
    display: block;
    cursor: pointer;
    width: 15px;
    height: 15px;
    box-shadow: 2px 2px rgb(168, 168, 168);
    @mixin arrowRotation($angle) {
      transform: rotate($angle);
      -webkit-transform: rotate($angle);
    }
    &.rightArrow {
      @include arrowRotation(-45deg);
    }
    &.leftArrow {
      @include arrowRotation(135deg);
    }
  }
}