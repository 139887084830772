.item {
  position: relative;
  display: block;
}

.titleContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABgCAYAAADcvRh2AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABV0RVh0Q3JlYXRpb24gVGltZQAzLzEyLzEzP/y5FwAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAABHSURBVBjTpZDRCgAgCANvC/r/T+5NSDSKXmTouOEApgEZsIERysXu1teOd0sKH/uBUBdkFwAdgxJUz5+nDO9kffSi7hlFLyx6jQGLlHpJqgAAAABJRU5ErkJggg==') left bottom repeat-x;
  background-size: 100% 96px;
}

.title {
  padding: 0 10px 10px 10px;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 2;
  width: 100%;
  color: white;
  text-shadow: 1px -1px 0 rgba(0,0,0,.35);
  line-height: 1.2;
}

.seeMoreItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f171e;
  color: white;
  text-shadow: 1px -1px 0 rgba(0,0,0,.35);
  font-size: 17px;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
