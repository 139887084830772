@import './variables.scss';
@import '../merchandizing-mixins.scss';

.saleContainer{
  background: linear-gradient(rgba(249, 203, 40, 0) 11.35%, rgba(249, 203, 40, 0.24) 100%);
}

.container {
  width: $containerWidth;
  position: relative;

  margin: 80px auto 0;
  @include alignSliderSpots();

  &.first {
    margin-top: 210px;
  }

  .heading, .saleHeading {
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    h1 {
      font-size: $containerHeaderFontSize;
      font-weight: 700;
      margin-right: 12px;
    }
    &.heading h1 {
      color: $fontColor;
    }
  
    &.saleHeading h1 {
      color: $saleColor;
    }
    a {
      font-size: 14px;
      color: $fontColor;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease; 
      &:hover {
        text-decoration: none;
      }
      span {
        margin-left: 10px;
        border: 0.5px solid #fff;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
      }
      .rightArrow {
        border: solid $fontColor;
        border-width: 0 2px 2px 0;
        display: block;
        cursor: pointer;
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-right: 5px;
      }
    }
    &:hover {
      a {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .containerItem, .categoryItem, .landscapeContainerItem, .videoContainerItem, .chartContainerItem {
    padding-right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 14px;
  }

  .containerItem, .landscapeContainerItem, .videoContainerItem, .chartContainerItem {
    display: block;
  }

  .containerItem {
    div {
      width: $containerItemWidth;
      height: $containerItemHeight;
    }
  }

  .landscapeContainerItem {
    div {
      width: $landscapeContainerItemWidth;
      height: $landscapeContainerItemHeight;
    }
  }

  .videoContainerItem {
    div {
      width: $videoContainerItemWidth;
      height: $videoContainerItemHeight;
    }
  }

  .categoryItem {
    display: flex;
    justify-content: center;
  }

  .categoryItem {
    div {
      width: $categoryItemWidth;
      height: $categoryItemHeight;
      border-radius: 50%;
    }
  }

  .chartContainerItem {
    div {
      width: $chartContainerItemWidth;
      height: $chartContainerItemHeight;
    }
  }

  .fade.fade {
    opacity: 0.25;
  }

  .highlight.highlight {
    opacity: 1;
  }
}
