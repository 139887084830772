@import './variables.scss';

.container {
  width: 70%;
  margin: 100px 15% 0 15%;
  position: relative;
  padding-bottom: 24px;
}

.spotWrapper{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.heading {
  padding-bottom: 3px;
  margin: 0 0 20px 20px;
}

.heading h2 {
  font-size: $gridHeading;
  font-weight: 700;
  color: white;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  opacity: 1;

}

.item div {
  width: $gridItemWidth;
  height: $gridItemHeight;
  margin-bottom: 10px;
  transition: all .2s ease-in-out;
}

.item div:hover {
  transform: scale(1.05);
}

.item h5{
  color: #fff;
  margin-bottom: 20px;
  max-width: 200px;
}
