@import './variables';

.container {
  width: $containerWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 100px auto 0;
  &.first {
    margin-top: 100px;
  }
  .sectionHeader {
    position: relative;
    margin: 100px auto 0;
    h1 {
      color: $colorLight;
      margin: 0 0 25px 0;
    }
    p {
      font-size: 1.2rem;
    }
  }
  .spotWrapper{
    display: grid;
    grid-template-columns: repeat(9, 11vw);
  }
  .gridItem {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    float: left;
    div {
      width: $gridItemWidth;
      height: $gridItemHeight;
      &:hover {
        transform: scale(1.03);
        transform-origin: top;
      }
    }
  }
}
.sectionBackgroundImage {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
}