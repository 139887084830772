.section {
  max-width: 1300px;
  margin: auto;
  height: 680px;
}

.menu {
  position: relative;
  box-shadow: 0 1px 0 white;
  background: white;
  height: 40px;
  display: flex;
  flex-direction: row;
}

.list {
  list-style: none;
}

.dropdown {
  background-color: white;
}

.button {
  padding: 0 14px;
  line-height: 60px;
  cursor: pointer;
}

.item {
  justify-content: center;
  margin-right: 10px;
  padding: 5px 20px 0px 20px;
  list-style-type: none;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  letter-spacing: .25px;
  line-height: 18px;
  cursor: pointer;
  color: #8a0d37;
  fill: #8a0d37;
  background-color: #f7dde6;
  border: 1px #f7dde6 solid;
  border-radius: 20px;
}

.item:hover {
  list-style-type: none;
  cursor: pointer;
  color: #8a0d37;
  background-color: #fff;
  text-decoration: none;
  background-color: #d2cbce;
  border: 1px #d2cbce solid;
}

.menuItems {
  padding: 0px;
  display: flex;
}

.heading {
  color: #202124;
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  padding-bottom: 20px;
}

.heading h2 {
  font-size: 1.375rem;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-weight: 400;
}