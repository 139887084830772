@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  max-width: 1220px;
  margin: 140px auto 0;
  position: relative;
  @include alignSliderSpots();
}

.container + .container {
  margin-top: 40px ;
}

.heading {
  padding-bottom: 3px;
}

.heading h2 {
  font-size: $sectionHeadingFontSize;
  font-weight: 700;
  color: white;
}

.heading h2 a{
  float: right;
  margin-right: 20px;
  color: $light;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
}

.heading h2 a:hover{
  text-decoration: none;
  color: $light !important;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: $squareCarouselItemWidth;
  height: $squareCarouselItemHeight;
}

.fade {
  opacity: 0.25;
}

.highlight {
  opacity: 1;
}

.disabledArrow {
  opacity: 0;
}

.rightArrow,
.leftArrow {
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 5;
  top: 40%;
}

.leftArrow {
  left: -40px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.rightArrow {
  right: -20px;
}

.viewAllArrow {
  border: solid $light;
  border-width: 0 1px 1px 0;
  display: inline-block;
  margin: 0 10px 0 0;
  cursor: pointer;
  width: 12px;
  height: 12px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
