@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: 100%;
  margin: 100px 7px 0 7px;
  position: relative;
  float: left;
  clear: both;
  @include alignSliderSpots();
}

.container + .container {
  margin-top: 50px;
}

.heading {
  padding-bottom: 3px;
}

.heading h2 {
  font-size: 25px;
  font-weight: 700;
  color: white;
}

.item div {
  width: $containerItemWidth;
  height: $containerItemHeight;
}

.itemRectangle div {
  width: $containerRectangleItemWidth;
  height: $containerRectangleItemHeight;
}

@media (max-width: 1920px) {

  .item div {
    width: $responsiveItemWidth;
    height: $responsiveItemHeight;
  }

  .itemRectangle div {
    width: $responsiveRectangleItemWidth;
    height: $responsiveRectangleItemHeight;
  }
}

.fade {
  opacity: 0.25;
}

.highlight {
  opacity: 1;
}

.arrowWrapperRight, .arrowWrapperLeft {
  position: absolute;
  width: 57px;
  height: 74px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.arrowWrapperLeft{
  left: 10px;
  top: 45%;
}

.arrowWrapperRight{
  right: 10px;
  top: 45%;
}

.rightArrow,
.leftArrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: block;
  cursor: pointer;
  opacity: 0;
}

.rightArrow:hover,
.leftArrow:hover {
  border-left: 0px;
  border-top: 0px;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 15px;
  width: 30px;
  height: 30px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 15px;
  width: 30px;
  height: 30px;
}

.container:hover .arrowWrapperRight, .container:hover .arrowWrapperLeft {
  background-color: rgba(18,35,57,.9);
}

.container:hover .rightArrow, .container:hover .leftArrow {
  opacity: 1;
}

.disabledArrow {
  opacity: 0;
}
