@import './variables.scss';

.sectionHeader {
  display: flex;
  margin: 20px 0;
}

.sectionHeader .seeMoreLink {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-weight: 800;
}

.scrollWrapper {
  max-width: 1600px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.scrollableItem {
  width: 160px;
  height: 305px;
  margin-right: 8px;
  display: inline-block;
}

.tubiScrollable {
  max-width: 1500px;
  margin-top: 10px;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px;
}

.tubiScrollable .scrollableItem {
  width: 16%;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  font-size: 16px;
  color: #fafafa;
}

.tubiScrollable .sectionHeader {
  position: relative;
  margin: 10px 0;
}

.tubiScrollable .sectionHeader .heading {
  display: inline-block;
  width: 69%;
  margin: 0;
  padding: 0;
  text-align: left;
}

.tubiScrollable .sectionHeader h3 {
  display: flex;
  font-size: 22px;
  font-weight: 800;
  color: #fafafa;
}

.tubiScrollable .seeMoreLink {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: right;
  padding-right: 20px;
}

.tubiScrollable .sectionHeader .seeMoreLink a {
  color: #ffffff;
  outline: 0;
  font-size: 15px;
  line-height: 1.5;

}