$containerWidth: 90vw;
$carouselHeight: 59vh;
$chartBackgroundHeight: 110vh;

$containerItemWidth: 17vw;
$containerItemHeight: 19vh;
$containerItemPadding: 15px;

$largeCarouselItemWidth: 17vw;
$largeCarouselItemHeight: 50vh;
$largeItemPadding: 15px;

$sectionHeadingFontSize: 20px;
$logoWidth: 144px;
$dropdownWidth: 300px;

$navbarWidth: 91vw;
$navbarHeight: 75px;
$navbarBackgroundColor: rgb(25 26 33 / 64%);

$itemWithPositionWidth: 15vw;
$itemWithPositionHeight: 17vh;

$darkColor1: #191e25;
$darkColor2: #00050d;

$lightColor: #ffffff;

$grayColor: #aaaaaa;

$yellowColor: #fc0;

$blueColor1: #1c222c;
$blueColor2: #147ace