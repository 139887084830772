.menu {
  position: absolute;
  top: 0;

  box-shadow: 0 1px 0 rgba(0,0,0,0.05);
  background: #fff;
  height: 48px;
  width: 100%;
  padding-left: 38px;

  display: flex;
  flex-direction: row;
}

.list {
  list-style: none;
}

.dropdown {
  background-color: white;
}

.button {
  padding: 0 14px;
  line-height: 48px;
  cursor: pointer;
}

.dropdownIcon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAQAAACR313BAAAAaElEQVR4AWP4jxfSXjqXrygzVwYhmClYmJUlAZcuqiz+X/w4VxkimS1cdKH4f9EcuHShbvEHiAK45K9CNyS7880hCvKMIZLFATC7URX8hEkipBEKvgMV/IVKYnqsMKL4ZVElHUMNLwQAZCvi2TxdGw0AAAAASUVORK5CYII=);
  background-position-y: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 15px;
  margin: 17px 3px 1px 3px;
  vertical-align: top;
  width: 15px;
}
