@import './variables.scss';

@mixin labelStyling($color) {
  color: $color;
  font-size: 12px;
  background-color: rgba(0, 0, 0, .1);
  padding: 2px 3px;
  border-radius: 2px;
  margin-right: 5px;
}

.item {
  position: relative;
  display: block;
  border-radius: 16px;
  &:hover {
    text-decoration: none;
  }
  .detailWrapper{
    color: $fontColor;
    font-size: 14px;
    .subtitle {
      @include labelStyling($fontColorGreen);
    }
    .rating {
      @include labelStyling($shadowFontColor);
    }
  }
  .fade {
    opacity: 0.2;
  }
  .highlight {
    opacity: 1 !important;
  }
}