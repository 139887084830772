@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: $containerWidth;
  position: relative;
  margin: 80px auto 0;
  @include alignSliderSpots();
  &.first {
    margin-top: 210px;
  }

  .heading {
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    h1 {
      font-size: $containerHeaderFontSize;
      font-weight: 700;
      margin-right: 12px;
      font-size: 1.6rem;
      color: $colorLight;
    }  
    a {
      margin: 0 0 8px 10px;
      font-size: 0.8rem;
      color: $colorLight;
      display: flex;
      align-items: center;
      opacity: 0;
      .rightArrow {
        margin-left: 10px;
        border: solid $colorLight;
        border-width: 0 1px 1px 0;
        display: block;
        cursor: pointer;
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-right: 5px;
      }
    }
    &:hover {
      a {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .containerItem, .landscapeContainerItem, .largeLandscapeContainerItem, .portraitContainerItem {
    padding-right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 14px;
    display: block;
  }

  .containerItem {
    div {
      width: $containerItemWidth;
      height: $containerItemHeight;
      border-radius: $itemBorderRadius;
    }
  }

  .landscapeContainerItem {
    div {
      width: $landscapeContainerItemWidth;
      height: $landscapeContainerItemHeight;
      border-radius: $itemBorderRadius;
    }
  }

  .largeLandscapeContainerItem {
    div {
      width: $largeLandscapeContainerItemWidth;
      height: $largeLandscapeContainerItemHeight;
      border-radius: $itemBorderRadius;
    }
  }

  .portraitContainerItem {
    div {
      width: $portraitContainerItemWidth;
      height: $portraitContainerItemHeight;
      border-radius: $itemBorderRadius;
    }
  }

  .fade.fade {
    opacity: 0.25;
  }

  .highlight.highlight {
    opacity: 1;
  }
}
