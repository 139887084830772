@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: 100%;
  margin-top: 110px;
  position: relative;
  float: left;
  clear: both;
  @include alignSliderSpots();
}

.heading {
  padding-bottom: 3px;
  margin-left: 20px;
}

.heading h2 {
  font-size: $sectionHeadingFontSize;
  font-weight: 700;
  color: white;
}

.heading h2 a{
  color: #fff;
  text-decoration: none;
}

.heading h2 a:hover{
  text-decoration: none;
  color: #fff !important;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: $squareCarouselItemWidth;
  height: $squareCarouselItemHeight;
}

.fade {
  opacity: 0.25;
}

.highlight {
  opacity: 1;
}

.rightWrapper,
.leftWrapper {
  background: transparent;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

.disabledArrow {
  opacity: 0;
}

.rightWrapper{
  right: 0;
}

.leftWrapper{
  left: 0;
}

.rightArrow,
.leftArrow {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  z-index: 5;
}

.container:hover .rightWrapper,
.container:hover .leftWrapper {
  opacity: 1;
}

.leftArrow {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
