@import "@blueprintjs/core/lib/scss/variables";

.suggestionsContainer {
  display: none;
  scrollbar-width: none;
}
.suggestionsContainerOpen {
  background-color: $white;
  border: 1px solid $gray4;
  display: block;
  font-size: 16px;
  max-height: 500px;
  overflow-y: scroll;
  position: absolute;
  top: 65px;
  width: 100%;
}
.navBar {
  position: relative;
  .searchBar{
    display: flex;
    background-color: $white;
    height: 30px;
    width: 320px;

    .search {
      border-right: 1px solid $gray5;
      order: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      font-size: 20px;
    }

    .tag {
      display: flex;
      order: 2;
      max-width: 320px;
      max-height: 64px;
      height: fit-content;
      background: $white;
      overflow-y: hidden;
      scrollbar-width: none;
      .span {
        background-color: $light-gray1;
        color: $black;
        flex-shrink: 0;
        max-width: 120px;
        min-width: 60px;
      }
    }
    .tag::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
    .tag::-webkit-scrollbar-track {
      background: $gray1;
    }
    .input {
      border: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      box-shadow: none;
      max-width: 100%;
      min-width: 20%;
      order: 3;
      padding-left: 5px !important;
      width: unset;
    }
  }
}
.suggestionContent {
  align-items: center;
  background-repeat: no-repeat;
  display: flex;
}
.suggestionContentImage {
  height: 60px;
  width: 45px;
}
.suggestionContentInfo {
  padding: 10px;
  p {
    font-size: 12px;
    min-height: 20px;
  } 
  h5 {
    margin: auto 0;
    font-weight: 600;
  }
}
.suggestionsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.suggestion {
  cursor: pointer;
  padding: 4px 13px;
}
.suggestion:not(:first-child) {
  border-top: 1px solid $gray5;
}
.suggestionHighlighted {
  background-color: $gray5;
}
