.item {
  padding: 0 14px;
  line-height: 48px;
  background: #d6d6d6;
  width: 1px;
  line-height: 48px;
  height: 64%;
  margin: 9px 5px;
  padding: 0;
}
