@import './variables.scss';

.root {
  position: absolute;
  top: 0;
  background: #000;
  color: #eee;
  font-weight: 200;
  z-index: 8;
  width: 100%;
  min-width: 320px;
  margin: auto;
  padding: 0;
}

.wrapper{
  width: 1240px;
  margin: 0 auto;
  display: flex;
}

.logo {
  width: 70px;
  height: 70px;
  display: inline;
}

.logo img {
  height: 70px;
}

.mediaNav {
  position: relative;
  font-size: 18px;
  list-style: none;
  margin: 0 0 0 30px;
  padding: 0;
}

.listItem {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  margin-right: 25px;
  margin-top: 10px;
}

.active {
  border-bottom: 3px solid white;
}

.listItem a {
  color: white;
  float: none;
  font-size: 18px;
}

.listItem a:hover {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.listItemNav {
  font-size: 18px;
  font-weight: 500;
  display: block;
  padding: 16px 0;
}

.listItemNav:hover {
  cursor: pointer;
  text-decoration: none;
  display: block;
}

.dropdownItem {
  float: none;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  font-size: 14px;
  vertical-align: middle;
  margin: 8px 0;
}

.dropdownItem a {
  color: white;
}

.dropdownItem a:hover {
  color: white;
  text-decoration: none;
}

.dropdown {
  background-color: rgba(0, 0, 0, .9);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 29px;
  left: 71vw;
  white-space: nowrap;
  list-style-type: none;
  z-index: 1000;
  min-width: 99.7vw;
  height: 530px;
  padding: 1%;
}

.dropdown ul {
  list-style: none;
  margin: 10px 70px;
  padding: 0;
}

.dropdownItem:first-child{
  font-size: 18px;
}

.dropdownItem:last-child .dropdownItemLink {
  color: #00a8e1;
}

.dropdownItem a h3{
  color: #fff;
}

.rightArrow{
  transform: rotate(-45deg);
  border: solid 1px #106ba3;
  border-width: 0 2px 2px 0;
  display: inline-block;
  cursor: pointer;
  width: 9px;
  height: 9px;
  margin-left: 10px;
}