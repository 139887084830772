@import "./variables.scss";

.root {
  color: white;
}

.header {
  color: white;
}

.text {
  color: #ddd;
  font-size: 12px;
  padding-bottom: 10px;
  counter-increment: chart-counter;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.text:hover {
  color: #ddd;
}

.item {
  clear: both;
  position: relative;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
}

.item::before {
  content: counter(chart-counter)".";
  color: #aaa;
  position: absolute;
  left: -15px;
  text-align: right;
  width: 20px;
  font-size: 12px;
}

.genre {
  color: #aaa;
  font-size: 11px;
}

.sectionFade {
  opacity: 0.2;
}

.spotHighlight {
  opacity: 1 !important;
}

.poster {
  width: 43px;
  height: 65px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 20px;
  margin-left: 10px;
  margin-bottom: 10px;

  flex: 0 0 43px;
}
