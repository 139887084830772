.root {
  background: #080808;
  padding: 20px 0;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.root > .toutItem {
  padding-right: 10px;
  width: 100%;
  max-width: 225px;
  height: 100px;
}