@import '../merchandizing-mixins.scss';

.container {
  min-width: 768px;
  height: 77vh;
  margin: 0 0px 60px 0;
  position: relative;
  padding-top: 55px;
  @include alignSliderSpots();
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  min-width: 1290px;
  height: 75vh;
}

.item div{
  min-width: 1290px;
  height: 77vh;
}

.fade {
  opacity: 0.25;
}

.highlight {
  opacity: 1;
}

.rightWrapper,
.leftWrapper {
  background: transparent;
  width: 8%;
  height: 80%;
  position: absolute;
  top: 10%;
  display: flex;
  justify-content: center;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

.rightWrapper{
  right: 0;
}

.leftWrapper{
  left: 0;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  z-index: 5;
}

.container:hover .rightWrapper,
.container:hover .leftWrapper {
  opacity: 1;
}

.leftArrow {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.slickActive {
  border-radius: 50%;
  background: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 10px !important;
  height: 10px !important;
  margin: 5px !important;
  margin-right: 7px !important;
}

.slickNormal {
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 10px !important;
  height: 10px !important;
  margin: 5px !important;
  margin-right: 7px !important;
}
