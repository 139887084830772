.matchPanel.matchPanel {
  height: 137px;
  display: flex;
  align-items: center;
}

.matchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: scroll;
}

.matchSpinnerContainer {
  width: 70px;
}