@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.headerBase {
  width: 100%;
  height: $headerHeight !important;
  display: flex;
  align-items: center;
  box-shadow: none !important;
}

.header {
  @extend .headerBase;
}

.staticHeader {
  position: fixed !important;
  @extend .headerBase;
}

.divider {
  border-left: 1px solid transparent;
}

.btnDefault {
  box-shadow: inset 0 0 0 1px rgba(223, 238, 241, 0.2), inset 0 1px 2px rgba(223, 238, 241, 0.2) !important;
  background-color: $white !important;
  border: 1px solid $lightBlue !important;
  &.active, &:hover {
    background-color: $lightBlue !important;
  }
}
