@import './variables.scss';

.root {
  padding: 20px 0 20px 20px;
}

.header {
  min-height: 37px;
  padding: 5px;
  position: relative;
}

.sectionHeader {
  display: flex;
  min-height: 37px;
  margin: 20px 0;
  position: relative;
}

.sectionHeader .seeMoreLink {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-weight: 800;
}

.spotList {
  height: 340px;
  overflow: hidden;
}

.hasBackground {
  margin: 17px 15px 21px 0;
}

.showAll {
  height: auto !important;
  max-height: none;
}

.spotItem {
  display: inline-block;
  position: relative;
  height: 330px;
  width: 160px;
}

.vuduContentList,  .vuduPrimaryList, .vuduGrid{
  width: 1114px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin: 0 auto;
}

.vuduContentList .spotItem, .vuduPrimaryList .spotItem, .vuduGrid .spotItem {
  height: $vuduContentListItemHeight;
  box-sizing: border-box;
  margin: $vuduContentListItemTopMargin $vuduContentListItemRightMargin 0 0;
  width: $vuduContentListItemWidth;
}

.vuduContentList .spotItem:nth-child(7n) {
  margin-right: 0;
}

.vuduContentList .heading h3, .vuduPrimaryList .heading h3, .vuduGrid h1, .vuduGrid h3 {
  color: white;
}

.vuduPrimaryList {
  width: 1275px;
}

.vuduGrid {
  width: 1129px;
}

.vuduPrimaryList .spotList, .vuduGrid .spotList {
  width: 100%;
}

.vuduPrimaryList .spotItem, .vuduGrid .spotItem {
  width: $vuduPrimaryListItemWidth;
}

.vuduPrimaryList .spotItem:nth-child(8n) {
  margin-right: 0;
}

.vuduSeeTheSpot {
  text-decoration: none;
  color: #fff;
  padding: 15px 80px;
  background: #0098db;
  margin: 70px auto;
  font-size: 20px;
  font-weight: 600;
}

.vuduSeeTheSpot:hover {
  color: #fff;
  text-decoration: none;;
}
