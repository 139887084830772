@import "@blueprintjs/core/lib/scss/variables";

.brandCard {
  flex: 1;
  white-space: nowrap;
  display: flex;
  margin-bottom: 20px;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid $light_gray3 !important;
}

.brandCardHeader {
  color: $dark-gray1;
}

.brandCardImg {
  float: right;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.4);
  transition: box-shadow 0.3s ease-in-out;
}

.brandCardImg:hover {
  box-shadow: 0 4px 4px rgba(0,0,0,0.4);
}

.brandClear {
  clear: both;
}

.left {
  min-width: 0;
  flex: 2;
  margin-right: 10px;

}

.right {
  flex: 1;
}
