$rowHeaderWidth: 350px;
$titleInfoWidth: 300px;
$gridCellWidth: 150px;
$tvodCellWidth: 150px;
$avodCellWidth: 50px;
$svodCellWidth: 80px;
$seasonGridCellWidth: 75px;
$totalRowHeaderWidth: $rowHeaderWidth * 2;
$gridCellHeight: $gridCellWidth * 0.8;
$seasonGridCellHeight: $seasonGridCellWidth * 1.6;
$gridCellTotalWidth: $tvodCellWidth + $tvodCellWidth + $svodCellWidth;
$ghostCell: 1;
$borderWidth: 1px;
$borderColor: #EBF1F5;
$notAppliedExceptionColor: #00E3E7;
$headerHeight: 75px;
$lightBlue: #dfeef1;

:export {
  rowHeaderWidth: $rowHeaderWidth;
  rowHeaderWidth: $rowHeaderWidth;
  titleInfoWidth: $titleInfoWidth;
  gridCellWidth: $gridCellWidth;
  tvodCellWidth: $tvodCellWidth;
  avodCellWidth: $avodCellWidth;
  svodCellWidth: $svodCellWidth;
  seasonGridCellWidth: $seasonGridCellWidth;
  totalRowHeaderWidth: $totalRowHeaderWidth;
  gridCellHeight: $gridCellHeight;
  seasonGridCellHeight: $seasonGridCellHeight;
  gridCellTotalWidth: $gridCellTotalWidth;
  ghostCell: $ghostCell;
  borderWidth: $borderWidth;
  borderColor: $borderColor;
  notAppliedExceptionColor: $notAppliedExceptionColor;
  headerHeight: $headerHeight;
  lightBlue: $lightBlue;
}