@import "./variables.scss";

.root {
  position: fixed;
  z-index: 2;
  width: $pageWidth;
  height: fit-content;
  line-height: 67px;
  display: flex;
  flex-direction: row;
  color: $colorDark;
  background: $colorLight;
  .pageName {
    margin: 30px 0 30px 11vw;
  }
  .menuLinks {
    width: $navbarWidth;
    height: 70px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .listItem, .listItemRight {
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
      color: $colorDark;
      font-family: $fontFamily;
      span {
        padding: 3px 20px;
      }
      &:hover{
        text-decoration: none;
        color: $colorGray;
      }
      &.listItem {
        margin-right: 25px;
      }
      &.listItemRight {
        margin-left: 13vw;
      }
    }
    .activeNav {
      border-bottom: 4px solid $colorPrimary;
    }
  }
}
