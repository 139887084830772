@import './variables.scss';

.container {
  width: $pageWidth;
  background-color: $colorDarkLight;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $subHeadingHeight;
  margin-bottom: 20px;
  p {
    font-weight: bold;
    font-size: 17px;
    a{
      color: $colorLight;
      padding: 10px 15px;
      background-color: $colorGrayLight;
      margin-left: 30px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}