$xboxItemWidth: 162px;
$xboxItemHeight: 325px;
$xboxItemMargin: 12px;
$xboxItemBackroundColor: #fafafa;

$fandangoItemWidth: 12.5%;
$fandangoItemHeight: 270px;
$fandangoItemPadding: 20px;

$tubiItemWidth: 16.5%;
$tubiItemHeight: 500px;
$tubiItemPadding: 10px;