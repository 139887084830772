.root {
  width: 100%;
  height: 600px;
}

.root ul {
  list-style-type: none;
  padding-left: 0;
  height: 100%;
}

.root ul li {
  float: right;
  width: 33.33333%;
  height: 50%;
  padding-bottom: 5px;
}

.root ul li:first-child {
  float: left;
  width: 66.66667%;
  padding-right: 4px;
  height: 100%;
}