@import "./variables.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 5rem 0 2rem 0;
  position: relative;
  z-index: 20;
  .firstItem {
    padding: 10px 20px;
    border: 1px solid $colorLight;
    width: 10.5vw;
    a{
      color: $colorLight;
      text-decoration: none;
      .arrowDown {
        border: solid $colorLight;
        border-width: 0 1px 1px 0;
        display: inline-block;
        cursor: pointer;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin: 0 0 3px 5px;
      }
    }
  }
}
.dropdown {
  width: 10.5vw;
  background-color: $colorDarkLight;
  .dropdownList {
    list-style: none;
    padding: 0;
    margin: 0;
    .listItem {
      a{ 
        text-decoration: none;
        color: $colorLight;
        display: block;
        padding: 10px 15px;
        margin: 1%;
        border: 2px solid transparent;
        font-weight: bold;
      }
    }
  }
}