@import "@blueprintjs/core/lib/scss/variables";

.header {
  width: 100%;
  background-color: $violet1 !important;
}

.buttonStyle {
  border-radius: 0px;
  border: none;
}

.heading {
  display: block;
  font-size: 16px;
  margin: 8px 0;
  font-weight: bold;
}

.downloadCollapse {
  flex-direction: column;
  margin-top: 46px;
  align-items: flex-end !important;
  max-width: 200px;
  height: 75px !important;
}

.preCollapse {
  background: $white;
  position: absolute;
  right: 0;
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-family:  Arial, sans-serif !important;
  font-size: 13px;
  font-weight: 400;
  width: fit-content;
  max-height: 650px;
  overflow: auto;
  padding: 10px;
}

.inProgress {
  margin: 0 0 2px;
  font-family: 'IBM Plex Sans' !important;
}

.spinner {
  width: 15px !important;
}

.collapseItems {
  padding: 4px;
  font-family: 'IBM Plex Sans' !important;
}

.collapseList {
  max-height: 300px;
  overflow-x: auto;
  background: $white;
  padding: 15px 0;
  border-top: 1px solid rgba(16, 22, 26, 0.15);
}

.downloadLinks {
  color: black;
  font-family: 'IBM Plex Sans' !important;
}

.downloadLinks:hover {
  color: #670067 !important;
}

.downloadBtn {
  position: sticky;
  width: 100%;
}