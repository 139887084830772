@import './variables.scss';

.container {
  width: $containerWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 50px auto 0;
  &.first {
    margin-top: 50px;
  }
  .sectionHeader {
    position: relative;
    margin: 100px auto 0;
    
    h1 {
      color: $colorLight;
      margin: 0 0 25px 0;
    }
    p {
      font-size: 1rem;
      white-space: pre-line;
      max-width: 70ch;
      padding-bottom: 50px;
    }
  }
  .spotWrapper{ 
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
  }
  .gridItem {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    float: left;
    margin: 0 20px 25px 0;
    div {
      width: $containerItemWidth;
      height: $containerItemHeight;
    }
    @media (max-width: 2400px) {
      div {
        width: 14.5vw;
        height: 38vh;
      }
    }
  }
}

.sectionBackgroundImage {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
}