@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: $pt-navbar-height;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  overflow-x: auto;
}

.downloadButton {
  span {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.spinnerWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.artworkNotFoundWrapper {
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .icon {
    width: auto;
    height: 50px;
    margin-bottom: 25px;
    opacity: 0.3;
  }
  h1{
    font-size: 20px;
    color: $dark-gray5;
    margin-bottom: 15px;
  }
  p {
    color: $dark-gray5;
    font-size: 16px;
  }
}