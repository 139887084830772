@import './variables.scss';

.spotDetails {
  display: flex;
  flex-direction: column;
  max-width: $containerItemWidth;
  font-size: 12px;

  .name {
    font-size: 15px;
    font-weight: bold;
    color: $colorDark;
    margin-bottom: 5px;
  }

  .label, .free, .payment {
    margin-bottom: 15px;
  }

  .label {
    color: $colorDarkLight;
    font-weight: bold;
  }

  .free {
    color: $colorDarkLight;
    font-weight: bold;
  }

  .payment {
    color: $colorBlue;
  }

  .subscription {
    color: $colorPrimary;
    margin: 15px 0;

    .label {
      background-color: $colorRed;
      border-radius: 5px;
      color: $colorLight;
      margin-right: 10px;
      padding: 3px 5px;
    }
  }
}