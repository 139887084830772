.item {
  position: relative;
  display: block;
  cursor: pointer;
  animation: fadeIn 2s;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.seeMoreItem {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #16181E;
  color: white;
  text-shadow: 1px -1px 0 rgba(0, 0, 0, .35);
  font-size: 17px;
  text-decoration: none;
  flex-direction: column;
  cursor: pointer;
}

.seeMoreLabel{
  font-size: 18px;
  font-weight: 700;
  color: #FDB516;
}

.seeMoreLabel:hover{
  text-decoration: none;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
