.item {
  height: 100%;
  width: 100%;
}

.item .itemImageContainer {
  height: 80%;
}

.item .itemDescriptionContainer {
  height: 20%;
  display: flex;
  white-space: pre-wrap;
  padding: 5px;
}

.channelRoundItem {
  border-radius: 50%;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
