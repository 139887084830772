@import "@blueprintjs/core/lib/scss/variables";
@import "./_variables";
@import "./mixins.scss";

.brandCard {
  flex: 1;
  white-space: nowrap;
  display: flex;
  margin-bottom: 20px;
  border: none !important;
  border-bottom: 1px solid $light_gray3 !important;
  box-shadow: none !important;
}

.brandCardHeader {
  color: $gray3;
  position: relative;
}

.brandCardImg {
  float: right;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.4);
  transition: box-shadow 0.3s ease-in-out;
}

.brandCardImg:hover {
  box-shadow: 0 4px 4px rgba(0,0,0,0.4);
}

.left {
  flex: 1;
  margin-right: 10px;
  min-width: 0;
}

.right {
  flex: 10;
  align-self: self-end;
  .scoreData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .scoreValue{
      @include actionItemLabelStyle($dark-gray1, 18px)
    }
    .flag{
      width: 20px;
      margin-bottom: 5px;
    }
  }
}

.difference {
  @include actionItemLabelStyle($gray4, 14px)
}
.differenceUp {
  @include actionItemLabelStyle($green4, 14px)
}
.differenceDown {
  @include actionItemLabelStyle($red4, 14px)
}