$pageWidth: 100vw;
$containerWidth: 98vw;
$navbarWidth: 80vw;
$colorLight: #fff;
$colorDark: #000;
$colorPrimary: #f16e00;
$colorGray: #555;
$colorGrayLight: #333;
$fontFamily: sans-serif;
$containerHeaderFontSize: 34px;
$containerItemWidth: 12vw;
$containerItemHeight: 31vh;
$largeContainerItemWidth: 16vw;
$largeContainerItemHeight: 42vh;
$landscapeContainerItemWidth: 24vw;
$landscapeContainerItemHeight: 26vh;
$heroCarouselItemWidth: 32vw;
$heroCarouselItemHeight: 35vh;
$gridItemWidth: 15.5vw;
$gridItemHeight: 40vh;
$landscapeGridItemWidth: 23.5vw;
$smallGridItemWidth: 14.3vw;
$smallGridItemHeight: 35vh;
$smallLandscapeGridItemWidth: 22.5vw;
$smallLandscapeGridItemHeight: 23vh;
$chartItemWidth: 13vw;
$chartItemHeight: 30vh;
$chartSpotItemWidth: 9vw;
$chartSpotItemHeight: 24vh;
