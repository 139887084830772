@import './variables.scss';

.container {
  width: $pageWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 100px auto 0;
  &.first {
    margin-top: 100px;
  }
  .sectionHeader, .saleHeader {
    position: relative;
    margin: 100px auto 0;
    h3 {
      color: $fontColor;
      margin: 0 0 25px 15px;
    }
    &.saleHeader {
      h3 {
        color: $saleColor;
        margin: 0 0 25px 15px;
      }
    }
  }
  .spotWrapper{ 
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
  }
  .gridItem, .landscapeGridItem {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    font-size: 21px;
    float: left;
    margin: 0 5px 25px 0;
  }
  
  .gridItem {
    div {
      width: $containerItemWidth;
      height: $containerItemHeight;
      margin-bottom: 72px;
    }
  }
  
  .landscapeGridItem {
    div {
      width: $landscapeContainerItemWidth;
      height: $landscapeContainerItemHeight;
      margin-bottom: 72px;
    }
  }

  .gridItem {
    div {
      @media (max-width: 2000px) {
        width: 13.5vw;
        height: 35vh;
      }
    }
  }
}

