@import './variables.scss';
@import './mixins';

.disabledArrow{
  display: none !important;
}

.arrowWrapperRight, .arrowWrapperLeft {
  position: absolute;
  top: 35%;
  width: 50px;
  height: 40px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &.arrowWrapperLeft {
    left: 0px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), transparent);
  }

  &.arrowWrapperRight {
    right: 0px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.7), transparent);
  }
  .rightArrow, .leftArrow {
    border: solid $colorLight;
    border-width: 0 3px 3px 0;
    display: block;
    cursor: pointer;
    width: 15px;
    height: 15px;

    &.rightArrow {
      @include arrowRotation(-45deg);
    }
    &.leftArrow {
      @include arrowRotation(135deg);
    }
  }
}