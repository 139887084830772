.item {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.titleContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABgCAYAAADcvRh2AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABV0RVh0Q3JlYXRpb24gVGltZQAzLzEyLzEzP/y5FwAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAABHSURBVBjTpZDRCgAgCANvC/r/T+5NSDSKXmTouOEApgEZsIERysXu1teOd0sKH/uBUBdkFwAdgxJUz5+nDO9kffSi7hlFLyx6jQGLlHpJqgAAAABJRU5ErkJggg==') left bottom repeat-x;
  background-size: 100% 100%;
}

.title {
  padding: 0 10px 10px 10px;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 2;
  width: 100%;
  color: white;
  text-shadow: 1px -1px 0 rgba(0,0,0,.35);
  line-height: 1.2;
}

.titleInfo {
  width: 100%;
  height: 25% !important;
  padding: 10px;
  background-color: #1b2530;
  text-overflow: ellipsis;
}

.titleInfo h6 {
  font-size: 15px;
  color: white;
}

.titleInfo p {
  padding-right: 5px;
  float: left;
  font-size: 13px;
  color: #b1b1b1;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
