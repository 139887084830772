$pageWidth: 100vw;
$containerWidth: 96vw;
$containerItemPadding: 15px;
$heroCarouselHeight: 70vh;
$containerHeaderFontSize: 20px;
$gridItemWidth: 18vw;
$containerItemWidth: 18.5vw;
$containerItemHeight: 20vh;
$itemBorderRadius: 10px;
$landscapeContainerItemWidth: 30.5vw;
$landscapeContainerItemHeight: 34vh;
$largeLandscapeContainerItemWidth: 47vw;
$largeLandscapeContainerItemHeight: 46vh;
$portraitContainerItemWidth: 18.5vw;
$portraitContainerItemHeight: 50vh;
$detailItemWidth: 50vw;
$detailItemHeight: 55vh;
$dropdownWidth: 180px;
$colorLight: #fff;
$colorLightLight: #f0f0f04d;
$colorLightDark: #848585;
$colorDark: #1b1b24;
$colorDarkLight: #252433;
$colorPrimary: #d80c50;
$colorShadow: rgb(128 128 128 / 75%);
$fontFamily: sans-serif;
$portraitItemWidth: 12vw;
$portraitItemHeight: 20vw;
$collectionXlFirstHeight: 26vh;
$collectionXlSecondHeight: 35vh;
$collectionSmFirstHeight: 54vh;
$collectionSmSecondHeight: 35vh;
$symmetricLgMosaicItemHeight: 76vh;
$symmetricSmMosaicItemHeight: 24vh;
