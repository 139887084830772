$containerItemWidth: 196px;
$containerItemHeight: 295px;

$containerItemPadding: 0px;

$sectionHeadingFontSize: 24px;
$gridHeading: 2rem;

$gridItemWidth: 12vw;
$gridItemHeight: 30vh;

$gridItemHoverWidth: 12vw;
$gridItemHoverHeight: 30vh;
