@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.platformTitle {
  float: left;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left-style: solid;
  border-width: 1px;
  border-color: $gray4;
}

.inner {
  position: relative;
  box-shadow: 0 0 0 1px rgba(16,22,26,.1);
  background-color: $light-gray5;
}

.tvPoster {
  width: $tvPosterWidth;
  height: $tvPosterHeight;
}

.moviePoster {
  width: $moviePosterWidth;
  height: $moviePosterHeight;
}

.posterImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
}

.loaded {
  opacity: 1;
  transition: opacity 0.4s;
}

.badge {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.approved {
  background: $pt-intent-success;
  color: $white;
}

.error {
  background: $pt-intent-warning;
}

.unavailable {
  background: $pt-intent-danger;
}

.fade {
  opacity: 0.5;
}

.fade:hover {
  opacity: 1;
}
