.root {
  position: fixed;
  top: 151px;
  background-color: rgba(24,24,24,.9);
  border-bottom: 1px solid #ff7300;
  color: #f2f3f3;
  font-weight: 200;
  -webkit-transition: padding .5s ease;
  -moz-transition: padding .5s ease;
  -o-transition: padding .5s ease;
  transition: padding .5s ease;
  z-index: 2;
  width: 100%;
  max-width: 1600px;
  min-width: 320px;
  height: 79px;
  line-height: 70px;
  margin: auto;
  padding: 0;
}

.logo {
  margin: 0;
  margin-left: 20px;
  display: flex;
  align-items: center;
  max-width: 200px;
  height: 100%;
  float: left;
}

.logo img {
  width: 100%;
}

.mediaNav {
  position: relative;
  text-transform: uppercase;
  font-size: 18px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listItem {
  padding-right: 3.8vw;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.listItemNav {
  font-size: .8em;
  font-weight: 400;
}

.listItemNav:hover {
  color: #f1c400;
  cursor: pointer;
  text-decoration: none;
}

.dropdownItem {
  float: none;
  height: 39px;
  line-height: 39px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.listItem a {
  color: white;
  float: none;
  height: 39px;
  line-height: 39px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.listItem a:hover {
  color: #f1c400;
  cursor: pointer;
  text-decoration: none;
}

.dropdown {
  background-color: #00358e;;
  border-bottom: 1px solid #00358e;;
  border-left: 1px solid #00358e;;
  border-right: 1px solid #00358e;;
  color: #f2f3f3;
  width: auto;
  display: block;
  padding: 10px 15px;
  min-width: 175px;
  position: absolute;
  top: -26px;
  left: -100px;
  white-space: nowrap;
  list-style-type: none;
  z-index: 1000;
}

.dropdown > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 50px;
  min-height: 140px;
  width: auto;
  clear: both;
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.dropdown > ul:first-child {
  border: 0;
  padding-left: 0;
}