.page {
  background-color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #666;
  height: 100vh;
  position: relative;
  overflow-y: hidden;
}

.pageSpinner {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transparentBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  opacity: 0.4;
  background: aliceblue;
}

.transparentSpinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infiniteScroll {
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}