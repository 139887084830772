.item {
  position: relative;
  display: block;
  opacity: 1;
  cursor: pointer;
  text-decoration: none;
}

.item:hover{
  text-decoration: none;
}

.shadowWrapper{
  background: radial-gradient(at center center, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%), linear-gradient(45deg, rgb(0, 0, 0), rgba(0, 0, 0, 0) 50%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.seeMoreItem h3 {
  color: #fff;
  font-weight: bold;
  margin: 0 0 10px;
  padding: 0;
  line-height: 1.2em;
  font-size: 24px;
}

.seeMoreItem button{
  margin-top: 20px;
  border-radius: 50px;
  background: transparent;
  border-color: #fff;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
}

.seeMoreItem:hover{
  text-decoration: none;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.rightArrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  margin: 0 10px 0 0;
  cursor: pointer;
  width: 17px;
  height: 17px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 15px;
}

.descriptionWrapper{
  position: relative;
  top: 50%;
  left: 15%;
  width: 372px;
  height: 200px;
  box-sizing: content-box;
}

.descriptionWrapper h1{
  color: white;
  line-height: 55px;
  font-size: 60px;
  font-family: Helvetica, sans-serif;
}

.descriptionWrapper p{
  width: 72%;
  color: white;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  font-family: Helvetica, sans-serif;
}