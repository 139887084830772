.item {
  position: relative;
  display: block;
  opacity: 1;
  cursor: pointer;
}

.item:hover{
  opacity: 0.85;
}

.seeMoreItem {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  color: white;
  text-shadow: 1px -1px 0 rgba(0, 0, 0, .35);
  font-size: 17px;
  text-decoration: none;
  flex-direction: column;
  cursor: pointer;
}

.seeMoreItem:hover{
  text-decoration: none;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.seeMoreWrapper {
  margin-bottom: 15px;
  background: hsla(0,0%,100%,.15);
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 50px;
}

.rightArrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  margin: 0 10px 0 0;
  cursor: pointer;
  width: 17px;
  height: 17px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 15px;
}

.seeMoreLabel{
  font-size: 16px;
  font-weight: 700;
}

.spotWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .spotName {
    color: white;
  }
}