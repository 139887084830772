@import './variables.scss';

@mixin gridStyling() {
  display: grid;
  grid-template-columns: repeat(12, auto);
  grid-gap: 20px;
  .collectionItem {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    div {
      width: 100%;
      border-radius: $itemBorderRadius;
    }
  }
}

.container {
  width: $containerWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 50px auto 0;
  &.first {
    margin-top: 120px;
  }
  .header {
    color: $colorLight;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .xlCollectionGrid{ 
    @include gridStyling();
    .collectionItem:nth-child(1),
    .collectionItem:nth-child(2),
    .collectionItem:nth-child(3),
    .collectionItem:nth-child(4) {
      grid-column: span 3;
      div {
        height: $collectionXlFirstHeight;
      }
    }
    .collectionItem:nth-child(5),
    .collectionItem:nth-child(6),
    .collectionItem:nth-child(7) {
      grid-column: span 4;
      div {
        height: $collectionXlSecondHeight;
      }
    }
  }
  .smCollectionGrid{ 
    @include gridStyling();
    .collectionItem:nth-child(1),
    .collectionItem:nth-child(2){
      grid-column: span 6;
      div {
        height: $collectionSmFirstHeight;
      }
    }
    .collectionItem:nth-child(3),
    .collectionItem:nth-child(4),
    .collectionItem:nth-child(5) {
      grid-column: span 4;
      div {
        height: $collectionSmSecondHeight;
      }
    }
  }
}

