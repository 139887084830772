@import './variables.scss';

.container {
  max-width: $containerWidth;
  position: relative;
  margin: 90px auto;
  padding-bottom: 24px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
  float: left;
  margin: 0 15px 30px 0;
}

.sectionHeader {
  max-width: $containerWidth;
  position: relative;
  margin: 100px auto 0;
}

.sectionHeader h3 {
  color: white !important;
  margin: 0 0 0 15px;
  font-family: Amazon Ember,Amazon Arabic Ember,Arial,sans-serif;
}

.spotWrapper{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.item div {
  width: $containerItemWidth;
  height: $containerItemHeight;
  border-radius: 16px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}
