.container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 56px;
  background-color: #1b2530;
  border-bottom: none;
  overflow-x: hidden;
  font-weight: 400;
  margin-bottom: 18px;
  z-index: 2;
}

.list {
  width: 1500px;
  height: 100%;
  margin: auto;
  padding: 0;
  display: flex;
  align-items: center;
  color: #b1b1b1;
  list-style-type: none;
}

.listItem {
  margin-right: 24px;
  font-size: 17px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
}

.listItem a {
  color: #b1b1b1;
  text-decoration: none;

}

.listItem a:hover {
  color: white;
  text-decoration: none;
}

.selectedItem {
  border-bottom: 1px solid white;
}

.selectedItem a,
.selectedItem a:hover {
  color: white;
  text-decoration: none;
}

.dropdown {
  background-image: linear-gradient(180deg,transparent,#1b2530),linear-gradient(90deg,#0d648c,#2d274f);
  background-size: 100% 100%,100% 100%;
  background-repeat: no-repeat;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 275px;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
}

.dropdown .listItem {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.dropdown .listItem a:hover {
  font-weight: 600;
}

.dropdown .selectedItem {
  border: none;
}

.dropdown .selectedItem a {
  color: #b1b1b1;
}

.dropdownIcon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAQAAACR313BAAAAaElEQVR4AWP4jxfSXjqXrygzVwYhmClYmJUlAZcuqiz+X/w4VxkimS1cdKH4f9EcuHShbvEHiAK45K9CNyS7880hCvKMIZLFATC7URX8hEkipBEKvgMV/IVKYnqsMKL4ZVElHUMNLwQAZCvi2TxdGw0AAAAASUVORK5CYII=);
  background-position-y: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 15px;
  vertical-align: top;
  width: 15px;
  margin: 3px 3px 0 3px;
}