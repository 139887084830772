@import "@blueprintjs/core/lib/scss/variables";

.platfomrAndTerritoryList {
  width: fit-content;
  max-height: 500px;
  overflow-x: auto;
  overflow-y: scroll;
  padding: 10px 15px !important;
  &::-webkit-scrollbar {
    display: none;
  }
  .selectByEntity {
    .tabsList {
      column-gap: 10px;
    }
    .tabStyle {
      background-color: transparent;
      border-bottom: 1px solid $violet1;
      &.active {
        color: $blue2;
      }
    }
  }
  .singleSelectWrapper {
    .listHeader {
      margin-bottom: 15px;
      font-weight: 600;
    }
    .radioInput {
      margin-bottom: 15px;
    }
    .childWrapper {
      margin-bottom: 15px;
      padding-left: 20px;
      border-bottom: 1px solid $light-gray3;
    }
  }
  .multiSelectWrapper {
    border-bottom: 1px solid $light-gray3;
    margin-bottom: 15px;
  }
  .header {
    margin: 0 0 20px 0px;
  }
  .checkboxWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 13px;
    z-index: 222;
    cursor: pointer;
    input {
      cursor: pointer;
    }
  }
  .bulkSelectors {
    margin-bottom: 15px;
    font-size: 1em;
    margin-left: 0;

    a {
      font-size: 13px;
      font-weight: 400;
    }
  }
  .collapseWrapper {
    .collapseHeader {
      margin: 0 0 20px 0px;
      cursor: pointer;
      .icon {
        float: right;
      }
    }
    .collapseBody{
      margin-left: 0px;
    }
  }
}

.platformList {
  &.singleTab {
    .collapseHeader, .collapseBody {
      margin-left: 5px;
    }
  }
}

.bulkSelectorContainer {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  
  .bulkSelectors {
    padding: 10px 4px;
    margin: 0;
  }
}
