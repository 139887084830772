.root {
  color: white;
  font-weight: normal;

  max-width: 1260px;

  height: 32px;
  line-height: 32px;
  margin-bottom: 16px;
}
