@import './variables.scss';
@import './mixins';

.container {
  width: $containerWidth;
  margin: 50px auto;
  position: relative;
  padding-bottom: 24px;
  border-radius: 10px;
  top: 7px;
  display: flex;
  flex-direction: row;
  .linkExternalWrapper {
    @include linkExternalSize($linkExternalWidth, $linkExternalWithTextHeight);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .linkExternalDetails {
    @include linkExternalSize($linkExternalDetailWidth, $linkExternalWithTextHeight);
    background-color: $colorDarkLight;
    margin-left: 0.5vw;
    padding: 3%;
    .spotName {
      @include fontStyle($colorPrimary, 18px);
    }
    .spotHeader {
      @include fontStyle($colorLight, 30px);
    }
    .spotDescription {
      color: $colorGray;
      font-size: 16px;
    }
    .spotLink {
      position: absolute;
      bottom: 10vh;
      color: $colorLight;
      background-color: $colorGrayLight;
      padding: 10px 15px;
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
