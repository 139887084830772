@import "../variables.scss";
@import "@blueprintjs/core/lib/scss/variables";

.platformHeader {
  margin-left: $rowHeaderWidth;
}

.platformItem {
  float: left;
  height: 100px;
  text-align: center;
  display: inline;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-left-style: solid;
  border-image: linear-gradient(to top, #A7B6C2, rgba(0, 0, 0, 0)) 1 100%;
  position: relative;
}

.gridContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.platformItemGhostRow  {
  width: $gridCellWidth;
  float: left;
  height: 100px;
  text-align: center;
  display: inline;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-left-style: solid;
  border-image: linear-gradient(to top, #A7B6C2, rgba(0, 0, 0, 0)) 1 100%;
  position: relative;
}

.platformItemTable {
  height: 25px;
  width: 100%;
  height: 25px;
  border-collapse: collapse; border-spacing: 0;
}

.platformItemTable tr {
  justify-content: center;
  display: inline-flex;
  width: 100%;
  padding: 0px;
}

.platformItemTable td {
  padding: 0px;
  height: 25px;
  width: 100%;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-color: #8A9BA8;
  border-width: 2px;
  text-transform: uppercase;
  color: $dark-gray1;
  font-weight: 600;
  font-size: 11px;
  border-right: 1px solid #EBF1F5;
}

.avodColumn {
  width: $avodCellWidth !important;
}

.svodColumn {
  width: $svodCellWidth !important;
}

.tvodColumn {
  width: $tvodCellWidth !important;
}

.halfTvodColumn {
  width: 75px !important;
}

.tvodStyle {
  display: inline-flex;
  width: 100%;
}

.halfWidth {
  width: 50%;
  height: 24px;
}

.fullWidth{
  width: 100%;
  height: 24px;
}

.withLeftBorder {
  width: 50%;
  height: 24px;
  border-left: 1px solid #EBF1F5;
}

.platformItem h4 {
  color: $dark-gray1;
  margin: 0;
  font-size: 16px;
}

.seasonPlatformItem {
  width: $seasonGridCellWidth;
}

.withFlag {
  border-image: none;
  border-color: #A7B6C2;
}

.platformLogo {
  width: 50px;
  height: 40px;
  margin-top: 35px;
  background-size: contain, cover;
  background-repeat: no-repeat;
  background-position: 50%, 50%;
}

.headerGhostRow {
  float: left;
  height: 100px;
  width: $gridCellWidth;
  text-align: center;
  display: inline;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-left-style: solid;
  border-image: linear-gradient(to top, #A7B6C2, rgba(0, 0, 0, 0)) 1 100%;
  position: relative;
}
