@import './variables.scss';

.root {
  padding: 20px 0 20px 20px;
}

.header {
  min-height: 37px;
  padding: 5px;
  position: relative;
}

.sectionHeader {
  display: flex;
  min-height: 37px;
  margin: 20px 0;
  position: relative;
}

.sectionHeader .seeMoreLink {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-weight: 800;
}

.spotList {
  height: 340px;
  overflow: hidden;
}

.hasBackground {
  margin: 17px 15px 21px 0;
}

.showAll {
  height: auto !important;
  max-height: none;
}

.spotItem {
  display: inline-block;
  position: relative;
  height: 330px;
  width: 160px;
}

.root.xboxRoot.xboxBrowsePage {
  float: right;
  width: 75%;
}

.xboxRoot .spotItem {
  width: $xboxItemWidth;
  height: $xboxItemHeight;
  margin-right: $xboxItemMargin;
  margin-bottom: $xboxItemMargin;
  background-color: $xboxItemBackroundColor;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.xboxRoot.xboxFeatured .spotList {
  height: 280px;
  padding-left: 30%;
  padding-top: 20px;
  overflow: hidden;
  white-space: pre;
}

.xboxRoot.xboxFeatured .spotItem {
  margin-right: 25px;
  width: 135px;
  height: 220px;
  background: transparent;
  box-shadow: none;
}

.xboxRoot.xboxFeatured .sectionHeader .heading {
  position: absolute;
  top: 100px;
  left: 50px;
}

.xboxPagination {
  display: flex;
  padding: 25px;
  justify-content: flex-end;
}

.xboxPagination a {
  padding: 10px;
}

.fandangoRoot .spotList {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
}

.fandangoRoot .spotItem {
  flex-basis: $fandangoItemWidth;
  max-width: $fandangoItemWidth;
  height: $fandangoItemHeight;
  padding-right: $fandangoItemPadding;
  padding-bottom: $fandangoItemPadding;
}

.fandangoRoot .spotItem > div {
  border-radius: 5px;
}

.fandangoRoot .sectionHeader {
  position: relative;
  margin: 10px 0;
}

.fandangoRoot .sectionHeader .heading {
  display: inline-block;
  width: 69%;
  margin: 0;
  padding: 0;
  text-align: left;
}

.fandangoRoot .sectionHeader .heading h3 {
  color: #f2f3f3;
  margin: 0;
  font-size: 17px;
  font-weight: 800;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.fandangoRoot .sectionHeader .seeMoreLink {
  display: inline-block;
  width: 31%;
  margin: 0;
  padding: 0;
  text-align: right;
  padding-right: 20px;
}

.fandangoRoot .sectionHeader .seeMoreLink a {
  color: #3478c1;
  outline: 0;
  font-size: 15px;
  line-height: 1.5;
}

.tubiRoot .spotList {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding-left: 50px;
  padding-right: 50px;
}

.tubiRoot .spotItem {
  flex-basis: $tubiItemWidth;
  max-width: $tubiItemWidth;
  height: $tubiItemHeight;
  padding-right: $tubiItemPadding;
  padding-bottom: $tubiItemPadding;
  color: #ffffff;
}

.tubiRoot .spotItem > div {
  border-radius: 5px;
}

.tubiRoot .sectionHeader {
  position: relative;
  margin: 10px 0;
  padding-top: 60px;
  padding-left: 50px;
}

.tubiRoot .sectionHeader .heading {
  display: inline-block;
  width: 69%;
  margin: 0;
  padding: 0;
  text-align: left;
}

.tubiRoot .sectionHeader .heading h3 {
  color: #ffffff;
  margin: 0;
  font-size: 60px;
  font-weight: 800;
  padding: 40px;
  padding-left: 0px;
  padding-top: 0px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tubiRoot .sectionHeader .seeMoreLink {
  display: inline-block;
  width: 31%;
  margin: 0;
  padding: 0;
  text-align: right;
  padding-right: 20px;
}

.tubiRoot .sectionHeader .seeMoreLink a {
  color: #ffffff;
  outline: 0;
  font-size: 15px;
  line-height: 1.5;
}