@import './variables.scss';

.linkExternalContainer {
  width: $containerWidth;
  margin: 50px auto;
  padding-bottom: 24px;
  border-radius: 10px;
  .backgroundImage{
    width: $backgroundImageWidth;
    height: $linkExternalHeight;
    background: center center no-repeat;
    background-size: cover;
  }
}