.root {
  display: inline;
  line-height: 30px;

  top: -35px;
  position: absolute;
  right: 0;

  border: 1px;
  color: #fff;
  font-size: 12px;
}

.root:hover {
  color: white;
}

.withTitle {
  top: 0;
}
