.root {
  position: relative;
}

.img {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
