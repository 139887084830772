@import "@blueprintjs/core/lib/scss/variables";

.root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: $pt-navbar-height + 25;
  bottom: 0;
  left: 0;
  right: 0;
}

.canvas.canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3c3c3c !important;
  background-size: 50px 50px;
  background-image: linear-gradient(0deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent);
}

.left {
  position: relative;
}

.right {
  /* flex: 1; */
  /* max-width: 1200px; */
  /* background-color: #212121; */
  background-color: #eeeeee;
  overflow-y: scroll;
}

.removeTopPadding {
  top: 0px;
}
