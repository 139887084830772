@import "@blueprintjs/core/lib/scss/variables";
@import "../../breakpoints";

.chartWrapper { 
  position: relative;
  width: 100%;
  height: 68vh;
  overflow: hidden;
  @media (min-height: $bp-lg) {
    height: 75vh;
  }
}