@import "@blueprintjs/core/lib/scss/variables";

.header {
  width: 100%;
  height: 75px !important;
  background-color: rgb(103, 0, 103) !important;
  .customNavbarGroup {
    height: 75px !important;
    .heatGridStyle {
      color: $black;
      padding-right: 5px;
    }
  }
  .divider {
    margin: 0 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    height: 45px;
  }
}

.buttonStyle {
  border-radius: 0px !important;
  border: none !important;
  box-shadow: inset 0 0 0 1px rgba(223, 238, 241, 0.2), inset 0 1px 2px rgba(223, 238, 241, 0.2) !important;
  background-color: $white !important;
  border: 1px solid #dfeef1 !important;
  color: $black !important;
  &.active, &:hover {
    background-color: #dfeef1 !important;
  }
  .labelColor {
    color: $black !important;
  }
}

.heading {
  display: block;
  font-size: 16px;
  margin: 8px 0;
  font-weight: bold;
}

.downloadCollapse {
  flex-direction: column;
  margin-top: 46px;
  align-items: flex-end !important;
  max-width: 200px;
}

.preCollapse {
  background: $white;
  position: absolute;
  left: 0;
  transform: translateX(-29%);
}

.inProgress {
  margin: 0 0 2px;
  font-family: 'IBM Plex Sans' !important;
}

.spinner {
  width: 15px !important;
}

.collapseItems {
  padding: 4px;
  font-family: 'IBM Plex Sans' !important;
}

.collapseList {
  max-height: 300px;
  overflow-x: auto;
  background: $white;
}

.downloadLinks {
  color: $black;
  font-family: 'IBM Plex Sans' !important;
}

.downloadLinks:hover {
  color: #670067 !important;
}

.downloadBtn {
  width: 100%;
}

.selectAll {
  font-size: 12px;
}

.merchPtMenu {
  overflow-y: auto;
  max-height: 600px;
  padding: 1%;
  width: 235px;
  div {
    background: $white;
    padding: 10px 5px;
    border-bottom: 1px solid rgb(223, 223, 223);
    span{
      cursor: pointer;
      display: flex;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  ul {
    margin-left: 7px;
    padding: 1%;
    li {
      margin: 7px 0;
    }
  }
  .chevronIcon {
    margin-left: auto;
    margin-right: 5px;
  }
  .platformName {
    margin-left: 10px;
  }
}
