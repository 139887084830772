.item {
  position: relative;
  display: block;
  margin-bottom: 15px;
  border-radius: 5px;
}

.container {
  margin: 20px;
  display: inline-flex;
  justify-content: space-evenly;
}

.position {
  color: #fff;
  height: auto;
  padding: 0 20px;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .00625em;
  line-height: 1.5rem;
  flex: 0 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.multiRowImage {
  width: 160px!important;
  height: 85px!important;
}

.detailImage {
  width: 230px!important;
  height: 130px!important;
}

.multiRowTitle, .detailTitle {
  padding-left: 20px;
  z-index: 2;
  color: #fff;
  line-height: 1.2;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #3e3e3e;
}

.detailTitle{
  width: 500px!important;
}

.multiRowTitle{
  width: 278px!important;
}
