@import "./variables.scss";

.root {
  position: static;
  font-weight: 200;
  z-index: 2;
  width: $containerWidth;
  height: fit-content;
  line-height: 67px;
  display: flex;
  flex-direction: row;
  color: $colorLight;
  background: transparent;
  .menuLinks {
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo img{
      width: auto;
      height: 80px;
      margin: 30px 0 0 10px;
    }
    .listItem {
      text-decoration: none;
      font-size: 0.9rem;
      font-weight: 700;
      color: $colorLight;
      font-family: $fontFamily;
      span {
        padding: 3px 20px;
      }
      &:hover{
        text-decoration: none;
        color: $colorPrimary;
      }
    }
    .activeNav {
      color: $colorPrimary;
    }
  }
}
