$showcaseItemWidth: 300px;
$showcaselItemHeight: 175px;

$verticalShowcaseItemWidth: 12.8%;
$verticalShowcaseItemHeight: 455px;

$sliderContainerWidth: 930px;

$verticalShowcaseItemImageContainerHeight: 375px;
$navbarButtonWidth:84px;

$navbarButtonTextColor: #333;
$chiliGreenColor: #009688;