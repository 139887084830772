@import './variables.scss';
@import './mixins';

.container {
  width: $containerWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 100px auto 0;
  &.first {
    margin-top: 50px;
  }
  .sectionHeader {
    position: relative;
    margin: 100px auto 0;
    h1 {
      color: $colorLight;
      margin: 0 0 25px 0;
    }
    p {
      font-size: 1.2rem;
    }
  }
  .gridWrapper{ 
    display: grid;
    grid-template-columns: repeat(6, $gridItemWidth);
  }
  .portraitGridWrapper{ 
    display: grid;
    grid-template-columns: repeat(8, $portraitGridItemWidth);
  }
  .gridItem {
    @include spotStyling($gridItemHeight);
  }
  .portraitGridItem {
    @include spotStyling($portraitContainerItemHeight);
  }
}
