@import "./variables.scss";

.page {
  background-color: $colorDark;
  color: $colorLight;
  overflow-y: scroll;
  width: $pageWidth;
  height: 100vh;
  .pageName {
    color: $colorLight;
    margin: 40px 0 0px 40px;
    font-size: 2rem;
  }
}
