@import "./variables.scss";

.root {
  position: absolute;
  color: white;
  right: 0;
  width: $sidePanelWidth;
  top: $carouselItemHeight + $linkSectionHeight + 75px;
  background-color: $itunesBackgroundColor;
}

@media(min-width: $screen-xl) {
  .root {
    width: $sidePanelWidth-xl;
    right: 40px;
  }
}