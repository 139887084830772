@import './variables.scss';

.arrowWrapperRight, .arrowWrapperLeft {
  position: absolute;
  top: 0;
  width: 90px;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &.arrowWrapperLeft {
    left: 0px;
  }

  &.arrowWrapperRight {
    right: 0px;
  }
  .rightArrow, .leftArrow {
    display: block;
    cursor: pointer;
  }
}