.item {
  width: 50%;
  height: 200px;
  padding: 10px;
  display: flex;
}

.item .artwork {
  height: 75px;
  min-width: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.item .info {
  padding: 5px;
  color: white;
  margin-left: 40px;
}

.item .info h5 {
  color: white;
}

.sectionFade {
  opacity: 0.2;
}

.spotHighlight {
  opacity: 1 !important;
}
