@import './variables.scss';
@import '../merchandizing-mixins.scss';
@import './mixins';

.container {
  width: $containerWidth;
  position: relative;
  margin: 80px auto 0;
  &.first {
    margin-top: 50px;
  }

  &.shiftUp {
    margin-top: 0;
    top: -140px;
  }

  @include alignSliderSpots();

  .fade {
    opacity: 0.2;
  }
  
  .highlight {
    opacity: 1 !important;
  }

  .heading {
    padding-bottom: 3px;
    align-items: center;
    min-height: 40px;
    .headerText {
      font-size: $containerHeaderFontSize;
      font-weight: 700;
      margin-right: 12px;
      font-size: 1.6rem;
      color: $colorLight;
      a {
        font-size: 16px;
        font-weight: bold;
        color: $colorPrimary;
        margin-bottom: 8px;
        float: right;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .subtitle {
      color: $colorGray;
      font-size: 16px;
    }
  
  }

  .containerItem, .pageContainerItem, .largeContainerItem, .portraitContainerItem, .largePortraitContainerItem {
    padding-right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
  }

  .containerItem {
    @include widthHeight($containerItemWidth, $containerItemHeight)
  }

  .pageContainerItem {
    @include widthHeight($pageContainerItemWidth, $pageContainerItemHeight)
  }

  .largeContainerItem {
    @include widthHeight($largeContainerItemWidth, $largeContainerItemHeight)
  }

  .portraitContainerItem {
    @include widthHeight($portraitContainerItemWidth, $portraitContainerItemHeight)
  }
  
  .largePortraitContainerItem {
    @include widthHeight($largePortraitContainerItemWidth, $largePortraitContainerItemHeight)
  }

  .xlContainerItem {
    @include widthHeight($xlItemWidth, $xlItemHeight)
  }

  .wideScreenContainerItem {
    @include widthHeight($wideScreenItemWidth, $wideScreenItemHeight)
  }
  
  .chartContainerItem {
    a {
      @include widthHeight($chartItemWidth, $chartItemHeight);
    }
  }
}
