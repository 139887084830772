@import './variables.scss';

.container {
  max-width: 93%;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 25px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: 100% !important;
  height: 375px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}
