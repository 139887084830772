@import './variables.scss';
@import '../merchandizing-mixins.scss';

.pageContainer {
  width: $smContainerWidth;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 100px 0;
  position: relative;
  @include alignSliderSpots();
  .shadow{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
  }
  .item {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    div {
      width: $pageContainerWidth;
      height: $pageContainerHeight;
      border-radius: 25px;
    }
  }
  .slickActive {
    margin-right: 10px;
    font-weight: bold;
    color: $colorLight;
  }
  .slickNormal {
    margin-right: 10px;
    font-weight: bold;
    color: $colorDarkLight;
  }

  @media (max-width: 2000px) {
    width: $xlContainerWidth;
    .item div {
      width: $smPageContainerWidth;
      height: $smPageContainerHeight;
    }
  }
}