@import "./variables.scss";

.menu {
  z-index: 8;
  background: #242322;
  width: 250px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: -webkit-sticky;
  position: absolute;
  top: 65px;
  left: 0;
}

.dropdownMenu {
  display: flex;
  overflow-y: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 !important;
  width: 250px;
}

.dropdownMenu .header{
  margin: 25px 0 10px 20px;
  color: #636265;
}

.dropdownMenu ul {
  list-style-type: none;
  margin: 20px 0;
  padding: 0;
}

.dropdownMenu ul li {
  font-size: 16px;
  width: 100%;
  border: none;
  padding: 5% 0;
}

.dropdownMenu ul li:hover {
  cursor: pointer;
  background: #40433a;
}

.dropdownMenu ul li:hover > a {
  text-decoration: none;
  color: #ffffff;
}

.dropdownMenu ul li a {
  font-weight: 600;
  color: #ffffff;
  font-size: 16px;
  justify-content: flex-start;
  margin-left: 20px;
}

.dropdownMenu .navbarSection{
  margin-top: 20px;
  color: gray;
}

.dropdownMenu .navbarSection:hover {
  background: transparent;
  cursor: default;
}

.dropdownMenu .navbarSection span{
  margin-left: 20px;
}
