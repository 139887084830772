@import '../merchandizing-mixins.scss';

.page {
  background-color: #000;
  width: 100%;
  height: 100vh;
  position: relative;
  font-family: Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  overflow-y: scroll;
  @include alignSliderSpots();
}

.transparentBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  opacity: 0.4;
  background: aliceblue;
}

.transparentSpinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
