.item {
  position: relative;
  display: block;
  border-radius: 16px;
}

.item:hover {
  text-decoration: none;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}


