@import './variables.scss';

.container {
  max-width: 1500px;
  height: 250px;
  margin-top: 10px;
  position: relative;
  margin: 16px auto;
  padding: 0 48px;
}

.item {
  width: 25%;
  height: 100%;
  padding-right: $seasonCarouselItemPadding;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
  float: left;
}

.sectionHeader {
  max-width: 1500px;
  position: relative;
  padding: 0 48px;
  margin: 20px auto !important;
}

.sectionHeader h3 {
  color: white !important;
}

.item div {
  width: 100%;
  height: 75%;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}