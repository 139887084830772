.root {
  position: fixed;
  background-color: #000;
  color: #eeeeee;
  font-weight: 200;
  z-index: 2;
  width: 100%;
  min-width: 320px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  padding: 0;
}

.logo {
  margin: 5px 0 5px 20px;
  width: 250px;
  height: 100%;
  float: left;
}

.logo img {
  width: 100%;
}

.mediaNav {
  position: relative;
  left: 30%;
  font-size: 18px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  float: left;
  height: 79px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  padding: 0 10px;
}

.listItemNav {
  font-size: 1.1em;
  font-weight: 400;
  padding: 0 10px;
  display: block;
  height: fit-content;
}

.listItemNav:hover {
  cursor: pointer;
  text-decoration: none;
  background: #165e93;
  display: block;
  height: fit-content;
}

.dropdownItem {
  float: none;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  font-size: 14px;
  vertical-align: middle;
}

.listItem a {
  color: #eeeeee;
  float: none;
  font-size: 16px;
}

.listItem a:hover {
  color: #eeeeee;
  cursor: pointer;
  text-decoration: none;
}

.dropdown {
  color: white;
  display: block;
  position: absolute;
  top: -26px;
  left: 10px;
  white-space: nowrap;
  list-style-type: none;
  z-index: 1000;
}

.dropdown > ul {
  background-color: rgba(0, 0, 0, .9);
  border: 1px solid rgba(81, 90, 105, .95);
  list-style: none;
  margin: 0;
  padding: 0 10px;
  max-height: calc(100vh - 69px);
  width: auto;
  clear: both;
  content: '';
  vertical-align: top;
}

.dropdown  ul li:first-child {
  border-bottom: 1px solid rgba(81, 90, 105, .95);
}

.dropdown ul li:hover {
  background-color: #515A69;
  opacity: 0.88;
}

.firstLetter {
  color: #feb613;
}

.rightArrow {
  border: solid white;
  border-width: 0 1px 1px 0;
  cursor: pointer;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  margin: 10px 10px;
  display: block;
}

.subMenuItem {
  display: flex;
  justify-content: space-between;
}

.contentSpot{
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
}