$pageWidth: 100vw;
$containerWidth: 96vw;
$containerItemPadding: 15px;
$colorDark: #0e0e0e;
$colorDarkLight: #181818;
$colorGrayLight: #242424;
$colorLight: #ffffff;
$colorPrimary: #ec3755;
$colorGray: #bababa;
$fontFamily: sans-serif;
$heroCarouselHeight: 100vh;
$heroCarouselDetailsWidth: 40vw;
$linkExternalWidth: 70vw;
$linkExternalDetailWidth: 30vw;
$subHeadingHeight: 8vh;
$containerHeaderFontSize: 30px;
$containerItemWidth: 15.7vw;
$containerItemHeight: 17vh;
$pageContainerItemWidth: 10.4vw;
$pageContainerItemHeight: 13vh;
$largeContainerItemWidth: 23.7vw;
$largeContainerItemHeight: 26vh;
$largePortraitContainerItemWidth: 13.5vw;
$largePortraitContainerItemHeight: 37vh;
$portraitContainerItemWidth: 10.4vw;
$portraitContainerItemHeight: 31vh;
$gridItemHeight: 17vh;
$gridItemWidth: 16vw;
$smallGridItemWidth: 15.5vw;
$backgroundImageWidth: 100%;
$backgroundImageHeight: 66vh;
$linkExternalHeight: 48vh;
$linkExternalWithTextHeight: 78vh;
$xlItemWidth: 31.7vw;
$xlItemHeight: 32vh;
$wideScreenItemWidth: 23.5vw;
$wideScreenItemHeight: 17vh;
$chartItemWidth: 16.5vw;
$chartItemHeight: 19vh;
$portraitGridItemWidth: 12vw;