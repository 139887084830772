@import './variables.scss';

.container {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 24px;
}

.spotWrapper{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.heading {
  padding-bottom: 3px;
  margin-left: 20px;
}

.heading h2 {
  font-size: $sectionHeadingFontSize;
  font-weight: 700;
  color: white;
}

.item {
  margin-bottom: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  opacity: 1;
}

.item div {
  width: $squareCarouselItemWidth;
  height: $squareCarouselItemHeight;
}

.item:hover {
  opacity: 0.85;
}
