@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.titleAuditRowHeading {
  padding: 10px;
  float: left;
  width: $rowHeaderWidth;
  height: 220px;

  padding-right: 15px;
  padding-bottom: 20px;
  color: $dark-gray2;
  &:hover{
    color: $dark-gray2;
  }
  h3 {
    margin-top: 0;
  }
}

.titleAuditRowHeading:hover {
  text-decoration: none;
}

.tvHeading {
  height: 150px;
}

.movieHeading {
  height: 200px;
}

.titleAuditRow {
  height: 200px;
  clear: both;
  border-top-style: solid;
  border-width: 1px;
  border-color: $gray4;
}

.tvRow {
  height: 170px;
  width: 101%;
}

.movieRow {
  height: 220px;
  width: 101%;
}

.titleType {
  color: $gray1;
  margin-bottom: 5px;
}

.promoted {
  border-radius: 2px;
  background: $pt-intent-success;
  padding: 4px;
  font-weight: bold;
  font-size: 10px;
  color: white;
  display: inline-block;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  max-width: 200px;
}

.tagItem {
  margin-top: 5px;
  margin-right: 3px;
}

.titleAuditRowPosition {
  position: relative;
}
