@import "./variables.scss";

.dropdownWrapper {
  flex: 8;
  font-family: $fontFamily;
  .dropdownItem{
    color: $colorLight;
    padding: 0 20px;
    font-size: 1.3rem;
    font-weight: 700;
  }
  &:hover{
    background-color: $colorLight;
    .dropdownItem {
      text-decoration: none;
      color: $colorDark;
    }
  }
}
.dropdown {
  width: $dropdownWidth;
  height: fit-content;
  background: $colorLight;
  position: relative;
  margin-top: -4px;
  .dropdownList {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .listItem, .listItemViewAll {
      width: 33.3%;
      height: 40px;
      padding: 15px;
      a {
        color: $colorDark;
        font-weight: 700;
        font-size: .8rem;
        &:hover{
          text-decoration: none;
          color: $colorLightDark;
        }
      }
      .activeLink {
        color: $colorPrimary;
      }
    }
    .listItemViewAll {
      width: 100%;
      padding: 2% 0;
      background-color: $colorPrimary;
      a {
        display: block;
        width: 100%;
        height: 100%;
        margin-left: 15px;
      }
    }
  }
}

