.container {
  min-width: 768px;
  height: 60vh;
  margin: 0 0px 100px 0;
  position: relative;
  padding-top: 55px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  min-width: 1290px;
  height: 60vh;
}

.item div{
  min-width: 1290px;
  height: 60vh;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.arrowWrapperRight, .arrowWrapperLeft {
  position: absolute;
  width: 30px;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.arrowWrapperLeft{
  left: 0;
  top: 0;
}

.arrowWrapperRight{
  right: 0;
  top: 0;
}

.container:hover .arrowWrapperRight, .container:hover .arrowWrapperLeft {
  background-color: rgba(0,0,0,.6);
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  cursor: pointer;
  width: 60px;
  height: 60px;
  opacity: 0.8;
  z-index: 5;
}

.rightArrow:hover,
.leftArrow:hover {
  border-left: 0px;
  border-top: 0px;
  opacity: 1;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 10px;
  width: 20px;
  height: 20px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 10px;
  width: 20px;
  height: 20px;
}
