.accordionContainer {
  width: 620px;
  padding-top: 100px;
}

.root {
  font-size: 15px;
  margin-bottom: 5px;
  color: #000;
}

.root details {
  width: 620px;
}

.root details > summary {
  font-size: 20px;
  line-height: 26px;
  padding: 14px 12px 14px 36px;
  width: 15em;
  background-color: #FAFAFA;
  border: none;
  cursor: pointer;
}

.root details > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.root details > ul li {
  font-size: 20px;
  line-height: 26px;
  padding: 14px 12px 14px 36px;
  width: 15em;
  background-color: #FAFAFA;
  border: none;
  cursor: pointer;
}

.root details > ul li a {
  color: #000;
  font-size: 15px;
}