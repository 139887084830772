$pageWidth: 100vw;
$containerWidth: 96vw;
$containerItemPadding: 15px;
$heroCarouselHeight: 70vh;
$containerHeaderFontSize: 20px;
$containerItemWidth: 15.5vw;
$containerItemHeight: 43vh;
$landscapeContainerItemWidth: 23.5vw;
$landscapeContainerItemHeight: 22vh;
$largeLandscapeContainerItemWidth: 31.5vw;
$largeLandscapeContainerItemHeight: 30vh;
$dropdownWidth: 180px;
$colorLight: #fff;
$colorLightLight: #f0f0f04d;
$colorLightDark: #848585;
$colorDark: #1a1a1a;
$colorDarkLight: #333333;
$colorPrimary: #d87b02;
$colorShadow: rgb(128 128 128 / 75%);
$fontFamily: sans-serif;