@import './variables.scss';

.headerImageContainer {
  width: $smContainerWidth;
  margin: 50px auto;
  padding-bottom: 24px;
  border-radius: 10px;
  .backgroundImage{
    width: 100%;
    height: $heroCarouselHeight;
    background: center center no-repeat;
    background-size: cover;
  }
  .detailWrapper{
    width: $innerContainer;
    margin: 50px auto 0;
    .showText {
      margin-left: 10px;
      cursor: pointer;
    }
    .hideText{
      color: $colorPrimary;
      cursor: pointer;
      margin: 50px 0;
    }
  }
  @media (max-width: 2000px) {
    width: $xlContainerWidth;
  }
}