@import './variables.scss';

.container {
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom: none;
  overflow-x: hidden;
  z-index: 2;
  display: flex;
  justify-content: center;
}

.navbarWrapper {
  width: $navbarWidth;
  height: $navbarHeight;
  font-weight: 400;
  border-radius: 5px;
  backdrop-filter: blur(20px);
  background: $navbarBackgroundColor;
}

.logo {
  img {
    width: $logoWidth;
    margin-right: 30px;
    margin-left: 20px;
  }
}

.list {
  height: 100%;
  margin: auto;
  padding: 0;
  display: flex;
  align-items: center;
  color: $grayColor;
  list-style-type: none;
}

.listItem {
  margin-right: 15px;
  padding: 10px;
  font-size: 16px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  border-radius: 5px;
  width: fit-content;
  &:hover{
    background: $lightColor;
    a {
      color: $darkColor1;
      text-decoration: none;
    }
  }
  a {
    color: $lightColor;
    text-decoration: none;
    font-weight: 700;
    display: flex;
  }
  .selectedItem {
    border-bottom: 1px solid $lightColor;
    a,
    a:hover {
      color: $lightColor;
      text-decoration: none;
    }
  }
}

.dropdown {
  backdrop-filter: blur(20px);
  background: $navbarBackgroundColor;
  position: absolute;
  top: 30px;
  left: -70px;
  padding: 3px;
  width: $dropdownWidth;
  max-height: 75vh;
  margin: -10px 0 0 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
}

.dropdown .listItem {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  width: fit-content;
  height: 55px;
  display: flex;
  align-items: center;
}

.dropdown ul {
  padding-left: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown ul::-webkit-scrollbar {
  width: 5px !important;
  background-color: $lightColor;
}

.dropdown .listItem:hover {
  background: $lightColor;
}

.dropdown .selectedItem a {
  color: $darkColor1;
}

.dropdown .listItem:hover a {
  color: $darkColor1;
  font-weight: 700;
}

.dropdown .selectedItem {
  border: none;
}

.dropdownIcon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAQAAACR313BAAAAaElEQVR4AWP4jxfSXjqXrygzVwYhmClYmJUlAZcuqiz+X/w4VxkimS1cdKH4f9EcuHShbvEHiAK45K9CNyS7880hCvKMIZLFATC7URX8hEkipBEKvgMV/IVKYnqsMKL4ZVElHUMNLwQAZCvi2TxdGw0AAAAASUVORK5CYII=);
  background-position-y: center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 20px;
  vertical-align: top;
  width: 20px;
  margin: 3px 3px 0 3px;
}

.listItem:hover .dropdownIcon {
  transform: rotate(180deg);
  color: $lightColor;
}

.dropdownItem {
  margin-left: auto;
}

.alignRight {
  margin-left: auto;
  margin-right: 20px;
}

.iconGrid {
  border-radius: 50%;
}

.blueBtn {
  background: $blueColor2;
  margin-right: 20px;
}