@import './variables.scss';

.container {
  width: $pageWidth;
  position: relative;
  padding-bottom: 24px;
  border-radius: 10px;
  top: 7px;
  margin-top: 60px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

.item div {
  width: 100%;
  height: 100vh;
  background-position: initial !important;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}
