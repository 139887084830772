.page {
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
}

.vuduPage {
  background: #041C2C;
}

.vuduPage.newVuduPage {
  background: #171c20; 
}

.vuduPageName {
  display: none;
  text-align: center;
  margin: 25px 0;
}

.vuduPageName h1{
  color: #fff;
}

.page .vuduPageName:first-child{
  display: block;
}

.pageContainer {
  max-width: calc(1600px + 10%);
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

.tubiPage {
  background: #1b2831;
}

.cineplexPage {
  height: 100vh;
  width: 100%;
  background: #0a0b0d
}

.cineplexPageContainer {
  padding: 0px;
}

.tubiPage .pageContainer {
  padding-top: 80px;
}