@import "./variables.scss";

.root {
  color: white;
}

.header {
  color: white;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 14px;
  padding-left: 8px;
}

.root a:hover {
  color: #ddd;
}

.list {
  list-style: none;
  counter-reset: chart-counter 1;
  padding-left: 18px;
}
