.root {
  justify-content: center;
  margin-top: 30px;
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 300px;
  background-color: rgba(0,53,142,.4);
  background: linear-gradient(to right, rgba(0,53,142,.4) , rgba(86, 0, 117, 0.4));
}

.collection {
  justify-content: center;
  margin-top: 100px;
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.spotItem {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 30px;
  padding-right: 30px;
  justify-items: center;
  display: inline-flex;
  position: relative;
  height: 250px;
  width: 350px;
}
