.item {
  position: relative;
  display: block;
  border-radius: 16px;
}

.item:hover {
  text-decoration: none;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.title {
  display: block;
  position: relative;
  height: fit-content !important;
  margin-bottom: 4px;
  top: 0%;
  max-width: 60%;
  max-height: 200px;
}

.seeMoreItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f171e;
  color: white;
  text-shadow: 1px -1px 0 rgba(0, 0, 0, 0.35);
  font-size: 17px;
}

.coverGradient {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 5, 13, 0.8) 19.65%,
    rgba(0, 5, 13, 0) 49.93%
  );
}

.amazonDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 1%;
  .itemImage {
    display: block;
    position: relative;
    top: 0%;
    max-width: 60%;
    max-height: 200px;
  }
}

.amazonDetails .title,
.amazonDetails .description {
  font-weight: 600;
  color: #fff;
}

.amazonDetails .title {
  font-size: 2.5em;
}

.amazonDetails .description {
  font-size: 1.3em;
  font-weight: 600;
  color: #fff;
}

.liveInfo{
  display: flex;
  align-items: center;
  column-gap: 16px;
  height: min-content !important;
}

.status{
  background-color: #33373d;
  padding: 4px 8px;
  border-radius: 3px;
  color: #fff;
  font-weight: 900;
  font-size: 16px;
}

.time{
  padding: 4px 8px;
  color: #fff;
  font-weight: 900;
  font-size: 16px;
}


.season {
  color: #fff;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600; 
  font-size: 20px;
  mix-blend-mode: hard-light;
  max-width: 40%;

  display: -webkit-box; 
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis; 

  opacity: 0;
  max-height: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, max-height 1s ease, transform 1s ease;
}

.season.visible {
  opacity: 1; 
  max-height: 100px;
  transform: translateY(0);
}

.amazonButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  margin-bottom: 4px;
}

.buttonSubtitle {
  color: #fff;
  background-color: #33373d;
  border-radius: 8px;
  width: fit-content;
  padding: 8px 21px;
  height: 62px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  margin-top: 20px;
}

.amazonDescriptionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  height: fit-content !important;
  align-items: center;
  margin-top: 10px;
}

.amazonButtonWrapper span {
  display: inline-flex;
}

.amazonButtonWrapper .currentRating {
  background-color: #33373d;
  color: #fff;
  border-radius: 3px;
  padding: 3px 7px;
  font-weight: 700;
  font-size: 16px;
  margin-left: 15px;
  height: fit-content;
}
.amazonButtonWrapper .promoMessage {
  color: #37f1a3;
  padding: 3px 7px;
  font-weight: 700;
  font-size: 18px;
  text-align: end;
  margin-bottom: 8px;
}

.amazonButtonWrapper .termsMessage {
  color: #fff;
  padding: 3px 7px;
  font-weight: 700;
  font-size: 18px;
  text-align: end;
}

.amazonButtonWrapper .offerIcon {
  background-color: #33373d;
  color: #fff;
  border-radius: 15px;
  padding: 3px 10px;
  font-weight: 700;
  font-size: 16px;
  margin-right: 5px;
  height: fit-content;
}
.amazonButtonWrapper > .logoUrl {
  max-width: 90px;
  height: 50px;
  margin-bottom: 5px;
  mix-blend-mode: difference; 
}

.contentWrapper {
  display: flex;
  align-items: flex-end;
  padding-right: 70px;
  flex-direction: column;
  row-gap: 4px;
  height: min-content !important;
}

.offerDetails {
  display: flex;
  align-items: center;
  column-gap: 4px;
  height: min-content !important;
}
.infoDetails {
  display: flex;
  align-items: center;
  column-gap: 20px;
  height: min-content !important;
}

.infoButton{
  background-color: #33373d;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.shopIcon {
  margin-right: 5px;
}

.ratingIcon {
  margin-left: 15px;
}

.infoIcon{
  margin-left: 5px;
}

.amazonCollection {
  font-size: 19px;
  font-weight: 600;
  color: #fff;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg,rgba(0,5,13,.8) 32%,rgba(0,5,13,0) 60%);
}