.container {
  margin-top: 15px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 24px;
  height: 350px;
}

.image {
  display: flex;
  max-width: 1300px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px;
  height: 350px;
}

.posterImage {
  justify-content: space-between;
  display: flex;
  height: 350px;
  width: 450px;
  background-size: contain;
}

.slider {
  height: 350px;
  justify-content: space-between;
  width: 70%;
}

.gradientLeft {
  width: 40px;
  height: 350px;
}

.gradientRight {
  width: 110px;
  height: 350px;
}

.heading {
  position: absolute;
  /* margin-left: 50px; */
  padding-top: 15px;
}

.heading h2 {
  font-size: 1.475rem;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-weight: 350;
  color: white;
}

.heading h3 {
  color: white;
}