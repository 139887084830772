@import '../merchandizing-mixins.scss';

.container {
  max-width: 1850px;
  margin: 0 auto;
  @include alignSliderSpots();
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  height: 245px;
}

.item div{
  max-width: 1364px;
  height: 245px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  cursor: pointer;
  width: 17px;
  height: 17px;
  opacity: 0.8;
  z-index: 5;
}

.rightArrow:hover,
.leftArrow:hover {
  opacity: 1;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 25px;
}

.disabledArrow {
  display: none;
}

.slickActive {
  opacity: 0.9;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 13px !important;
  height: 13px !important;
  margin: 0 !important;
  margin-right: 7px !important;
}

.slickNormal {
  opacity: 0.3;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 13px !important;
  height: 13px !important;
  margin: 0 !important;
  margin-right: 7px !important;
}

.banner.container {
  max-width: 1850px;
  padding-left: 52px;
  margin: 0 auto;
}

.bannerItem.item {
  max-width: 100%;
  padding-right: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-flex !important;
  justify-content: right;
  font-size: 21px;
}

.bannerItem.item div {
  max-width: 100%;
  height: 245px;
  display: inline-flex;
  justify-content: flex-end;
}

.backgroundImage {
  height: 245px;
}

.inner {
  margin: 5px 0 15px 0;
}

.backgroundGradientProperties {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.backgroundImagePosition {
  position: absolute;
}

.backgroundColorProperties {
  width: 100%;
  height: 100%;
}

.titleSize {
  width: 100%;
  justify-content: left !important;
  padding-left: 50px;
  align-items: center;
  font-size: 32px;
  max-width: 472px;
  font-family: "Roboto",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.inner {
  margin: 5px 0 15px 0;
}
