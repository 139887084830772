.container {
  min-width: 768px;
  height: 100vh;
  position: relative;
  top: -83px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  min-width: 1290px;
  height: 100vh;
}

.item div{
  min-width: 1290px;
  height: 100vh;
}

.fade {
  opacity: 0.25;
}

.highlight {
  opacity: 1;
}

.arrowWrapperRight, .arrowWrapperLeft {
  position: absolute;
  width: 57px;
  height: 74px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.arrowWrapperLeft{
  left: 10px;
  top: 45%;
}

.arrowWrapperRight{
  right: 10px;
  top: 45%;
}

.rightArrow,
.leftArrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: block;
  cursor: pointer;
  opacity: 0;
}

.rightArrow:hover,
.leftArrow:hover {
  border-left: 0px;
  border-top: 0px;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 15px;
  width: 30px;
  height: 30px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 15px;
  width: 30px;
  height: 30px;
}

.container:hover .arrowWrapperRight, .container:hover .arrowWrapperLeft {
  background-color: rgba(18,35,57,.9);
}

.container:hover .rightArrow, .container:hover .leftArrow {
  opacity: 1;
}

.slickActive {
  border-radius: 50%;
  background: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 8px !important;
  height: 8px !important;
  margin: 5px !important;
  margin-right: 7px !important;
}

.slickNormal {
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 6px !important;
  height: 6px !important;
  margin: 5px !important;
  margin-right: 7px !important;
}
