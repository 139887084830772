.page {
  width: 100%;
  background-color: #181818;
  overflow-y: scroll;
  position: relative;
  height: 100vh;
}

.container {
  padding-top: 70px;
  background-color: #000;
  max-width: 1600px;
  box-shadow: 0 0 25px 10px rgba(0,0,0,.55);
  width: 100%;
  font-weight: 400;
  color: #f2f3f3;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
}
