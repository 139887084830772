@import './variables.scss';

.item {
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.47);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.47);
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.47);
}

.item:hover {
  text-decoration: none;
  color: #ffffff;
}

.item div{
  height: 100%;
  width: 100%;
}

.channelRoundItem {
  border-radius: 50% !important;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.item {
  .appleTvDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 3%;
    width: 450px !important;
    img {
      max-width: 200px;
      margin-bottom: 10px;
    }
    span {
      color: #fff;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .genre{
      font-size: 14px;
    }
    button {
      width: 220px;
      height: 55px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      border: 1px solid #ffffff;
      background: #ffffff;
      color: #000;
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
}