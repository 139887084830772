@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 24px;
  float: left;
  clear: both;
  @include alignSliderSpots();
}

.heading {
  padding-bottom: 3px;
  margin-left: 20px;
}

.heading h2 {
  font-size: $sectionHeadingFontSize;
  font-weight: 700;
  color: white;
}

.heading h2 a{
  float: right;
  margin-right: 20px;
  color: #909090;
  font-size: 12px;
  text-decoration: none;
}

.heading h2 a:hover{
  text-decoration: none;
  color: #fff !important;
}

.heading h3 {
  font-size: 13px!important;
  color: #999;
  font-weight: 700;
}

.item {
  padding-right: $squareCarouselItemPadding;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
  opacity: 0.9;
}

.item div {
  width: $videCarouselItemWidth;
  height: $videCarouselItemHeight;
}

.fade {
  opacity: 0.25;
}

.highlight {
  opacity: 1;
}

.arrowWrapperRight, .arrowWrapperLeft {
  position: absolute;
  width: 30px;
  height: 90%;
  z-index: 1;
  cursor: pointer;
}

.arrowWrapperLeft{
  left: 0;
  top: 10%;
}

.arrowWrapperRight{
  right: 0;
  top: 10%;
}

.container:hover .arrowWrapperRight, .container:hover .arrowWrapperLeft {
  background-color: rgba(0,0,0,.6);
}

.rightArrow,
.leftArrow,
.playArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  opacity: 0.6;
}

.rightArrow::before,
.leftArrow::before, {
  content: '' !important;
}

.rightArrow:hover,
.leftArrow:hover {
  opacity: 1;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 15px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 15px;
}

.playArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 25%;
}

.playWrapper{
  position: relative;
  top: 17vh;
  right: 14vw;
  border-radius: 50px;
  background-color: gray;
  width: 50px;
  height: 50px;
  display: block;
  opacity: 0.6;
}

.playWrapper{
  opacity: 0.85;
}
