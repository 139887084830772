$squareCarouselItemWidth: 155px;
$squareCarouselItemHeight: 232px;
$gridItemWidth: 183px;
$gridItemHeight: 275px;
$collectionItemWidth: 285px;
$collectionItemHeight: 160px;
$quarterDealaItemWidth: 235px;
$quarterDealItemHeight: 356px;
$halfDealaItemWidth: 485px;
$halfDealItemHeight: 276px;
$fullDealaItemWidth: 1200px;
$fullDealItemHeight: 220px;
$sectionHeadingFontSize: 1.8em;
$grape: #491e54;
$plum: #902a7d;
$ruby: #e42045;
$light: #ffffff;