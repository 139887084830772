.root {
  position: fixed;
  top: 150px;
  left: 0px;
  right: 0px;
  background-color: #05327d;
  color: #f2f3f3;
  font-weight: 200;
  -webkit-transition: padding .5s ease;
  -moz-transition: padding .5s ease;
  -o-transition: padding .5s ease;
  transition: padding .5s ease;
  z-index: 2;
  width: 100%;
  min-width: 320px;
  height: 79px;
  line-height: 70px;
  margin: auto;
  padding: 0;
}

.logo {
  margin: 0;
  margin-left: 20px;
  padding-right: 80px;
  display: flex;
  align-items: center;
  max-width: 200px;
  height: 100%;
  float: left;
}

.logo img {
  width: 100%;
}

.mediaNav {
  position: relative;
  text-transform: uppercase;
  font-size: 18px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listItem {
  color: white;
  text-transform: uppercase;
  font-size: .8em;
  font-weight: 400;
  padding-left: 0;
  padding-right: 3.8vw;
  outline: none;
}

.listItem:hover {
  color: #f1c400;
  cursor: pointer;
  text-decoration: none;
}

.listItem a {
  color: #f2f3f3;
  text-decoration: none;
  transition: color .25s ease;
}

.listItem a:hover {
  color: #f1c400;
  cursor: pointer;
  text-decoration: none;
}
