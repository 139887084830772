@import "./variables.scss";

.page {
  width: 100%;
  background-color: $appleTvBackgroundColor;
  overflow-y: scroll;
  height: 100vh;
}

.container {
  max-width: $containerWidth;
  width: 80%;
  margin: 75px auto 0;
}

.container h2 {
  margin: 20px 0;
  color: #f2f3f3;
}

@media (max-width: 2000px) {

  .container {
    max-width: 80%;
    margin-left: 300px
  }
}