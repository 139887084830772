@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: 1260px;
  position: relative;
  margin: 100px auto 0;
  padding-bottom: 24px;
  @include alignSliderSpots();
}

.container + .container {
  margin-top: 50px;
}

.heading {
  border-bottom: 1px solid #fff;
  margin-bottom: 30px;
}

.heading h2 {
  font-size: $sectionHeadingFontSize;
  font-weight: 700;
  color: white;
  padding-bottom: 10px;
}

.heading h2 a{
  float: right;
  font-size: 14px;
  text-decoration: none;
}

.heading h2 a:hover{
  text-decoration: none;
}

.heading h3 {
  font-size: 13px!important;
  color: #999;
  font-weight: 700;
}

.item {
  padding-right: $containerItemPadding;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: $containerItemWidth;
  height: $containerItemHeight;
  transition: all .2s ease-in-out;
}

.item div:hover {
  transform: scale(1.1);
}

.item span {
  float: left;
  width: 200px;
}

.item span h3{
  font-size: 16px;
  margin: 20px 0;
  color: #fff;
  line-height: 1.2rem;
}

.item.pageContainer div{
  border-radius: 20px;
  height: $containerItemWidth;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.arrowWrapperRight, .arrowWrapperLeft {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(0,0,0,.6);
  top: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowWrapperLeft{
  left: 0;
}

.arrowWrapperRight{
  right: 0;
}

.rightArrow,
.leftArrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  cursor: pointer;
  width: 14px;
  height: 14px;
}

.rightArrow::before,
.leftArrow::before, {
  content: '' !important;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 15px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 15px;
}

.seeAllArrow {
  transform: rotate(-45deg);
  border: solid 1px #106ba3;
  border-width: 0 2px 2px 0;
  display: inline-block;
  cursor: pointer;
  width: 9px;
  height: 9px;
}
