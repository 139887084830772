$pageWidth: 100vw;
$containerWidth: 96vw;
$containerItemPadding: 15px;
$heroCarouselHeight: 111vh;
$containerHeaderFontSize: 20px;
$containerItemWidth: 8.7vw;
$containerItemHeight: 24vh;
$gridItemWidth: 10vw;
$gridItemHeight: 28vh;
$portraitContainerItemWidth: 11vw;
$portraitContainerItemHeight: 31vh;
$landscapeContainerItemWidth: 18vw;
$landscapeContainerItemHeight: 23vh;
$collectionItemWidth: 32vw;
$collectionItemHeight: 35vh;
$dropdownWidth: 635px;
$colorLight: #fff;
$colorLightLight: #f0f0f04d;
$colorLightDark: #848585;
$colorDark: #1a1a1a;
$colorDarkLight: #333333;
$colorPrimary: #ffc427;
$fontFamily: sans-serif;