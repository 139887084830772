@mixin arrowBaseStyling($topPosition, $width, $height, $borderColor, $hoverStyle) {
  position: absolute;
  top: $topPosition;
  width: $width;
  height: $height;
  border-radius: 5px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $borderColor;
  &:hover {
    @each $property, $value in $hoverStyle {
      #{$property}: #{$value};
    }
  }
}

@mixin arrowPosition($position, $borderRadius) {
  @each $property, $value in $position {
    #{$property}: #{$value};
  }
  @each $property, $value in $borderRadius {
    #{$property}: #{$value};
  }
}

@mixin arrowStyling($deg, $borderColor, $hoverStyle, $arrowWidth, $arrowHeight, $arrowDensity) {
  .arrow {
    border: solid $borderColor;
    border-width: 0 $arrowDensity $arrowDensity 0;
    display: block;
    cursor: pointer;
    width: $arrowWidth;
    height: $arrowHeight;
    transform: rotate($deg);
    -webkit-transform: rotate($deg);
    &:hover {
      @each $property, $value in $hoverStyle {
        #{$property}: #{$value};
      }
    }
  }
}

@mixin arrow($baseTop, $baseWidth, $baseHeight, $baseBorderColor, $baseHoverStyle, $position, $borderRadius, $deg, $arrowBorderColor, $arrowHoverStyle, $arrowWidth, $arrowHeight, $arrowDensity) {
  @include arrowBaseStyling($baseTop, $baseWidth, $baseHeight, $baseBorderColor, $baseHoverStyle);
  @include arrowPosition($position, $borderRadius);
  @include arrowStyling($deg, $arrowBorderColor, $arrowHoverStyle, $arrowWidth, $arrowHeight, $arrowDensity);
}

@mixin itemStyling {
  padding-right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

@mixin itemSize($width, $height) {
  div {
    width: $width;
    height: $height;
    border-radius: 10px;
    &:hover {
      background-blend-mode: multiply;
    }
  }
}

@mixin slickStyles($bgColor) {
  background: $bgColor;
  color: $bgColor;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  button{
    &::before {
      color: transparent;
    }
  }
}

@mixin labelStyle($bgColor, $color) {
  background: $bgColor;
  color: $color;
  border-radius: 5px;
  padding: 4px;
  margin: 10px;
  width: fit-content;
  font-weight: bold;
}

@mixin gridWrapperStyle($gridColumns, $width, $height) {
  display: grid;
  grid-template-columns: repeat($gridColumns, $width);
  grid-gap: 15px;
  .gridItem {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    div {
      width: 100%;
      height: $height;
      border-radius: 10px;
      &:hover {
        background-blend-mode: multiply;
      }
    }
  }
}