@import "./variables.scss";

.container {
  margin-bottom: 40px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.item {
  width: $carouselItemWidth;
  height: $carouselItemHeight;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

.fade.fade {
  opacity: 0.25
}

.highlight.highlight {
  opacity: 1;
}
