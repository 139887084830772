.root {
  padding: 20px 0;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.infiniteGridItem {
  height: auto;
  width: 50%;
  padding: 0 15px 15px 0;
}
