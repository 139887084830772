@import './variables.scss';
@import './mixins.scss';

.bannerImageContainer {
  width: $containerWidth;
  margin: 50px auto;
  .header {
    h1 {
      color: $colorLight;
    }
    p{
      opacity: .5;
      font-size: 16px;
    }
  }
  @include headerBackgroundStyling($backgroundImageWidth, $backgroundImageHeight)
}

.headerImageContainer {
  width: $containerWidth;
  margin: 6px auto 0px;
  @include headerBackgroundStyling($backgroundImageWidth, $backgroundImageHeight);
  .backgroundShadow {
    width: 100%;
    height: 100%;
    background: linear-gradient(1deg, $colorDark, transparent);
  }
}