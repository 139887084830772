@import "./variables.scss";

.page {
  background-color: $colorLight;
  overflow-y: scroll;
  width: $pageWidth;
  height: 100vh;
  color: $colorLight;
  .pageName {
    margin: 30px 0 30px 11vw;
  }
}
