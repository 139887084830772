@import './variables';
@import '../merchandizing-mixins.scss';

.container {
  width: $containerWidth;
  position: relative;

  margin: 80px auto 0;

  @include alignSliderSpots();

  &.first {
    margin-top: 210px;
  }

  .heading {
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    h1 {
      font-size: $containerHeaderFontSize;
      font-weight: 700;
      margin-right: 12px;
      font-size: 1.6rem;
    }
    &.heading h1 {
      color: $colorDark;
    }
  
    a {
      font-size: 1rem;
      color: $colorDark;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease; 
      &:hover {
        text-decoration: none;
      }
      .rightArrow {
        margin-left: 10px;
        border: solid $colorDark;
        border-width: 0 1px 1px 0;
        display: block;
        cursor: pointer;
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-right: 5px;
      }
    }
    &:hover {
      a {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .containerItem, .landscapeContainerItem, .portraitContainerItem, .collectionItem {
    padding-right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 14px;
    display: block;
  }

  @mixin hoverEffect {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.03);
      transform-origin: top;
    }
  }

  .containerItem {
    div {
      width: $containerItemWidth;
      height: $containerItemHeight;
      @include hoverEffect;
    }
  }

  .landscapeContainerItem {
    div {
      width: $landscapeContainerItemWidth;
      height: $landscapeContainerItemHeight;
      @include hoverEffect;
    }
  }

  .portraitContainerItem {
    div {
      width: $portraitContainerItemWidth;
      height: $portraitContainerItemHeight;
      @include hoverEffect;
    }
  }

  .collectionItem {
    div {
      width: $collectionItemWidth;
      height: $collectionItemHeight;
    }
  }

  .fade.fade {
    opacity: 0.25;
  }

  .highlight.highlight {
    opacity: 1;
  }
}