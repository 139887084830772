@import './variables.scss';

.container {
  width: 100%;
  height: 64vh;
  margin-top: 80px;
  margin-bottom: 30px;
  position: relative;
  background-position: center center;
  background-size: cover;
}
