@import "@blueprintjs/core/lib/scss/variables";

.root {
  position: relative;
  height: 230px;
  max-width: 150px;
  margin: 5px;
  flex: 1;
  flex-basis: 100%;
}

.imgContainer {
  height: 200px;
  overflow: hidden;

  border: 3px solid rgba(0,0,0,0.2);
  border-radius: 5px;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid white;
  position: relative;
}

.img:hover {
  opacity: 1;
}

.selected .imgContainer {
  border: 3px solid $pt-intent-danger !important;
}

img.selected {
  opacity: 1;
}

.link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  opacity: 0.6;
  display: block;
  color: black;
  padding-top: 3px;
}

.link:hover {
  color: black;
  text-decoration: none;
}

.linkInner {
  padding-left: 5px;
}

.selected .link {
  opacity: 1;
}

.root:hover .link {
  opacity: 1;
}

.root:hover .button {
  display: block;
  opacity: 1;
}

.button {
  position: absolute;
  top: 10px;
  left: 10px;

  /* display: none; */
  opacity: 0;
  transition: opacity 0.2s ease;
}
