.dialog.dialog {
  width: 800px !important;
}

.popover {
  display: flex;
  padding: 15px;
  overflow: hidden;
}

.header {
  padding: 20px 20px 10px 20px;
  box-shadow: 0 1px 0 rgba(16,22,26,0.15);
}

.artworkContainer {
  width: 120px;
  height: 1px;
}

.body.body {
  min-height: 100px;
  margin-right: 140px;
}

.primaryActions {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
}

.primaryActions button {
  margin-right: 10px;
}

.secondaryActions {
  flex-grow: 1;
}

.footer {
  display: flex;
}
