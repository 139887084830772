.suggestionsContainer {
  width: 100%;
  max-height: 225px;
  overflow: scroll;

  .suggestion {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 8px;

    &.highlighted {
      background-color: #ddd;
      
      h6 {
        font-weight: bold;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid #ddd;
    }

    &:hover {
      background-color: #ddd;
      
      h6 {
        font-weight: bold;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;

    h6 {
      font-weight: normal;
      margin: 0;
      font-size: 14px;
    }
  }
}

