@import "./variables.scss";

.container {
  max-width: 1260px;
  position: relative;
  padding-bottom: 9px;
}

.scrollWrapper {
  width: 100%;
  overflow: scroll;
  white-space: nowrap;
}

.highlight.highlight {
  filter: brightness(150%);
  opacity: 1;
}

.highlight:hover {
  filter: brightness(100%) !important;
  /* transform: scale(1.1); */
}

.highlight.artwork {
  border: 2px red solid;
}

.item {
  float: left;
  display: block;
  width: $flowcaseItemWidth;
  height: $flowcaseItemHeight;
  margin-right: 20px;
  /* opacity: 0.25; */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.item:hover {
  opacity: 1;
}

.artwork {
  display: block;
  height: $flowcaseItemArtworkHeight;
  width: $flowcaseItemWidth;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.caption {
  width: $flowcaseItemWidth;
  height: 30px;
  color: white;
  overflow: hidden;
  white-space: normal;
  font-size: 12px;
  text-overflow: ellipsis;
  font-weight: 100;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clear {
  clear: both;
}

.tvEpisode {
  width: $tvFlowcaseItemWidth;
  height: $tvFlowcaseItemHeight;
}

.tvEpisode .artwork {
  width: $tvFlowcaseItemWidth;
  height: $tvFlowcaseItemArtworkHeight;
}

.tvEpisode .caption {
  width: $tvFlowcaseItemWidth;
  max-height: 30px;
  height: auto;
}

.tvEpisode .subCaption {
  color: #8e8e92;
  width: $tvFlowcaseItemWidth;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lockerRoomItem {
  height: 245px;
}

.headline {
  color: white;
  font-weight: 200;
  letter-spacing: 0.8px;
}

.sectionFade {
  opacity: 0.2;
}

.spotHighlight {
  opacity: 1 !important;
}
