$rowHeaderWidth: 400px;
$gridCellWidth: 150px;
$gridCellHeight: $gridCellWidth * 0.8;
$gridCellPaddingLeft: 10px;
$gridCellPaddingRight: 10px;
$gridCellTotalBorderWidth: 1px;
$gridCellTotalWidth: $gridCellTotalBorderWidth + $gridCellPaddingRight + $gridCellPaddingLeft + $gridCellWidth;

$moviePosterWidth: 150px;
$moviePosterHeight: 200px;

$tvPosterWidth: 150px;
$tvPosterHeight: 150px;

$toolbarOffset: 75px;

$moviePopoverHeight: 400;
$tvPopoverHeight: 300;
$popoverWidth: 300;
$popoverMinWidth: 100px;
$popoverMaxWidth: 500px;
$popoverMinHeight: 100px;
$popoverMaxHeight: 300px;
$lightBlue: #dfeef1;

:export {
  rowHeaderWidth: $rowHeaderWidth;
  gridCellWidth: $gridCellWidth;
  gridCellHeight: $gridCellHeight;
  gridCellPaddingLeft: $gridCellPaddingLeft;
  gridCellPaddingRight: $gridCellPaddingRight;
  gridCellTotalBorderWidth: $gridCellTotalBorderWidth;
  gridCellTotalWidth: $gridCellTotalWidth;
  moviePosterWidth: $moviePosterWidth;
  tvPosterWidth: $tvPosterWidth;
  tvPosterHeight: $tvPosterHeight;
  toolbarOffset: $toolbarOffset;
  moviePopoverHeight: $moviePopoverHeight;
  tvPopoverHeight: $tvPopoverHeight;
  popoverWidth: $popoverWidth;
  popoverMinWidth: $popoverMinWidth;
  popoverMaxWidth: $popoverMaxWidth;
  popoverMinHeight: $popoverMinHeight;
  popoverMaxHeight: $popoverMaxHeight;
  lightBlue: $lightBlue;
}