@import './variables.scss';

.container {
  width: $containerWidth;
  position: relative;
  margin: 130px auto 0;
  padding-bottom: 24px;
}

.container + .container {
  margin: 60px auto 0;
}

.heading {
  padding-bottom: 3px;
}

.heading h1 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.item {
  margin-right: $brick2ItemMargin;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: 100% !important;
  height: 655px !important;
  border-radius: 16px;
}

@media (max-width: 2000px) {

  .container {
    width: 100%;
  }
}