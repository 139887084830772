@import './variables.scss';
@import './minxins.scss';

.container {
  width: $innerContainer;
  position: relative;
  padding-bottom: 24px;
  margin: 100px auto 0;
  &.first {
    margin-top: 100px;
  }
  .sectionHeader {
    position: relative;
    margin: 100px auto 0;
    h1 {
      .link{
        font-size: $containerHeaderFontSize;
        font-weight: 700;
        color: $colorDark;
        &:hover {
          text-decoration: none;
          color: $colorRed;
        }
      }
    }
  }
  .gridWrapper{
    @include gridWrapperStyle(6, $gridItemWidth, $gridItemHeight);
  }
  .landscapeGridWrapper{
    @include gridWrapperStyle(4, $landscapeContainerItemWidth, $landscapeContainerItemHeight);
  }
}
