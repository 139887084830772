.item {
  position: relative;
  display: block;
  width: 180px;
  height: 230px;
  border-radius: 5px;
}

.titleContainer {
  width: 100%;
  height: 70px !important;
}

.title {
  left: 0;
  z-index: 2;
  width: 100%;
  color: #333;
  line-height: 1.2;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 14px
}

.bannerTitle {
  left: 0;
  z-index: 2;
  width: 100%;
  color: white;
  line-height: 1.2;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 14px
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.detail {
  display: flex;
}

.oldPrice {
  width: 50px!important;
  line-height: 0.8;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 14px!important;
  text-decoration: line-through;
  color: #5f6368;
  font: inherit;
  stop-color: #5f6368;
  margin-right: 5px;;
}

.newPrice {
  width: 40px;
  line-height: 0.8;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 14px!important;
  color: #5f6368;
  font: inherit;
  stop-color: #5f6368;
  margin-right: 5px;;
}

.bannerOldPrice {
  width: 50px!important;
  line-height: 0.8;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 14px!important;
  text-decoration: line-through;
  color: white;
  font: inherit;
  stop-color: white;
  margin-right: 5px;;
}

.bannerNewPrice {
  width: 40px;
  line-height: 0.8;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 14px!important;
  color: white;
  font: inherit;
  stop-color: white;
  margin-right: 5px;;
}

.rating {
  display: flex;
  width: 100px!important;
}

.currentRating {
  width: 20px!important;
  line-height: 0.8;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 14px!important;
  color: #5f6368;
  font: inherit;
  stop-color: #5f6368;
  margin-right: 5px;;
}

.bannerCurrentRating {
  width: 20px!important;
  line-height: 0.8;
  font-family: "Roboto",Arial,sans-serif;
  font-size: 14px!important;
  color: white;
  font: inherit;
  stop-color: white;
  margin-right: 5px;;
}

.starIcon {
  color: #5f6368;
}

.bannerStarIcon {
  color: white;
}
