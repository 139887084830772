@import "./variables.scss";

.root {
  position: absolute;
  top: 75px;
  font-weight: 200;
  z-index: 2;
  width: $containerWidth;
  height: fit-content;
  line-height: 67px;
  display: flex;
  flex-direction: row;
  color: $fontColor;
  background: $backgroundColor;
  .activeNav {
    color: $fontColor;
    border-top: 4px solid red;
  }
  .menuLinks {
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .listItem {
      text-decoration: none;
      margin: 0 15px 0 0;
      font-size: 1.1rem;
      font-weight: 700;
      color: $fontColor;
      font-family: $fontFamily;
      img { 
        width: 130px;
        display: flex;
      }
      span {
        padding: 3px 20px;
      }
      &:hover{
        text-decoration: none;
        color: $fontColor;
        span {
          background: $shadowColor;
          border-radius: 30px;
        }
      }
    }
  }
}
