@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: $containerWidth;
  position: relative;
  margin: 130px auto 0;
  padding-bottom: 24px;
  @include alignSliderSpots();
}

.container + .container {
  margin: 60px auto 0;
}

.heading {
  padding-bottom: 3px;
}

.heading h1 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  span {
    margin-right: 10px;
  }
}

.heading a {
  color: #ffffff;
  transition: color .2s ease-in-out;
  font-size: 16px;
  font-weight: 600;
}

.item {
  margin-right: $swooshItemMargin;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: $swooshItemWidth;
  height: $swooshItemHeight;
  border-radius: 16px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  opacity: 0;
}

.rightArrow::before,
.leftArrow::before, {
  content: '' !important;
}

.container:hover .rightArrow,
.container:hover .leftArrow {
  opacity: 1;
}

.disabledArrow {
  display: none;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -25px;
}

.seeMoreArrow {
  border: solid white;
  border-width: 0 1px 1px 0;
  display: inline-block;
  margin: -2px 10px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

div.chartWrapper {
  color: $appleTvGreyColor;
  display: flex;
  flex-direction: row;
  margin-top: 7px;
  width: 100%;
  height: fit-content;
  div:first-of-type {
    height: fit-content;
    flex: 1;
    font-size: 40px;
    font-weight: bold;
    margin-right: 10px;
  }
  div:nth-of-type(2) {
    height: fit-content;
    flex: 9;
    padding-top: 3px;
    p {
      font-size: 15px;
      margin-bottom: 0;
    }
    span:nth-of-type(1){
      font-size: 12px;
    }
    span:nth-of-type(2){
      font-size: 12px;
    }
  }
}

div.notesLockupDetailWrapper {
  color: $appleTvGreyColor;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  width: 100%;
  height: fit-content;
  span {
    text-transform: uppercase;
    font-size: 11px;
  }
  p {
    font-size: 15px;
  }
}

@media (max-width: 2000px) {

  .container {
    width: 100%;
  }

  .item div {
    width: 270px;
  }
}