@import './variables.scss';

.detailContainer {
  width: $containerWidth;
  position: relative;

  margin: 80px auto 0;
  display: flex;
  flex-direction: row;
  .detailItem{ 
    div {
      width: $detailItemWidth;
      height: $detailItemHeight;
    }
  }
  .details {
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    h1{
      color: $colorLight;
      margin-bottom: 30px;
      font-weight: bold;
      font-size: 3rem;
    }
    span{
      margin-bottom: 15px;
    }
    img{
      width: 30px;
    }
    p{
      font-size: 1rem;
    }
    .variant {
      border: 1px solid $colorLight;
      padding: 2px;
    }
  }
}