@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: 100%;
  height: $spotlightItemHeight;
  @include alignSliderSpots();
}

.container .spotLightItem div {
  height: $spotlightItemHeight;
  width: $spotlightItemWidth;
  border-radius: 16px;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  opacity: 0.6;
}

.rightArrow:hover,
.leftArrow:hover {
  opacity: 1;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -15px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -15px;
}

.disabledArrow {
  display: none;
}
