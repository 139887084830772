.header {
  width: 100%;
  height: 342px;
  background-color: black;
  position: relative;
}

.rootHeader {
  height: 90%;
}

.header h1 {
  width: 100%;
  position: absolute;
  font-size: 35px;
  font-weight: 800;
  color: #ffffff;
  margin: 0 !important;
  text-transform: uppercase;
  top: 45%;
  z-index: 5;
  text-align: center;
}

.fade {
  opacity: 0.2 !important;
}

.highlight {
  opacity: 1 !important;
}
