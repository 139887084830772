@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.header {
  width: 100%;
  height: 75px !important;
  background-color: rgb(103, 0, 103) !important;
  position: fixed !important;
  top: 75px;
  
  .toolbarGroup {
    height: 75px !important;
    
    .tagWrapper {
      width: 400px;
      
      :global .bp5-tag-input-values {
        max-height: 50px;
        overflow-y: auto;
        overflow-x: unset;
        
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $lightBlue;
          border-radius: 6px;
        }

        .tag {
          background-color: $lightBlue;
        }
      }
    }
  }
  
  .divider {
    margin: 0 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    height: 45px;
  }
}

:global .bp5-heading {
  color: $blue2 !important;
}

.metricMenu {
  li {
    padding: 5px 2px;
    cursor: pointer;
    
    &:hover {
      background-color: $lightBlue;
    }
  }
}

.btnDefault, .infoButton {
  box-shadow: inset 0 0 0 1px rgba(223, 238, 241, 0.2), inset 0 1px 2px rgba(223, 238, 241, 0.2) !important;
  background-color: $white !important;
  border: 1px solid $lightBlue !important;
  min-width: 150px !important;
  &.active, &:hover {
    background-color: $lightBlue !important;
  }
  .caretIcon {
    float: right;
  }
}

.menuBtn {
  margin-bottom: 5px;
  &:hover {
    background-color: $lightBlue !important;
  }
}

.infoButton {
  min-width: fit-content !important;
  span {
    color: $gray2;
    align-self: end;
  }
}
