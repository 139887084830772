.root {
  margin-bottom: 40px;
  position: absolute;
  height: 260px;
  left: 0;
  right: 0;
  top: 0;

  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain;
  width: 1600px;
  margin: auto;
}
