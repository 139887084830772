@import './variables.scss';

.container {
  max-width: $containerWidth;
  margin: 140px auto 0;
  position: relative;
}

.heading h1{
  color: #fff;
}