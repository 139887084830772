.root {
  max-width: 1260px;
  border: 1px solid;
  border-radius: 4px;
  padding: 20px;
  margin-top: 10px;
  border-color: rgba(255,255,255,0.15);
}

.header {
  color: grey;
  font-weight: bold;
  font-size: 25px;
}

.container {
  display: flex;
  flex-wrap: wrap;
}