@import "./variables.scss";

.root {
  color: white;
}

.header {
  color: white;
}

.text {
  color: #ddd;
  font-size: 12px;
  padding-bottom: 10px;
  counter-increment: chart-counter;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 195px;
  display: block;
}

.text:hover {
  color: #ddd;
}

.item {
  clear: both;
  position: relative;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
}

.sectionFade {
  opacity: 0.2;
}

.spotHighlight {
  opacity: 1 !important;
}
