@import '../merchandizing-mixins.scss';

.container {
  width: 95%;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 75px;
  @include alignSliderSpots();
}

.containerWithOutImage {
  padding-top: 75px;
}

.heading {
  padding: 30px 0px 30px 10px;
}

.heading h2 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 500;
  color: white;
  span{
    font-size: 2rem;
  }
}

.heading h2:hover {
  color: #f1c400;
  cursor: pointer;
  text-decoration: none;
}

.heading a {
  text-decoration: none !important;
}

.heading h3 {
  font-size: 13px!important;
  color: #999;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: 200px;
  height: 300px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  opacity: 0.6;
}

.rightArrow::before,
.leftArrow::before, {
  content: '' !important;
}

.rightArrow:hover,
.leftArrow:hover {
  border: #f1c400 solid;
  border-left: 0px;
  border-top: 0px;
  opacity: 1;
}

.disabledArrow {
  display: none;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -25px;
}
