$superHeroItemWidth: 1500px;
$superHeroItemHeight: 300px;

$standardCarouselItemWidth: 150px;
$standardCarouselItemHeight: 400px;
$standardCarouselItemPadding: 4px;

$squareCarouselItemWidth: 12vw;
$squareCarouselItemHeight: 33vh;
$squareCarouselItemPadding: 7px;

$videCarouselItemWidth: 24vw;
$videCarouselItemHeight: 29vh;

$sectionHeadingFontSize: 1.46154em;