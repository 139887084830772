.root {
  position: fixed;
  top: 150px;
  left: 0px;
  right: 0px;
  background-color: #000;
  color: #fff;
  font-weight: 200;
  -webkit-transition: padding .5s ease;
  -moz-transition: padding .5s ease;
  -o-transition: padding .5s ease;
  transition: padding .5s ease;
  z-index: 2;
  width: 100%;
  min-width: 320px;
  height: 80px;
  margin: auto;
  padding: 0;
}

.mediaNav {
  position: relative;
  font-size: 18px;
  list-style: none;
  margin: 15px 0 35px 28px;
  padding: 0;
  height: 50px;
  display: -webkit-inline-box;
}

.listItem {
  color: #dcdcdc;
  font-size: 18px;
  font-weight: 700;
  margin-right: .8vw;
  line-height: 16px;
  outline: none;
  border-radius: 63px;
  background: hsla(0,0%,100%,.3);
  border: 2px solid transparent;
  display: flex;
  align-items: center;
}

.listItem:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  background: hsla(0,0%,100%,.15);
  border: 2px solid #fff;
}

.listItem:active {
  border: 2px solid #fff;
}

.listItem a {
  color: #dcdcdc;
  text-decoration: none;
  transition: color .25s ease;
  padding: 25px 10px;
}

.listItem a:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
