.root {
  position: relative;
  top: 60px;
  left: 0px;
  right: 0px;
  color: #fff;
  width: 1220px;
  min-width: 320px;
  height: 33px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mediaNav {
  font-size: 16px;
  list-style: none;
  padding: 0;
  height: 33px;
  margin: 0;
  align-self: center;
  display: flex;
}

.listItem {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  width: 303px;
}

.listItem:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.active {
  border-bottom: 4px solid #792d8d;
}

.listItem a {
  color: #fff;
  text-decoration: none;
  margin: auto;
  padding-bottom: 5px;
}

.listItem a:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
