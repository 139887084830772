@import './variables.scss';

.container {
  max-width: 93%;
  margin-top: 10px;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px;
}

.seeAll {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  border-bottom: 2px solid #009688;
  margin-bottom: 2px;
}

.seeAll:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 2px solid #009688;
}

.heading {
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 15px;
}

.heading h2 {
  font-weight: 800;
  color: white;
  display: inline;
  margin-right: 15px;
  font-size: 20px;
}

.item {
  padding-right: 7px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.arrowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  border-width: 0 2px 2px 0;
  margin: 0 -3px;
  cursor: pointer;
  width: 35px;
  height: 98%;
  opacity: 0;
  background: $chiliGreenColor;
  transition: opacity 0.5s;
}

.sliderContainer {
  position: absolute;
  width: $sliderContainerWidth;
  height: 175px;
  right: 0;
}

.leftArrowContainer {
  left: -35px;
}

.rightArrowContainer {
  right: -35px;
}

.arrowContainer:hover {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 45%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  cursor: pointer;
  width: 15px;
  height: 15px;
  opacity: 0.8;
}

.rightArrow::before,
.leftArrow::before, {
  content: '' !important;
}

.rightArrow:hover,
.leftArrow:hover, {
  opacity: 1;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: -5px !important;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: -5px !important;
}
