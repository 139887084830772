.root {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto; 
  margin-right: auto; 
  display: inline-flex;
  flex-direction: column;
}

.img {
  padding: 5px;
  height: 100%;
  align-self: center;
}
