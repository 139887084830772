@import './variables.scss';
@import './minxins.scss';

.container {
  width: $smContainerWidth;
  border-radius: 10px;
  margin: 0 auto;
  .item {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    div {
      width: 100%;
      height: $heroCarouselHeight;
    }
  }
  .customPaging {
    @include slickStyles($colorLight);
    display: block;
  }
  .slickActive {
    @include slickStyles($colorLight);
  }
  .slickNormal {
    @include slickStyles($colorDarkLight);
  }

  @media (max-width: 2000px) {
    width: $xlContainerWidth;
  }
}
