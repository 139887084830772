@import './variables.scss';

.container {
  max-width: 1500px;
  margin-top: 10px;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px;
}

.heading {
  padding-bottom: 3px;
}

.heading h2 {
  font-size: $sectionHeadingFontSize;
  font-weight: 400;
  color: white;
}

.heading h3 {
  font-size: 13px!important;
  color: #999;
}

.item {
  padding-right: $standardCarouselItemPadding;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: $standardCarouselItemWidth;
  height: $standardCarouselItemHeight;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  opacity: 0.6;
}

.rightArrow::before,
.leftArrow::before {
  content: '' !important;
}

.rightArrow:hover,
.leftArrow:hover {
  opacity: 1;
}

.disabledArrow {
  display: none;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -25px;
}
