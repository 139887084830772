@import './variables.scss';

.item {
  position: relative;
  display: block;
  border-radius: 16px;
  background-size: cover;
  margin-bottom: 8px;
  &:hover{
    text-decoration: none;
  }
  .spotBackground {
    background: $colorDarkLight center center no-repeat;
    background-size: cover;
    margin-bottom: 8px;
    display: flex;
  }
  .spotDetails, .gridDetails {
    display: flex;
    flex-direction: column;
    color: $colorLight;
    max-width: $largeContainerItemWidth;
    .subtitle{
      opacity: .5
    }
    margin-bottom: 10px;
    &.gridDetails {
      max-width: $smallGridItemWidth;
    }
  }
}
.chartWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  width: 100%;
  .position {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.mosaicDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 15%;
  .spotName {
    color: $colorPrimary;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .subtitle {
    color: $colorLight;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .description {
    color: $colorGray;
    font-size: 16px;
  }
  .label {
    padding: 10px 15px;
    background-color: $colorGrayLight;
    color: $colorLight;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    width: fit-content;
  }
}
.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
