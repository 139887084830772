@import "./variables.scss";

.page {
  background-color: $backgroundColor;
  overflow-y: scroll;
  width: $pageWidth;
  height: 100vh;
  color: $fontColor;
}

.container {
  width: $containerWidth;
  margin: 0 auto;
}
