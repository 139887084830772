@import "@blueprintjs/core/lib/scss/variables";

.navBar {
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3) !important;
  z-index: 15 !important;
  height: 75px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation {
  height: 100%;
  display: inline-flex;
  align-items: center;
  gap: 40px;
}

.navButton {
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50% !important;
  background-color: rgb(103, 0, 103) !important;
  margin-right: 10px;
  &::before {color: $light-gray5 !important}
}

.historyButton {
  padding-left: 2px !important;
}

.popoverContainer {
  display: inline-flex;
  align-items: center;
}

.navigationList {
  background: rgb(103, 0, 103);
  padding: 15px !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navigationList > a, .navigationList > button {
  border: none !important;
  color: $light-gray5 !important;
  opacity: 0.75;
  font-size: 1.25em;
}

.navigationGrid {
  background: $violet1;
  padding: 15px 10px !important;
  margin: 0 !important;
  height: 300px;
}

.navigationList > button:hover, .navigationList > a:hover {
  background: none !important;
  opacity: 1;
  color: $light-gray5 !important;
}

.navLink {
  display: none !important;
}

.navLink:hover {
  background: none !important;
}

.navLinkActive {
  display: inline !important;
}

.navLink h5, .navigation h5 {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.btnDefault {
  box-shadow: inset 0 0 0 1px rgba(223, 238, 241, 0.2), inset 0 1px 2px rgba(223, 238, 241, 0.2) !important;
  background-color: $white !important;
  &.active, &:hover {
    background-color: #dfeef1 !important;
  }
  .labelColor {
    color: $black !important;
  }
}