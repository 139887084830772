@import './variables';

.item {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto auto;
  .position {
    grid-column: 1;
    font-size: 7.5rem;
    color: $grayColor;
    font-weight: bolder;
    font-family: sans-serif;
    margin: auto -40px auto 0;
  }
  div {
    grid-column: 2;
    margin-left: 30px;
  }
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}
