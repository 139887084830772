@import './variables.scss';

.tabMenuContainer {
  width: $smContainerWidth;
  background: $colorBlack;
  color: $colorLight;
  .tabWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    margin-left: 100px;
    .tabItem {
      margin-right: 5px;
      padding: 15px 20px;
      color: $colorDarkLight;
      font-weight: bold;
      &:hover {
        text-decoration: none;
        color: $colorPrimary;
      }
    }
    .activeTab {
      border-bottom: 4px solid $colorPrimary;
    }
  }
  @media (max-width: 2000px) {
    width: $xlContainerWidth;
  }
}