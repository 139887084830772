.item {
  height: 100%;
  width: 100%;
}

.item .itemImageVuduContainer {
  height: 100%;
}

.item .itemVuduDescription {
  height: 10%;
  display: table;
  white-space: pre-wrap;
  color: #fff;
  margin: 0 auto;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
