@import "./variables.scss";

.tabMenu {
  position: absolute;
  top: 145px;
  font-weight: 200;
  z-index: 2;
  width: $containerWidth;
  height: fit-content;
  line-height: 67px;
  display: flex;
  flex-direction: row;
  color: $fontColor;
  background: $backgroundColor;
  height: 70px;
  align-items: stretch;
  .activeTab {
    color: $fontColor;
    border-bottom: 4px solid $fontColor;
  }
  .tabMenuListItem, .listItemWithDropdown {
    text-decoration: none;
    margin: 0 15px 0 0;
    font-size: 1.1rem;
    font-weight: 700;
    color: $fontColor;
    font-family: $fontFamily;
    img { 
      width: 130px;
      display: flex;
    }
    span {
      padding: 3px 20px;
    }
    &.listItemWithDropdown{
      font-size: 1.5rem;
      i{
        font-size: 2rem;
        margin: 0 0 8px 8px;
      }
    }
  }
  .tabMenuListItem:hover, .listItemWithDropdown:hover{
    text-decoration: none;
    color: $fontColor;
    span {
      background: $shadowColor;
      border-radius: 30px;
    }
  }
}
.dropdownWrapper {
  flex: 8;
}
.dropdown {
  width: $dropdownWidth;
  height: fit-content;
  background: #000;
  position: relative;
  margin-top: -4px;
  border: 1px solid $dropdownFontColor;
  .dropdownList {
    margin: 0;
    padding: 20px 15px;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .dropdownItem {
      width: 33.3%;
      height: 40px;
      a {
        color: $dropdownFontColor;
        font-size: 18px;
        &:hover{
          text-decoration: none;
        }
        span {
          padding: 5px 15px;
          border-radius: 30px;
          &:hover {
            background: $shadowColor;
          }
          i {
            color: $fontColor;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

