@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.header {
  width: 100%;
  height: $headerHeight !important;
  background-color: rgb(103, 0, 103) !important;
  .customNavbarGroup {
    height: $headerHeight !important;
  }
  .divider {
    margin: 0 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    height: 45px;
  }
}

.btnDefault {
  box-shadow: inset 0 0 0 1px rgba(223, 238, 241, 0.2), inset 0 1px 2px rgba(223, 238, 241, 0.2) !important;
  background-color: $white !important;
  border: 1px solid $lightBlue !important;
  &.active, &:hover {
    background-color: $lightBlue !important;
  }
  .labelColor {
    color: $black !important;
  }
}

.header.staticHeader {
  position: static;
}

.spinner {
  width: 15px !important;
}

.tagFilter{
  margin-left: 15px;
  height: $headerHeight !important;
}

.downloadCollapse {
  flex-direction: column;
  margin-top: 46px;
  align-items: flex-end !important;
  max-width: 440px;
}

.heading {
  display: block;
  font-size: 16px;
  margin: 8px 0;
  font-weight: bold;
}

.collapseItems {
  padding: 4px;
  font-family: "IBM Plex Sans" !important;
}

.preCollapse {
  background: $white;
  position: absolute;
  right: 0;
}

.downloadBtn {
  width: 100%;
}

.downloadLinks {
  color: black;
  font-family: "IBM Plex Sans" !important;
}

.downloadLinks:hover{
  color: #670067 !important;
}

.inProgress {
  margin: 0 0 2px;
  font-family: "IBM Plex Sans" !important;
}

.collapseList {
  max-height: 300px;
  overflow-x: auto;
  background: $white;
}

.downloadsEmptyState {
  font-family: "IBM Plex Sans" !important;
}