.root {
  max-width: 1600px;
  width: 100%;
  overflow: hidden;
  height: 590px;
  background-color: #080808;
  margin: auto;
  clear: both;
}

.root > div{
  padding: 10px 10px;
  height: 99%;
}

.root img {
  border-radius: 4px;
  height: 570px;
}

.slick-dots {
  left: 10px;
  position: absolute;
  top: 10px;
  width: 15px;
}

.slickActive {
  opacity: 0.9;
  display: block;
  border-radius: 50%;
  background-color: #3478c1;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 15px !important;
  height: 15px !important;
  margin: 0 !important;
  margin-bottom: 15px !important;
}

.slickNormal {
  display: block;
  opacity: 0.3;
  border-radius: 50%;
  background-color: rgba(0,0,0,.5);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 15px !important;
  height: 15px !important;
  margin: 0 !important;
  margin-bottom: 15px !important;
}

.slickNormal button {
  background-color: rgba(0,0,0,.5)
}

.slickNormal button::before, .slickActive button::before{
  content: none !important;
}

.sectionDivider {
  background: #ff7300;
  background: -webkit-radial-gradient(center,ellipse,#ff7300 0,transparent 70%);
  background: -moz- oldradial-gradient(center,ellipse,#ff7300 0,transparent 70%);
  background: -o-radial-gradient(center,ellipse,#ff7300 0,transparent 70%);
  background: radial-gradient(ellipse at center,#ff7300 0,transparent 70%);
  display: block;
  height: 1px;
  max-width: 1600px;
  width: 100%;
}

.carouselItem {
  height: 570px;
  width: 1025px !important;
}