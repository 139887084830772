@import './variables.scss';

.wideContainer, .container {
  height: 380px;
  margin: 90px auto 70px;
}

.container {
  max-width: 1220px;
}

.wideContainer {
  max-width: 1460px;
}

.container h2, .wideContainer h2 {
  color: $light;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  min-width: 1220px;
  height: 380px;
}

.item div{
  min-width: 1220px;
  height: 380px;
}

.slickActive {
  border-radius: 50%;
  background: $light;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 10px !important;
  height: 10px !important;
  margin: 5px !important;
  margin-right: 7px !important;
}

.slickNormal {
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 10px !important;
  height: 10px !important;
  margin: 5px !important;
  margin-right: 7px !important;
}
