.page {
  background-color: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.page h3 {
  color: aliceblue;
}