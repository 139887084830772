$containerItemWidth: 235px;
$containerItemHeight: 350px;
$containerRectangleItemWidth: 615px;
$containerRectangleItemHeight: 345px;
$responsiveItemWidth: 200px;
$responsiveItemHeight: 320px;
$responsiveRectangleItemWidth: 420px;
$responsiveRectangleItemHeight: 224px;
$gridItemWidth: 49vw;
$gridItemHeight: 50vh;

$sliderContainerWidth: 930px;

$navbarButtonWidth:68px;

$navbarButtonTextColor: #ffffff;
$rowEightColor: #122339;