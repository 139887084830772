@import './variables.scss';

.tabMenuContainer {
  width: $smContainerWidth;
  background: $colorBlack;
  color: $colorLight;
  padding: 80px 0 50px 260px;
  filter: opacity(0.77);
  .heading {
    font-size: 4rem;
    color: $colorLight;
    margin-bottom: 25px;
  }
  .tabWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space;
    font-size: 18px;
    .tabItem {
      margin-right: 20px;
      color: $colorLight;
      &:hover {
        text-decoration: none;
      }
    }
    .activeTab {
      border-bottom: 2px solid $colorLight;
    }
  }
  @media (max-width: 2000px) {
    width: $xlContainerWidth;
  }
}