.container {
  min-width: 500px;
  height: 450px;
  margin: 0 0px 100px 0;
  position: relative;
  padding-top: 55px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  min-width: 1290px;
  height: 450px;
}

.item div{
  min-width: 1290px;
  height: 450px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 15%;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  cursor: pointer;
  width: 60px;
  height: 60px;
  opacity: 0.8;
  z-index: 5;
}

.rightArrow:hover,
.leftArrow:hover {
  border-left: 0px;
  border-top: 0px;
  opacity: 1;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 20px;
  height: 20px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  width: 20px;
  height: 20px;
}

.slickActive {
  opacity: 0.9;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 11px !important;
  height: 11px !important;
  margin: 0 !important;
  background-color: transparent;
  border: 2px solid #fff;
  box-sizing: content-box;
  border-radius: 50%;
  margin-right: 7px !important;
}

.slickNormal {
  opacity: 0.3;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  width: 10px !important;
  height: 10px !important;
  margin: 0 !important;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 7px !important;
}