@import './variables.scss';

.container {
  width: $pageWidth;
  position: relative;
  padding-bottom: 24px;
  border-radius: 10px;
  top: -70px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

.item div {
  width: 100%;
  height: $heroCarouselHeight;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}
