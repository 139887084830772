@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

:global html.react {
  overflow: hidden;
  height: 100%;
}

:global html.react body {
  margin: 0;
  background-color: white;
  padding-top: $pt-navbar-height;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.root {
  display: flex;
  flex-direction: column;
  position: absolute;
   top: $pt-navbar-height + 25;
  bottom: 0;
  left: 0;
  right: 0;
}

.row {
  clear: both;
  height: $gridCellHeight;
  width: 7400px;
}

.artwork {
  background-size: contain;
  background-repeat: no-repeat;
  height: $gridCellHeight - 20;
  width: ($gridCellHeight - 20) * 0.6;
  float: left;
  margin-right: 15px;
}

.card {
  padding: 10px;
  margin: 0px;
  height: $gridCellHeight;
  float: left;
  width: $titleInfoWidth;

  border-top-style: solid;
  border-width: 1px;
  border-color: $gray4;
  padding-right: 15px;
}

.card:hover {
  text-decoration: none;
}

.card h6 {
  line-height: 120%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 0;
  margin-bottom: 0;
}

.titleType {
  color: $gray1;
}

.auditCount {
  color: #D44;
}

.price {
  margin-top: 0;
  margin-bottom: 0;
  height: $gridCellHeight;
  float: left;
  width: $gridCellWidth;
  position: relative;
  border-top-style: solid;
  border-width: 1px;
  border-color: $gray4;
}

.priceHeader {
  border-top-style: solid;
  border-width: 1px;
  border-color: $gray4;
  float: left;
  width: 50px;
  height: $gridCellHeight;
  position: relative;
  font-weight: bold;

  color: #8A9BA8;
}

.fadeHeader .priceRow {
  opacity: 0.3
}

.priceRow {
  position: absolute;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.priceRow div {
  border-color: #8A9BA8;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 5px;
}

.hdPriceRow {
  top: 0;
}

.hdPriceRow div {
  background-color: #8A9BA8;
  color: white;
}

.sdPriceRow {
  bottom: 0;
}

.priceItem {
  cursor: pointer;
  position: absolute;
  width: 50%;
  height: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-color: $gray4;
  border-width: 1px;
}

.priceItem :global .bp5-popover-target {
  width: 100%;
  height: 100%;
  display: block;

  display: flex;
  align-items: center;
  justify-content: center;
}

.priceItem :global .bp5-popover-target :global .bp5-tether-target{
  width: 100%;
  height: 100%;
  display: block;

  display: flex;
  align-items: center;
  justify-content: center;
}

.priceItem:hover {
  background-color: #BFCCD6;
}

.topLeft {
  left: 0;
  top: 0;
  border-right-style: solid;
  border-right-color: #EBF1F5;
  border-left-style: solid;
}

.topRight {
  position: absolute;
  right: 0;
  top: 0;
}

.bottomRight {
  right: 0;
  bottom: 0;
  border-top-color: #EBF1F5;
  border-top-style: solid;
}

.bottomLeft {
  left: 0;
  bottom: 0;
  border-top-color: #EBF1F5;
  border-right-color: #EBF1F5;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
}

/* .image { */
/*     position: absolute; */
/*     top: 0; */
/*     left: 0; */
/*     bottom: 0; */
/*     width: 200px; */
/*     background-image: url(//d2zin8ttbglalq.cloudfront.net/production/audit_artworks/images/000/001/595/original/u?1511867294); */
/*     background-size: cover; */
/* } */

.price {
  float: left;
}

.hdLogo svg {
  position: absolute;
  left: 3px;
  top: 3px;
  vertical-align: middle;
  fill: #999;
  width: 20px;
  height: 20px;
}

.gridContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gridInnerContainer {
  flex-grow: 1;
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
}

.hasAction {
  position: absolute;
  top: 0;
  right: 0;
  width: 0px;
  height: 0px;
  border-width: 10px;
  border-top-color: red;
  border-top-style: solid;
  border-left-color: transparent;
  border-left-style: solid;
}

.hasActionCell.hasActionCell {
  color: #D44 !important;
  font-weight: bold;
}

.notAvailable {
  color: $gray5;
}

.promoted {
  border-radius: 2px;
  background: $pt-intent-success;
  padding: 4px;
  font-weight: bold;
  font-size: 10px;
  color: white;
  display: inline-block;
}
