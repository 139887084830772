$superHeroItemWidth: 1500px;
$superHeroItemHeight: 300px;

$twinHeroItemWidth: 460px;
$twinHeroItemHeight: 200px;
$twinHeroItemPadding: 4px;

$standardCarouselItemWidth: 150px;
$standardCarouselItemHeight: 200px;
$standardCarouselItemPadding: 4px;

$squareCarouselItemWidth: 200px;
$squareCarouselItemHeight: 200px;
$squareCarouselItemPadding: 4px;

$seasonCarouselItemWidth: 300px;
$seasonCarouselItemHeight: 175px;
$seasonCarouselItemPadding: 7px;

$sectionHeadingFontSize: 1.46154em;
