@import './variables.scss';

.item {
  position: relative;
  display: block;
  text-decoration: none;
}

.item div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.item div span{
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  margin-left: 25px;
}

.item:hover{
  text-decoration: none;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.amazonCollection {
  font-size: 19px;
  font-weight: 600;
  color: #fff;
}
