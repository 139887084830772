@import "./variables.scss";

.container {
  background-color: #212121;
  position: relative;
}

.inner {
  display: flex;
  flex-direction: row;
  max-width: 1520px;
  margin-left: auto;
  margin-right: auto;
}
