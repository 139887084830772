@import "@blueprintjs/core/lib/scss/variables";

.territoryWrap {
  padding: 10px;
  color: $white;
  background-color: $dark-gray5;
}

:global .bp5-popover-arrow-fill {
  fill: $dark-gray5 !important;
}


.flag {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 10px;
  top: 10px;
  opacity: 0.8;
}
