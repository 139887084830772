@import "./variables.scss";

.page {
  background-color: #212121;
  overflow-y: scroll;

  height: 100vh;
}

.header {
  max-width: 1260px;
  margin: auto;
  color: white;
  font-weight: 100;
  margin-top: 20px;
  font-size: 28px;
  letter-spacing: 1px;
}

.uber {
  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain;
  width: 1600px;
  margin: auto;
}

.center {
  max-width: 1260px;
  margin: auto;
}
