@import './variables.scss';

@mixin symmetricMosaicItemStyle ($heigh) {
  div {
    height: $heigh;
  }
}

.container {
  width: $containerWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 50px auto 0;
  &.first {
    margin-top: 120px;
  }
  .header {
    color: $colorLight;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .symmetricMosaic{ 
    display: grid;
    grid-gap: 1vw;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, $symmetricSmMosaicItemHeight);
    .symmetricMosaicItem {
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      div {
        width: 100%;
        height: $symmetricSmMosaicItemHeight;
        border-radius: $itemBorderRadius;
      }
      &:nth-child(1) {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        @include symmetricMosaicItemStyle($symmetricLgMosaicItemHeight);
      }
      &:nth-child(2) {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
      }
      &:nth-child(3) {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
      }
      &:nth-child(4) {
        grid-column: 3 / 4;
        grid-row: 3 / 4;
      }
      &:nth-child(5) {
        grid-column: 4 / 6;
        grid-row: 1 / 3;
        @include symmetricMosaicItemStyle($symmetricLgMosaicItemHeight);
      }
    }
  }
}

