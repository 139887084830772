.root {
  padding: 20px 0;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.infiniteGridItem {
  height: 170px;
  width: 20%;
  padding: 0 15px 15px 0;
}

.infiniteGridItem div {
  border-radius: 16px;
}
