@import './variables.scss';

.item {
  position: relative;
  display: block;
  border-radius: 16px;
  background-size: cover;
  margin-bottom: 8px;
  &:hover{
    text-decoration: none;
  }
  .spotBackground {
    background: $colorDark center center no-repeat;
    background-size: cover;
    margin-bottom: 8px;
    transition: background-image 0.3s ease-in-out;
    display: flex;
    align-items: end;
    .heroCarouselWrapper {
      height: fit-content;
      width: 100%;
      padding: 1%;
      h6, p{
        color: $colorLight
      }
    }
    .containerDetails {
      height: fit-content;
      width: 100%;
      padding: 0 0 10px 10px;
      h6, p{
        color: $colorLight
      }
    }
    .landscapeContainer {
      color: $colorLight;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      p{
        margin-left: 10px;
      }
    }
  }
  .chartWrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto;
    width: $chartItemWidth;
    height: $chartItemHeight;
    background: $colorGrayLight;
    .chartCount {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4rem;
      font-weight: bold;
      color: $colorGray;
    }
    .chartSpotBackground {
      width: $chartSpotItemWidth;
      height: $chartSpotItemHeight;
      background: $colorDark center center no-repeat;
      background-size: cover;
      margin-bottom: 8px;
      transition: background-image 0.3s ease-in-out;
      display: flex;
      align-items: end;
      .containerDetails {
        height: fit-content;
        width: 100%;
        padding: 0 0 10px 10px;
        h6, p{
          color: $colorLight
        }
      }

    }
    .spotName {
      grid-column: 1 / span 2;
      color: $colorLight;
      font-weight: bold;
      text-align: left;
      font-size: 0.9rem;
      margin-left: 10px;
      span{
        font-weight: 300;
      }
    }
    &:hover {
      background-color: $colorLight;
      .spotName {
        color: $colorDark;
      }
    }
  }
  .chartWrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto;
    width: $chartItemWidth;
    height: $chartItemHeight;
    background: $colorGrayLight;
    .chartCount {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4rem;
      font-weight: bold;
      color: $colorGray;
    }
    .chartSpotBackground {
      width: $chartSpotItemWidth;
      height: $chartSpotItemHeight;
      background: $colorDark center center no-repeat;
      background-size: cover;
      margin-bottom: 8px;
      transition: background-image 0.3s ease-in-out;
      display: flex;
      align-items: end;
      .containerDetails {
        height: fit-content;
        width: 100%;
        padding: 0 0 10px 10px;
        h6, p{
          color: $colorLight
        }
      }

    }
    .spotName {
      grid-column: 1 / span 2;
      color: $colorLight;
      font-weight: bold;
      text-align: left;
      font-size: 0.9rem;
      margin-left: 10px;
      span{
        font-weight: 300;
      }
    }
    &:hover {
      background-color: $colorLight;
      .spotName {
        color: $colorDark;
      }
    }
  }
  .spotNameLight, .spotNameDark {
    color: $colorDark;
    font-weight: bold;
    text-align: left;
    font-size: 0.9rem;
    max-width: $smallGridItemWidth;
    &.spotNameDark {
      color: $colorLight;
    }
    span{
      font-weight: 300;
    }
  }
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
