@import "./variables.scss";

.page {
  background-color: $backgroundColor;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  color: $fontColor;
}

.container {
  max-width: $pageWidth;
  width: 80%;
  margin: -7px auto 0;
}

@media (max-width: 2000px) {
  .container {
    max-width: 80%;
  }
}
