.root {
  width: 100%;
  height: 300px;
  display: flex;
  margin-bottom: 20px;
}

.marketingBannerItem {
  width: 25%;
  height: 100%;
}

.marketingBannerItem > div, a {
  border-radius: unset !important;
}