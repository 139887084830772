@import "@blueprintjs/core/lib/scss/variables";

.dialogBody {
  margin: 0 !important;
}

.dialog.dialog {
  position: absolute;
  width: 800px !important;
  overflow: hidden;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 0 rgba(16,22,26,0.15);
}

.header {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 4;
}

.scores, .scoreUnit {
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-bottom: 3px;
}

.body {
  min-height: 100px;
}

.artworkContainer {
  width: 100%;
  flex: 1;
  .artwork {
    margin: 20px;
  }
}

.panel.panel {
  width: 100% !important;
}

.title {
  margin-bottom: 2px;
  font-size: 24px;
}

.titleType {
  color: $gray1;
}

.vendorIdentifier {
  font-size: 10px;
  color: $gray1;
}

.tableContainer {
  padding: 10px;
  max-height: 60vh;
  overflow-y: auto;
  width: 100%;
  text-align: left;
}

.tableContainer a {
  color: $dark-gray1;
  text-decoration: underline;
}

.tableContainer a:hover, .tableContainer a:visited {
  color: $violet1;
}

.table {
  width: 100%;
  th, td {
    padding: 7px;
  }
  th {
    background-color: $white;
  }
  tr:nth-child(odd) {
    background-color: #ecf4f8;
  }
  tr:nth-child(even) {
    background-color: $white;
  }
}

.tabs {
  padding: 0 20px 0 20px;
}

.merchWrapper {
  max-height: 400px;
  overflow-y: scroll;

  .filterContainer a {
    color: $blue2;
    text-decoration: none;
  }

  .filterContainer a:hover, a:visited {
    color: $blue2;
    text-decoration: underline;
  }

}

.dialogHeader {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 10px 0 10px 20px;
}

.filterButton {
  width: fit-content;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 4px;

  svg {
    margin-right: 0 !important;
  }
}
