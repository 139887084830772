@import "./variables.scss";

.root {
  height: 55px;
  padding: 20px;
  margin-left: 3%;
}

.root select {
  width: 200px;
  border-radius: 8px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: white;
  padding: 10px 5px;
  font-size: 1.1em;
  cursor: pointer;
  border: 1px solid #949494;
  background: none;
  background-repeat: no-repeat;
  background-size: 0.6em auto;
  background-position: right 0.25em center;
  background-image: url("data:image/svg+xml;charset=utf-8, \
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 40'> \
      <polygon points='0,0 60,0 30,40' style='fill:white;'/> \
    </svg>");
}
