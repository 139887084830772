
.container {
  height: 350px;
  position: relative;
  padding: 5px 5px 30px 50px;
}

.item {
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
  float: left;
  margin-bottom: 60px;
}

.sectionHeader {
  padding-top: 110px !important;
  height: 50px;
  position: relative;
  padding-bottom: 110px
}

.sectionHeader h3 {
  padding: 20px 20px 20px 50px;
  color: white !important;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2rem;
}

.item div {
  width: 100%;
  height: 100%;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}