@import './variables.scss';
@import './minxins.scss';

.heroCarouselDetails {
  .heroSubtitle {
    color: $colorLight;
    background: $colorRed;
    padding: 20px 60px;
    position: absolute;
    right: 0;
    bottom: 70px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
  }
  .descriptions{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    height: 100%;
    color: $colorLight;
    margin-left: 110px;
    h2 {
      color: $colorLight;
      font-size: 48px;
      margin-bottom: 20px;
    }
    .logoWrapper {
      display: flex;
      flex-direction: row;
      img {
        width: 60px;
        margin-right: 20px;
      }
      .textWrapper {
        display: flex;
        flex-direction: column;
        .desc {
          border: 1px solid $colorLight;
          border-radius: 5px;
          padding: 3px 10px;
          width: fit-content;
        }
        .label {
          font-size: 28px;
        }
      }
    }
  }
}

.containerDetail {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 35px;
  color: $colorLight;
  font-weight: bold;
  text-align: left;
  &:hover {
    text-decoration: none;
  }
  span:nth-child(1) {
    font-size: 4rem;
    margin: 100px 0 55px 0px;
  }
  span:nth-child(2) {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  span:nth-child(3) {
    font-size: 1rem;
  }
  span:nth-child(4) {
    margin: auto 0 30px 0;
    background: $colorLight;
    color: $colorDark;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.landscapeDetails {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  .availability {
    @include labelStyle(hsla(0,0%,93%,.9), $colorDark);
  }
  .soon {
    @include labelStyle($colorRed, $colorLight);
  }
  img {
    width: 40px;
    margin: auto 0 10px 10px;
    border-radius: 5px;
  }
}

.landscapeGridDetails {
  color: $colorLight;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  position: absolute;
  left: 10px;
  bottom: 10px;
}
