@import "./variables.scss";

.page {
  background-color: $colorLightLight;
  overflow-y: scroll;
  width: $pageWidth;
  height: 100vh;
  .container {
    box-shadow: 0 0 24px rgba(0,0,0,.2);
    background-color: $colorLight;
    width: $smContainerWidth;
    height: fit-content;
    margin: 0 auto;
  }

  @media (max-width: 2000px) {
    .container {
      width: $xlContainerWidth;
    }
  }
}
