.root {
  padding: 15px 0;
}

.noPadding {
  padding: 0;
}

.fullWidth {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.withBottomBorder {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.2);
}

.extraPadding {
  padding: 20px;
}

.flex {
  flex: 1;
}

.withBorder {
  box-shadow: inset -1px 0 0 rgba(16, 22, 26, 0.2);
}
