@import "./variables.scss";

.root {
  float: left;
  display: inline-block;
  margin: 5px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 2px;
  position: relative;
}

.tallCover {
  height: 330px;
  width: $cardWidth;
}

.squareCover {
  height: 245px;
  width: $cardWidth;
}

.halfCover {
  height: 160px;
  width: $cardWidth;
}

.details {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 7px 10px 0;
  position: relative;
  max-height: 50px;
  background-color: white;
}

.squareCover .cover {
  height: 160px;
}

.halfCover .cover {
  height: 91px;
}

.halfCover .reason {
  display: none;
}

.cover {
  border-radius: 2px 2px 0 0;
  background-color: #f5f5f5;
  overflow: hidden;
  position: relative;

  height: 245px;
  width: $cardWidth;
}

.title {
  color: #212121;
  display: block;
  font-size: 16px;
  line-height: 18px;
  max-height: 36px;
  min-height: 18px;
  overflow: hidden;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
}

.coverImageContainer {
  height: 110%;
  left: -5%;
  position: relative;
  top: -5%;
  width: 110%;
}

.coverOuterAlign {
  display: table;
  height: 100%;
  width: 100%;
}

.coverInnerAlign {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.coverImage {
  max-height: 257px;
  max-width: 170px;
}

.paragraphEnd {
  height: 19px;
  width: 45px;
  background: linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,1));
  bottom: 0;
  height: 25px;
  max-height: 100%;
  position: absolute;
  right: 0;
  width: 60px;
}

.subtitle {
  width: 140px;
  color: #616161;
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  margin: 1px 0;
  max-width: 132px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 300;
}

.reason {
  max-height: 38px;
  background-color: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 2px;
  padding: 0 10px;
}

.ratingContainer {
  display: block;
  height: 24px;
  margin-top: 5px;
}

.rating {
  height: 14px;
  width: 69px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAQAAAC1QeVaAAAAYElEQVR4AY3QQQeEIRCA4T13iqFDp04dYoh+2vv/7Y7YL4b66j1MPCn6fA+9438hiI0NKrpBhGGJQxKZSp/YbZdJCwvDVda1not7k/ZQm3SPw6qWTYcElDgPRZRw9X2HfsTTkjWVYOnFAAAAAElFTkSuQmCC) top left repeat-x;
  background-size: contain;
}

.currentRating {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAQAAADYBBcfAAAA60lEQVR4AdXTIQjCQBTGcYNgMAt2BGGdZbHY28BeLAv/ok0wmAwWix37elsfbL2XrY7B2HbCGGPobW9bUr5yd7wf3L3HTdTI/BTkPAqypmA9Bt5Q3AZDZkQoImZD4R5VZj8UuhV0B0EMVB2jE7LAYIuFzZUnQQMGPLliY7HFYPEJL6heuXxdFVtm2No3YpF2oBSrtTnsiFtYzK6zq5iEGhZiiuPgoIGHHnPkoYGPPtDTQE+EzMnq8qJeZcwluKlKc+4suZNX+40ET2WZj1l32S9PThJ0SDgybZxMOZLgSPDFSvNbVrwEKOcP4Rt15kTMQuVR7QAAAABJRU5ErkJggg==) top left repeat-x;
  background-size: contain;
  height: 14px;
}

.sectionFade {
  opacity: 0.2;
}

.spotHighlight {
  opacity: 1 !important;
}
