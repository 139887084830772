@import "./variables.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 4vw 4vh 0;
  .firstItem {
    text-decoration: none;
    color: $colorLight;
    padding: 10px 20px;
    border: 1px solid $colorLight;
    .arrowDown {
      border: solid $colorLight;
      border-width: 0 1px 1px 0;
      display: inline-block;
      cursor: pointer;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin: 0 0 3px 5px;
    }
  }
}
.dropdown {
  min-width: 140px;
  background-color: $colorLight;
  .dropdownList {
    list-style: none;
    padding: 0;
    margin: 0;
    .activeLink{
      background-color: $colorPrimary;
    }
    .listItem {
      a{ 
        text-decoration: none;
        color: #1a1a1a;
        display: block;
        padding: 10px 15px;
        margin: 1%;
        border: 2px solid transparent;
        font-weight: bold;
        &:hover {
          text-decoration: none;
          border-color: $colorPrimary;
        }
      }
    }
  }
}