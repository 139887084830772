.bulkSelectors a {
  font-weight: bold;
  font-size: 1.1em;
}

.selectorContainer{
  margin: 10px 0;
}

.platformAndTerritoryList {
  max-height: 500px;
  overflow-x: auto;
  padding: 10px !important;
}

.platformItem {
  display: flex;
  flex-direction: column;
}

.itemWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdownIcon {
  cursor: pointer;
}

.dropdownIconDisabled {
  color: #979fa6 !important;
  cursor: disabled;
}

.platformList {
  padding: 0;
  margin: 5px 0 10px 27px;
  list-style: none;
}

.platformList li {
  margin-bottom: 10px;
}

.checkbox {
  svg {
    display: none;
  }
}