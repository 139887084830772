.page {
  background-color: #14233a;
  overflow-y: scroll;

  height: 100vh;

  position: relative;

  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
