@import './variables.scss';

.container {
  width: $containerWidth;
  position: relative;
  padding-bottom: 24px;
  margin: 100px auto 0;
  &.first {
    margin-top: 100px;
  }
  .header {
    font-size: $containerHeaderFontSize;
    color: $colorDark;
    font-weight: 700;
    .highlighted {
      border-bottom: 4px solid $colorPrimary;
    }
  }
  .spotWrapper{ 
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
  }
  .gridItem, .landscapeGridItem {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    float: left;
    margin: 0 20px 25px 0;
    div {
      width: $gridItemWidth;
      height: $gridItemHeight;
    }
    &.landscapeGridItem{
      div {
        width: $landscapeGridItemWidth;
        height: $landscapeContainerItemHeight;
      }
    }
    @media (max-width: 2000px) {
      margin-right: 35px;
      div {
        width: $smallGridItemWidth;
        height: $smallGridItemHeight;
      }
      &.landscapeGridItem{
        div {
          width: $smallLandscapeGridItemWidth;
          height: $smallLandscapeGridItemHeight;
        }
      }
    }
  }
}
