.item {
  position: relative;
  display: block;
  cursor: pointer;
  animation: fadeIn 2s;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {

  0% {
    opacity: 0; }

  100% {
    opacity: 1; }
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}

.spotCover{
  position: absolute;
  z-index: 5;
  opacity: 0;
  padding: 1%;
}

.spotCover {
  color: #fff;
}

.spotCover h3 {
  color: #fff;
  margin-bottom: 20px;
}

.spotCover p {
  font-size: 15px;
  margin-bottom: 15px;
}

.coverButton {
  width: 26px;
  height: 10px;
  background: rgba(0,0,0,.4);
  border-radius: 30px;
  position: absolute;
  top: 95%;
  left: 48%;
}

.spotCover:hover {
  background-color: rgb(100, 0, 125);
  opacity: 0.902;
  transition: .4s ease-in-out opacity;
}

.carouselCoverImg, .girdCoverImg{
  display: block;
  position: absolute;
}

.carouselCoverImg {
  top: 30vh;
  left: 35vw;
}

.girdCoverImg {
  max-width: 50%;
  top: 25%;
  left: 25%;
}
