@import './variables.scss';
@import '../merchandizing-mixins.scss';

.container {
  width: 100%;
  height: 46.8vw;
  position: relative;
  margin: 60px auto 0;
  padding-bottom: 24px;
  @include alignSliderSpots();
}

.sliderWrapper{
  width: $containerWidth;
  margin: 0 auto;
  position: absolute;
  left: 3.5vw;
  top: 20%;
}

.heading {
  margin-bottom: 220px;
}

.heading h1 {
  font-size: 4em;
  font-weight: 700;
  color: #fff;
  font-family: Amazon Ember,Amazon Arabic Ember,Arial,sans-serif;
}

.heading .linkWrapper{
  margin-top: 50px;
  background: #fff;
  display: inline-block;
  border-radius: 20px;
}

.heading .linkWrapper a {
  text-decoration: none;
  color: #000;
  padding: 20px 50px;
  display: block;
  font-size: 2em;
  font-weight: 600;
}

.heading .linkWrapper:hover {
  transform: scale(1.05);
  transition-duration: .1s;
}

.heading .linkWrapper a:hover {
  text-decoration: none;
  color: #000
}

.item {
  padding-right: $containerItemPadding;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 21px;
}

.item div {
  width: $containerItemWidth;
  height: $containerItemHeight;
  border-radius: 16px;
}

.item div:hover {
  transform: scale(1.20);
  transition-duration: .8s;
  z-index: 100;
  position: absolute;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 45%;
  border: solid white;
  border-width: 0 4px 4px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  opacity: 0;
}

.rightArrow::before,
.leftArrow::before, {
  content: '' !important;
}

.container:hover .rightArrow,
.container:hover .leftArrow {
  opacity: 1;
}

.disabledArrow {
  display: none;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -25px;
}
