@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.posterImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
}

.loaded {
  opacity: 1;
  transition: opacity 0.4s;
}

.tvPoster {
  width: $tvPosterWidth;
  height: $tvPosterHeight;
}

.moviePoster {
  width: $moviePosterWidth;
  height: $moviePosterHeight;
}

.blankImage {
  opacity: 1;
}

.auditArtwork {
  float: left;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-left-style: solid;
  border-width: 1px;
  border-color: $gray4;
}

.overlay {
  border-width: 1px;
  border-color: $gray4;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.overlayFirst {
  margin-right: 15px;
}

.popover {
  display: flex;
  padding: 15px;
}

.overlayContainer {
  display: flex;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
}

.inner {
  box-shadow: 0 0 0 1px rgba(16,22,26,.1);
  background-color: $light-gray5;
  position: relative;
}

.changeIndicator {
  position: absolute;
  right: 5px;
  bottom: 5px;

  border-radius: 2px;
  /* border: 2px solid $gray1; */
  background: #F1F1F1;
  padding: 4px;
  font-weight: bold;
  font-size: 10px;
  color: black;

}

.notChanged {
  opacity: 0.4;
}

.notChanged:hover {
  opacity: 1;
}

.popoverImage {
  min-height: $popoverMinHeight;
  max-width: $popoverMaxWidth;
  max-height: $popoverMaxHeight;
}
