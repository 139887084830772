@import "./variables.scss";

.root {
  position: fixed;
  font-weight: 200;
  z-index: 2;
  width: 100%;
  line-height: 70px;
  margin: -5px auto;
  height: fit-content;
  line-height: 70px;
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
  color: $fontColor;
  max-width: $pageWidth;
  background-color: $backgroundColor;
  border-bottom: 1px solid $dividerColor;
}

.menuHeader {
  display: flex;
  flex-direction: row;
}

.menuHeader img {
  width: 72px;
  height: 72px;
}

.menuHeader h2 {
  margin: 0 0 0 20px;
  font-size: 36px;
  color: $fontColor;
  font-family: $fontFamily;
  align-self: center;
}

.menuLinks{
  height: 70px;
}

.listItem {
  text-decoration: none;
  margin: 0 15px 0 0;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 23px 0;
  color: $shadowFontColor;
  font-family: $fontFamily;
}

.listItem:hover{
  text-decoration: none;
  border-bottom: 2px solid $shadowFontColor;
  color: $shadowFontColor;
}

.active {
  color: $fontColor;
  border-bottom: 2px solid $fontColor;
}

@media (max-width: 2000px) {
  .root {
    max-width: 82%;
  }
}
