.root {
  position: fixed;
  top: 150px;
  left: 0px;
  right: 0px;
  background-color: #000;
  color: #fff;
  font-weight: 200;
  -webkit-transition: padding .5s ease;
  -moz-transition: padding .5s ease;
  -o-transition: padding .5s ease;
  transition: padding .5s ease;
  z-index: 2;
  width: 100%;
  min-width: 320px;
  height: fit-content;
  line-height: 70px;
  margin: auto;
  padding: 0;
}

.logo {
  display: flex;
  align-items: center;
  width: 215px;
  max-height: 27px;
  padding: 33px 0 0 20px;
  float: left;
  margin-right: 40px;
}

.logo img {
  width: 215px;
  height: 27px;
}

.mediaNav {
  position: relative;
  font-size: 18px;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 43px;
  display: -webkit-inline-box;
}

.listItem {
  color: #dcdcdc;
  font-size: 16px;
  font-weight: 700;
  margin-right: 1.8vw;
  line-height: 1rem;
  outline: none;
  padding: 0 3%;
  border-bottom: 3px solid transparent;
}

.listItem:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 3px solid #00afff;
}

.listItem a {
  color: #dcdcdc;
  text-decoration: none;
  transition: color .25s ease;
}

.listItem a:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
