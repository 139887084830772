.rightArrow,
.leftArrow {
  position: absolute;
  top: 45%;
  border: solid white;
  border-width: 0 4px 4px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  z-index: 100;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 35px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 35px;
}

.disabledArrow {
  display: none;
}