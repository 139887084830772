@import "./variables.scss";

.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  top: $toolbarOffset !important;
}

.ghostRow {
  padding: 10px;
  margin: 0px;
  height: $gridCellHeight;
  float: left;
  width: $rowHeaderWidth;
  padding-right: 15px;
}

.spinnerWrapper {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}