@import "./variables.scss";

.container {
  position: absolute;
  right: 0;
  top: $carouselItemHeight + 40px;
  width: $sidePanelWidth;
  background-color: $itunesBackgroundColor;
  padding-left: 7px;
  height: 55px;
}

@media(min-width: $screen-xl) {

  .container {
    width: $sidePanelWidth-xl;
    right: 40px;
  }
}

.root select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: white;
  font-size: 1.1em;
  padding-right: 1em;
  cursor: pointer;
  border: none;
  background: none;
  background-repeat: no-repeat;
  background-size: 0.6em auto;
  background-position: right 0.25em center;
  background-image: url("data:image/svg+xml;charset=utf-8, \
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 40'> \
      <polygon points='0,0 60,0 30,40' style='fill:white;'/> \
    </svg>");
}
