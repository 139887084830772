@import './variables.scss';

.container {
  width: $pageWidth;
  position: relative;
  padding-bottom: 24px;
  border-radius: 10px;
  top: 70px;
}

.item {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

.item div {
  width: 100%;
  height: 100vh;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.dot {
  height: 100% !important;
  width: unset !important;
}

.dotActive {
  height: 100% !important;
  width: unset !important;

  div {
    border: 2px solid $colorDark;
  }
}

.priceBadge {
  align-self: center;
  border-radius: 4px;
  display: flex;
  font-size: .625rem;
  font-weight: 700;
  letter-spacing: .05rem;
  line-height: 1.125rem;
  padding: 1px 8px;
  pointer-events: none;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  white-space: normal;
  z-index: 1;
  background: #1b1b24;
  color: #f1f0fa;
  width: fit-content;
}

.pagingContainer {
  display: block;
  width: 14vw;
  height: 90%;
  box-shadow: 1px 1px 4px rgba(0,0,0,.4);
  overflow: hidden;
  padding: 5px;
}