@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: $pt-navbar-height + 25;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: auto;
}

.ptMenuItemLetter {
  padding: 2px !important;
  line-height: 10px !important;
  &:hover {
    background-color: $gray5;
    cursor: pointer;
  }
  a{
    color: $gray3;
  }
}

.gridWithMargin {
  margin-top: 75px;
}
