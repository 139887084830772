@import "@blueprintjs/core/lib/scss/variables";

.platformHeader {
  margin-left: 400px;
  margin-top: 25px;
}

.platformItem {
  float: left;
  width: 171px;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-left-style: solid;
  border-image: linear-gradient(to top, #A7B6C2, rgba(0, 0, 0, 0)) 1 100%;
  position: relative;
}

.withFlag {
  border-image: none;
  border-color: #A7B6C2;
}

.platformLogo {
  width: 60px;
  height: 60px;
  background-size: cover;
}

.buyHeader {
  width: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 25px;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rentHeader {
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 25px;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyHeader div, .rentHeader div {
  font-size: 10px;
  border-radius: 4px;
  border-color: #8A9BA8;
  border-width: 2px;
  padding-left: 3px;
  padding-right: 3px;
  text-transform: uppercase;
  font-weight: bold;
  color: #8A9BA8;
}
