@import "@blueprintjs/core/lib/scss/variables";
$panelWidth: 300px;

.root {
  border-radius: 3px;
  width: $panelWidth;
  padding: 10px;
}

.panelHeader {
  color: $dark-gray1;
  height: 30px;
  border-bottom: 2px solid $dark-gray1;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
}

.scanDate {
  float: right;
}

.clickableTitle {
  cursor: pointer;
}

.clickableTitle:hover {
  text-decoration: underline;
}
