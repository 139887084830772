@import './variables.scss';

.container {
  width: 96vw;
  position: relative;
  margin: 80px auto 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}

.heading {
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-right: 12px;
    font-size: 1.6rem;
    color: $colorLight;
  }
}

.subheading {
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;

  h2 {
    font-size: 15px;
    font-weight: 600;
    margin-right: 12px;
    font-size: 1.25rem;
    color: $colorLight;
  }
}