.page {
  background-color: #0f171e;

  height: 100vh;
  overflow-y: hidden;
  position: relative;
  font-family: Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.pageSpinner {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transparentBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  opacity: 0.4;
  background: aliceblue;
}

.transparentSpinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infiniteScroll {
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}