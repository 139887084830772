@import './variables.scss';

.container {
  max-width: 1220px;
  margin: 120px auto 0;
  position: relative;
}

.container + .container {
  margin-top: 0px ;
}

.heading {
  padding: 10px;
  margin-top: 150px;
}

.heading h2 {
  font-size: $sectionHeadingFontSize;
  font-weight: 700;
  color: white;
}

.spotWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.item, .collection {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  opacity: 1;
}

.item div {
  width: $gridItemWidth;
  height: $gridItemHeight;
  margin: 15px 10px;
}

.collection div {
  width: $collectionItemWidth;
  height: $collectionItemHeight;
  margin: 20px 10px;
}
