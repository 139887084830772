@import './variables.scss';

.item {
  position: relative;
  display: block;
  border-radius: 16px;
  background-size: cover;
  margin-bottom: 8px;
  &:hover{
    text-decoration: none;
  }
  .spotBackground {
    background: $colorDark center center no-repeat;
    background-size: cover;
    margin-bottom: 8px;
  }
  .viewAllSpot {
    border: 2px solid $colorPrimary;
    background-color: $colorLight;
    color: $colorPrimary;
    display: flex;
    flex-direction: column;
    &:hover {
      background-color: $colorPrimary;
      color: $colorLight;
    }
    .spotName{
      font-size: 16px;
      margin: 20px;
    }
    .arrowRight{
      margin: auto 20px 20px auto;
    }
  }
}
.fade {
  opacity: 0.2;
}
.highlight {
  opacity: 1 !important;
}
