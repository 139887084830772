@import "./variables.scss";

.root {
  position: relative;
  font-weight: 200;
  z-index: 2;
  width: $containerWidth;
  height: fit-content;
  line-height: 67px;
  display: flex;
  flex-direction: row;
  color: $colorLight;
  background: transparent;
  .menuLinks {
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .listItem {
      text-decoration: none;
      margin: 0 25px 0 0;
      font-size: 1.3rem;
      font-weight: 700;
      color: $colorLight;
      font-family: $fontFamily;
      span {
        padding: 3px 20px;
      }
      &:hover{
        text-decoration: none;
        color: $colorLight;
      }
    }
  }
}
