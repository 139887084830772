@import './variables.scss';

.disabledArrow{
  display: none !important;
}

.arrowWrapperRight, .arrowWrapperLeft {
  position: absolute;
  top: 3vh;
  width: 90px;
  height: 70%;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &.arrowWrapperLeft {
    left: 0px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent);
  }

  &.arrowWrapperRight {
    right: 0px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.4), transparent);
  }
  .rightArrow, .leftArrow {
    border: solid $colorLight;
    border-width: 0 3px 3px 0;
    display: block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    box-shadow: 2px 2px rgb(168, 168, 168);
    @mixin arrowRotation($angle) {
      transform: rotate($angle);
      -webkit-transform: rotate($angle);
    }
    &.rightArrow {
      @include arrowRotation(-45deg);
    }
    &.leftArrow {
      @include arrowRotation(135deg);
    }
  }
}