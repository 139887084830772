@import "@blueprintjs/core/lib/scss/variables";
@import "../variables.scss";

.suggestionsContainer {
  display: none;
}

.suggestionsContainerOpen {
  display: block;
  position: absolute !important;
  margin-top: 5px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-size: 12px;
  left: 0;
  top: 52.5px;
  width: 100%;
  overflow: hidden;
  z-index: 10000;
}

.suggestionContent {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.suggestionContentInfo {
  margin-top: 2px;
  p {
    min-height: 20px;
    font-size: 12px;
  }
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  position: relative;
  cursor: pointer;
  padding: 4px 5px;
}

.suggestionContainer {
  display: flex;
  overflow-y: auto;
  max-height: 100px;
}

.suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.suggestionHighlighted {
  background-color: #ddd;
}

.container {
  position: absolute !important;
}

.tagFilterContainer {
  position: relative;
}

.tagSearchIcon {
  position: absolute;
  left: 2px;
}

.dropdownSign {
  position: absolute;
  right: 1px;
}

.filterSuggestionContent {
  position: absolute;
  width: 100%;
}

.suggestionsName {
  margin-top: 8px;
}

.deleteTag {
  position: absolute;
  margin-top: 8px;
  right: 7px;
}

.autosuggestContainer {
  position: relative;
  height: 75px;
  width: 200px;
}

.cancelButton {
  color: #670067 !important;
  border: none !important;
}

.yesButton {
  background: #670067 !important;
  color: white !important;
  border-radius: 5px;
}

.dialogBox {
  height: 100px;
  font-size: 13px;
}

.btnDefault {
  box-shadow: inset 0 0 0 1px rgba(223, 238, 241, 0.2), inset 0 1px 2px rgba(223, 238, 241, 0.2) !important;
  background-color: $white !important;
  border: 1px solid $lightBlue !important;
  &.active, &:hover {
    background-color: $lightBlue !important;
  }
  .labelColor {
    color: $black !important;
  }
}
