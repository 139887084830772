@import "./variables.scss";

.dropdownWrapper {
  font-family: $fontFamily;
  margin-right: 20px;
  .dropdownItem{
    color: $colorLight;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: rgba(73, 69, 129, .6);
    width: fit-content;
    .arrowDown {
      border: solid $colorLight;
      border-width: 0;
      display: block;
      cursor: pointer;
      width: 15px;
      height: 15px;
      box-shadow: 2px 2px $colorLight;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin: 0 0 14px 15px;
    }
  }
  &:hover{
    .dropdownItem {
      text-decoration: none;
      color: $colorLight;
    }
  }
}
.dropdown {
  min-width: $dropdownWidth;
  height: fit-content;
  background: $colorDark;
  position: relative;
  margin-top: -4px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: fit-content;
  height: 47vh;
  .listItem {
    width: fit-content;
    margin: 15px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: $colorLight;
      font-weight: 700;
      font-size: 1rem;
      &:hover{
        text-decoration: none;
        color: $colorLightDark;
        border-bottom: 1px solid $colorLight;
      }
    }
    .activeLink {
      border-bottom: 1px solid $colorLight;
    }
  }
}

