.item {
  line-height: 48px;
  cursor: pointer;
}

.item a {
  display: block;
  width: 100%;
  height: 100%;
  color: black;
  padding: 0 14px;
}

.item a:hover {
  text-decoration: none;
  background-color: rgba(0,0,0,0.05);
}
