
@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

:global html.react {
  overflow: hidden;
  height: 100%;
}

:global html.react body {
  margin: 0;
  background-color: white;
  padding-top: $pt-navbar-height;
  height: 100%;
  width: 100%;
  overflow: auto;
  font-size: 13px;
  color: $dark-gray1;
}

.gridHeader {
  box-shadow: 0 3px 4px 0px rgba(0,0,0,.2);
  z-index: 5;
}

.root {
  display: flex;
  flex-direction: column;
  position: absolute;
   top: $pt-navbar-height + 25;
  bottom: 0;
  left: 0;
  right: 0;
}

.platformItemTable {
  height: 100%;
  width: 100%;
  border-left: 1px solid $gray4;
  border-collapse: collapse; border-spacing: 0;
}

.platformItemTable tr {
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0px;
}

.platformItemTable td {
  height: 100%;
  width: 100%;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-color: #8A9BA8;
  border-width: 2px;
  text-transform: uppercase;
  color: $dark-gray1;
  font-weight: 600;
  font-size: 11px;
  border-right: 1px solid #EBF1F5;
  padding: 0px;
}

.avodColumn {
  width: $avodCellWidth !important;
}

.svodColumn {
  width: $svodCellWidth !important;
}

.tvodColumn {
  width: $tvodCellWidth !important;
}

.buyColumn {
  float: left;
}

.rentColumn {
  float: left;
  border-left: 1px solid $borderColor;
}

.halfTvodColumn {
  width: 75px !important;
}

.row {
  clear: both;
  height: $gridCellHeight;
  border-top: 1px solid $gray4;
  overflow: hidden;
}

.artwork {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  min-height: 100px;
  min-width: 100px;
  width: ($gridCellHeight) * 0.85;
  float: left;
  margin-right: 10px;
}

.card {
  padding: 10px;
  margin: 0px;
  height: $gridCellHeight;
  float: left;
  width: $titleInfoWidth;
  padding-right: 15px;
}

.card:hover {
  text-decoration: none;
}

.card h6 {
  line-height: 120%;

  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: bold;
}

.titleType {
  color: $gray1;
}

.auditCount {
  color: #D44;
}

.price {
  margin-top: 0;
  margin-bottom: 0;
  height: $gridCellHeight;
  float: left;
  position: relative;
}

.priceGhostRow {
  margin-top: 0;
  margin-bottom: 0;
  height: $gridCellHeight;
  float: left;
  width: $gridCellWidth;
  position: relative;
  border-left: 1px solid $gray4;
}

.ghostTvod {
  margin-top: 0;
  margin-bottom: 0;
  height: $gridCellHeight;
  float: left;
  width: $gridCellWidth;
  position: relative;
}

.seasonPrice {
  height: $seasonGridCellHeight;
}

.priceHeader {
  float: left;
  width: 50px;
  height: $gridCellHeight;
  position: relative;
  font-weight: 600;
  font-size: 11px;
  display: flex;
  flex-direction: column;
}

.fadeHeader .priceRow {
  opacity: 0.3
}

.priceRow {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $borderColor;
}

.priceHeader .priceRow:first-child {
  border-top: none;
}

.actionText {
  cursor: pointer;
  margin-left: 15px;
}

.actionIcon {
  display: none !important;
}

.actionText:hover > .actionIconContainer .actionIcon {
  display: inline-block !important;
}

.actionIconContainer {
  display: inline-block;
  width: 10px;
}

.emaAction {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12px;
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 1px;
  font-size: 8px;
  color: #3D5160;
}

.emaApplied {
  background-color: $red2;
}

.emaSubmited {
  background-color: $gold5;
}

.emaPrice {
  margin-top: 0;
  margin-bottom: 0;
  height: $gridCellHeight;
  float: left;
  width: $gridCellWidth;
  position: relative;
  border-top-style: solid;
  border-width: 1px;
  border-color: $gray4;
}

.emaPrice .emaPriceItem {
  cursor: pointer;
  position: absolute;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #182026;
  cursor: default;
  border-color: $gray4;
  border-width: 1px;
}

.emaPrice .emaPriceItemActive {
  cursor: pointer;
}

.emaPrice .emaPriceItemActive:hover {
  background: #C1F5EF;
}

.emaPriceItem :global bp5-popover-target {
  width: 100%;
  height: 100%;
  display: block;

  display: flex;
  align-items: center;
  justify-content: center;
}

.emaPriceItem :global bp5-popover-target :global bp5-tether-target{
  width: 100%;
  height: 100%;
  display: block;

  display: flex;
  align-items: center;
  justify-content: center;
}

.priceColumn {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.channelColumn {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.customPopoverPadding {
  h5 {
    margin-top: 5px;
  }
}
.priceItem {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border-color: $borderColor;
  border-width: 1px;
  border-top-style: solid;
}
.priceWrapper, .notAvailableWrapper {
  min-height: 40px;
  display: flex;
  align-items: center;
}
.price .priceItem:first-child {
  border-top: none;
}

.priceItem.submited:hover {
  background-color: $gold5 !important;
}

.priceItem.applied:hover {
  background-color: $red2 !important;
}

.priceItem.pending {
  background-color: $red3;
}

.priceItem.pending:hover {
  background-color: $red2;
}

.priceItem.correctPrice {
  cursor: default;
  color: $gray5;
  font-size: 15px;
}

.priceItem.preorderPrice {
  color: #458DFC !important;
  cursor: pointer;
}

.extendPriceItemFullWidth {
  width: 100% !important;
}

.priceItem :global bp5-popover-target {
  width: 100%;
  height: 100%;
  display: block;

  display: flex;
  align-items: center;
  justify-content: center;
}

.priceItem :global bp5-popover-target :global bp5-tether-target{
  width: 100%;
  height: 100%;
  display: block;

  display: flex;
  align-items: center;
  justify-content: center;
}

.topLeft {
  left: 0;
  top: 0;
  border-right-style: solid;
  border-right-color: $borderColor !important;
  border-bottom-style: solid;
  border-bottom-color: $borderColor !important;
  border-left-style: solid;
}

.midLeft {
  border-right-style: solid;
  border-right-color: $borderColor !important;
  border-left-style: solid;
  left: 0;
  top: 33%;
}

.midRight {
  right: 0;
  top: 33%;
}

.topRight {
  border-bottom-style: solid;
  border-bottom-color: $borderColor !important;
  position: absolute;
  right: 0;
  top: 0;
}

.bottomRight {
  border-top-style: solid;
  border-top-color: $borderColor !important;
  right: 0;
  bottom: 0;
}

.bottomLeft {
  left: 0;
  bottom: 0;
  border-right-color: $borderColor !important;
  border-right-style: solid;
  border-top-style: solid;
  border-top-color: $borderColor !important;
  border-left-style: solid;
}

/* .image { */
/*     position: absolute; */
/*     top: 0; */
/*     left: 0; */
/*     bottom: 0; */
/*     width: 200px; */
/*     background-image: url(//d2zin8ttbglalq.cloudfront.net/production/audit_artworks/images/000/001/595/original/u?1511867294); */
/*     background-size: cover; */
/* } */

.price {
  float: left;
}

.hdLogo svg {
  position: absolute;
  left: 3px;
  top: 3px;
  vertical-align: middle;
  fill: #999;
  width: 20px;
  height: 20px;
}

.gridContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gridInnerContainer {
  flex-grow: 1;
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
}

.hasAction {
  position: absolute;
  top: 0;
  right: 0;
  width: 0px;
  height: 0px;
  border-width: 10px;
  border-top-color: red;
  border-top-style: solid;
  border-left-color: transparent;
  border-left-style: solid;
}

.hasActionCell.hasActionCell.pending,
.hasActionCell.hasActionCell.noActions,
.hasActionCell.hasActionCell.applied,
.hasActionCell.hasActionCell.submited {
  font-size: 15px;
  font-weight: 600;
  color: $dark-gray1 !important;
}

.hasActionCell.hasActionCell {
  color: #D44 !important;
  font-weight: bold;
}

.hasNotAppliedPriceExceptionCell {
  color: $notAppliedExceptionColor !important;
  font-weight: bold;
}

.hasPriceExceptionCell {
  color: $pt-intent-success;
  font-weight: bold;
}

.hasActionExceptionCell {
  color: $violet1 !important;
  font-weight: bold;
}

.notAvailable {
  cursor: default;
  color: $gray5;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.notAvailableforSubscription {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: $gray5;
  display: flex;
}

.notAvailable:hover {
  background-color: white;
}

.noActions {
  width: 100%;
  height: 100%;
  background: $red3;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.noActions.availabilityReady {
  background-color: $red2;
}

.noActions.availabilitySubmited {
  background-color: $gold5;
}

.notAvailbleExclamation {
  font-size: 16px;
}

.noActions:hover > .notAvailbleExclamation {
  display: none !important;
}

.notAvailableText {
  font-size: 14px;
  font-weight: 600;
  display: none !important;
  margin: 0px;
}

.noActions:hover > .notAvailableText {
  display: inline-block !important;
  overflow: hidden;
}

.noActions.availabilityReady, .noActions.availabilitySubmited {

  &:hover > .notAvailableText {
    display: none !important;
  }
}

.promoted {
  border-radius: 2px;
  background: $pt-intent-success;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 21px;
  color: white;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  max-width: 200px;
}

.tagItem {
  margin-top: 5px;
  margin-right: 3px;
}

.preorder {
  background-color: #458DFC !important;
  color: #FFFF !important;
}

.corrections div {
  margin-top: 10px;
  cursor: pointer;
}

.srpPopover {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  width: 200px;
}

.preorderPopover {
  padding: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  border-top: 1px solid;
  justify-content: center;
}

.srpPopover span {
  font-weight: 600;
}

.srpPopover > div {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 30px;
}

.srpPopover p {
  margin: 0;
}

.srpPopover > div:first-child {
  font-size: 12px;
}

.srpPopover > div:not(:first-child):hover {
  cursor: pointer;
  background-color: $red2;
}

.popoverContent {
  padding: 20px;
  max-height: 100% !important;
}

.titlesCount {
  display: inline;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.titlesCount span {
  font-weight: bold;
}

.ignoredButtonContainer {
  width: 30px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.ignoreActionButton {
  cursor: pointer;
  float: right;
  margin-left: 30px;
}

.ignoreActionErrorMessage {
  color: $red4;
}

.avodItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  float: left;
  width: 100%;
}

.seasonAvod {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.avodItem span {
  font-size: 30px !important;
}

.icontickColor {
  color: $gray5;
}

.subscription {
  width: 100%;
  height: 115px;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.contenerDimension{
  width: 100%;
  height: 100%;
  overflow-x: auto;
  display: run-in;
  min-width: 80%;
}

.subscription li {
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding-left: 3px;
  padding-right: 3px;
  text-transform: uppercase;
  color: $dark-gray1;
  font-weight: 600;
  font-size: 11px;
  list-style-type: none;
  cursor: pointer;
  border-bottom: 1px solid $borderColor;
}

.channelStyle {
  border-left: 1px solid $borderColor;
}

.channelAction {
  color: #D44 !important;
  font-weight: bold;
}

.leftRow
{
  float: left;
  width: 351px;
  border-right: 1px solid #8a9ba8;
}

.ghostAvod {
  margin-top: 0;
  margin-bottom: 0;
  height: $gridCellHeight;
  float: left;
  width: $avodCellWidth;
  position: relative;
}

.ghostSvod {
  margin-top: 0;
  margin-bottom: 0;
  height: $gridCellHeight;
  float: left;
  width: $svodCellWidth;
  position: relative;
}

.channelDimension{
  height: 100%;
}

.tagBtn {
  background: #670067;
  color: white;
  border-radius: 2px;
  border: none;
}

.tagBtn:hover {
  cursor: pointer;
}

.tagContainer {
  display: flex;
  height: auto;
}

.tagInput {
  max-width: 160px;
  min-width: 40px;
}

.tagInput:focus {
  border: 2px solid #670067;
}

.tagSpan {
  background-color: #dfeef1 !important;
  line-height: unset !important;
  color: black !important;
  height: 21px;
  min-width: 60px !important;
}

.numberOfTags {
  background-color: white !important;
  color: black !important;
  height: 21px;
}

.navBar {
  position: absolute !important;
  display: inline;
}

.suggestionsContainer {
  display: none;
}

.suggestionsContainerOpen {
  display: block;
  position: absolute !important;
  margin-top: 5px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-size: 12px;
  left: 0;
  top: 25px;
  width: 100%;
  z-index: 10000;
}

.suggestionContent {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.suggestionContentInfo {
  margin-top: 2px;

  p {
    min-height: 20px;
    font-size: 12px;
  }
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: 4px 5px;
}

.suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.suggestionHighlighted {
  background-color: #ddd;
}

.titleAuditRowPosition {
  position: relative;
}

.tagsContainer{
  margin-top: 2px;
  max-height: 27px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}

.container {
  position: absolute !important;
  top: 90px;
}

.tagFilterContainer {
  position: relative;
}

.tagSearchIcon {
  position: absolute;
  left: 2px;
}

.dropdownSign {
  position: absolute;
  right: 1px;
}

.filterSuggestionContent {
  position: absolute;
  width: 100%;
}

.linkOverflow{
  overflow-y: scroll !important;
}

.textContainer{
  margin-top: 2px;
  height: 47px;
  text-overflow: ellipsis;
   color: $dark-gray2;
}

.title{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 0px;  
}

.suggestionContainer{
  position: relative;
  display: flex;
}

.deleteTagButton:hover{
  cursor: pointer;
}

.deleteTagButton{
  position: absolute;
  right: 7px;
  bottom: 11px;
  z-index: 10000;
}

.tagName{
  position: absolute;
  bottom: 11px;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 5px;
}

.cancelButton {
  color: #670067 !important;
  border: none !important;
}

.yesButton {
  background: #670067 !important;
  color: white !important;
  border-radius: 5px;
}

.dialogBox {
  height: 100px;
  font-size: 13px;
  .customDialog {
    width: fit-content !important;
  }
}

.checkBox:hover{
  cursor: pointer;
}

.checkBox:checked{
  accent-color: #670067!important;
}

.tagsRow {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: space-between;
}

.btnDefault {
  box-shadow: inset 0 0 0 1px rgba(223, 238, 241, 0.2), inset 0 1px 2px rgba(223, 238, 241, 0.2) !important;
  background-color: $white !important;
  border: 1px solid $lightBlue !important;
  &.active, &:hover {
    background-color: $lightBlue !important;
  }
  .labelColor {
    color: $black;
  }
}