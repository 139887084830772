@import './variables.scss';

.container {
  max-width: 1300px;
  margin-top: 15px;
  margin-bottom: 0px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px;
}

.heading {
  color: #202124;
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  padding-bottom: 20px;
}

.heading h2 {
  font-size: 1.375rem;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-weight: 400;
}

.heading h3 {
  font-size: 13px!important;
  color: #999;
}

.item {
  padding-top: 25px;
  padding-right: 20px;
  display: block;
  font-size: 21px;

}

.item div {
  width: 416px;
  height: 132px;
}

.fade.fade {
  opacity: 0.25;
}

.highlight.highlight {
  opacity: 1;
}

.rightArrow,
.leftArrow {
  position: absolute;
  top: 50%;
  border: solid #5f6368;;
  border-width: 0 2px 2px 0;
  display: block;
  margin: -8px -3px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  opacity: 0.6;
}

.rightArrow::before,
.leftArrow::before {
  content: '' !important;
}

.rightArrow:hover,
.leftArrow:hover {
  opacity: 1;
}

.disabledArrow {
  display: none;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -25px;
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: -25px;
}
