$carouselItemWidth: 680px;
$carouselItemHeight: 260px;
$carouselItemMargin: 5px;
$itunesBackgroundColor: #212121;

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1550px;

$sidePanelWidth: 150px;
$sidePanelWidth-xl: 230px;

$flowcaseItemWidth: 105px;
$flowcaseItemPadding: 20px;
$flowcaseItemArtworkHeight: 157px;
$flowcaseItemHeight: 206px;

$tvFlowcaseItemWidth: 125px;
$tvFlowcaseItemHeight: 193px;
$tvFlowcaseItemArtworkHeight: 125px;

$brickHeroWidth: 200px;
$brickHeroHeight: 98px;
$brickHeroMargin: 20px;

$bodyWidth: 1260px;

$linkSectionHeight: 380px;
