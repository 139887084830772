@import './variables.scss';

.item {
  position: relative;
  display: block;
  width: $showcaseItemWidth;
  height: $showcaselItemHeight;
}

.item div {
  width: 100%;
  height: 100%;
}

.verticalItem {
  width: $verticalShowcaseItemWidth;
  height: $verticalShowcaseItemHeight;
  float: left;
  margin: 20px 0;
  margin-left: 1.3%;
}

.verticalItem div {
  width: 100% !important;
  height: $verticalShowcaseItemImageContainerHeight
}

.itemFooter {
  width: 100%;
  height: 80px !important;
  background: #333;
  position: absolute;
  bottom: 0;
  padding-left: 10px;
}

.itemFooter span {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.fade {
  opacity: 0.2;
}

.highlight {
  opacity: 1 !important;
}
