.root {
  position: fixed;
  top: 150px;
  background-color: rgba(24,24,24,.9);
  border-bottom: 1px solid #ff7300;
  color: #f2f3f3;
  font-weight: 200;
  -webkit-transition: padding .5s ease;
  -moz-transition: padding .5s ease;
  -o-transition: padding .5s ease;
  transition: padding .5s ease;
  z-index: 2;
  width: 100%;
  max-width: 1600px;
  min-width: 320px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  padding: 0;
}

.logo {
  margin: 0;
  margin-left: 20px;
  display: flex;
  align-items: center;
  max-width: 200px;
  height: 100%;
  float: left;
}

.divider {
  background: #747578;
  background: -webkit-radial-gradient(center,ellipse,#747578 0,transparent 65%);
  background: -moz- oldradial-gradient(center,ellipse,#747578 0,transparent 65%);
  background: -o-radial-gradient(center,ellipse,#747578 0,transparent 65%);
  background: radial-gradient(ellipse at center,#747578 0,transparent 65%);
  display: block;
  height: 60px;
  margin: 10px 20px 0;
  width: 1px;
  float: left;
}

.logo img {
  width: 100%;
}

.mediaNav {
  position: relative;
  text-transform: uppercase;
  font-size: 18px;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  float: left;
  height: 79px;
  margin: 0 0 0 15px;
  -webkit-transition: margin ease .5s;
  -moz-transition: margin ease .5s;
  -o-transition: margin ease .5s;
  transition: margin ease .5s;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.dropdownItem {
  float: none;
  height: 39px;
  line-height: 39px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.listItem a {
  color: #f2f3f3;
  text-decoration: none;
  transition: color .25s ease;
}

.listItem a:hover {
  color: #3478c1;
  cursor: pointer;
  text-decoration: none;
}

.dropdown {
  background-color: #181818;
  border-bottom: 1px solid #080808;
  border-left: 1px solid #080808;
  border-right: 1px solid #080808;
  color: #f2f3f3;
  width: auto;
  display: block;
  padding: 10px 15px;
  min-width: 175px;
  position: absolute;
  top: -26px;
  left: -100px;
  white-space: nowrap;
  list-style-type: none;
  z-index: 1000;
}

.dropdown > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 50px;
  min-height: 140px;
  width: auto;
  clear: both;
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.dropdown > ul:first-child {
  border: 0;
  padding-left: 0;
}