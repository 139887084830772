.sectionHeader {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.sectionHeader .seeMoreLink {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-weight: 800;
}

.scrollWrapper {
  max-width: 1600px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.scrollableItem {
  width: 160px;
  height: 305px;
  margin-right: 8px;
  display: inline-block;
}

.vuduScrollable {
  width: 100%;
  max-width: 1364px;
  min-width: 768px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.vuduScrollable .scrollableItem {
  width: 145px;
  height: 203px;
  margin-right: 8px;
  margin-right: 15px;
}

.vuduScrollable .sectionHeader h3 {
  font-size: 18px;
  font-weight: 600;
  color: #fafafa;
}