@import './variables.scss';
@import './minxins.scss';

.leftArrow {
  @include arrow(-40px, 45px, 30px, $colorGray, (), (right: 70px), (border-top-right-radius: 0, border-bottom-right-radius: 0), 135deg, $colorDark, (border-color: $colorRed), 10px, 10px, 2px);
}

.rightArrow {
  @include arrow(-40px, 45px, 30px, $colorGray, (), (right: 26px), (border-top-left-radius: 0, border-bottom-left-radius: 0), -45deg, $colorDark, (border-color: $colorRed), 10px, 10px, 2px);
}

.leftArrowCarousel {
  @include arrow(16vh, 55px, 20%, transparent, (background: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5))), (left: 30px), (), 135deg, $colorLight, (), 10px, 10px, 2px);
}

.rightArrowCarousel {
  @include arrow(16vh, 55px, 20%, transparent, (background: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5))), (right: 30px), (), -45deg, $colorLight, (), 10px, 10px, 2px);
}

.leftArrowPageContainer {
  @include arrow(16vh, 55px, 20%, transparent, (), (left: 12%, top: 40%), (), 135deg, $colorLight, (), 30px, 30px, 4px);
}

.rightArrowPageContainer {
  @include arrow(16vh, 55px, 20%, transparent, (), (right: 14%, top: 40%), (), -45deg, $colorLight, (), 30px, 30px, 4px);
}
